import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { KeycloakTokenParsed } from 'keycloak-js';

import * as AuthActions from '@g3p/store/auth/auth.actions';
import * as AuthSelectors from '@g3p/store/auth/auth.selectors';
import { IAuthState } from '@g3p/store/auth/interfaces/auth-state.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  constructor(private authStore: Store<IAuthState>) {}

  setTokenData(token: KeycloakTokenParsed): void {
    this.authStore.dispatch(new AuthActions.SetTokenData({ token }));
  }

  getEmail$(): Observable<string> {
    return this.authStore.select(AuthSelectors.emailState);
  }

  getFirstname$(): Observable<string> {
    return this.authStore.select(AuthSelectors.firstnameState);
  }

  getLastname$(): Observable<string> {
    return this.authStore.select(AuthSelectors.lastnameState);
  }

  getName$(): Observable<string> {
    return this.authStore.select(AuthSelectors.nameState);
  }

  getTenantId$(): Observable<string[]> {
    return this.authStore.select(AuthSelectors.tenantIdState);
  }

  getUsername$(): Observable<string> {
    return this.authStore.select(AuthSelectors.usernameState);
  }

  getRoles$(): Observable<string[]> {
    return this.authStore.select(AuthSelectors.rolesState);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { mapUserCustomerUsageResponse } from '@g3p/customer/helpers/mappers';
import { UserCustomerUsageDef } from '@g3p/customer/interfaces/customer-details-def.interface';
import { UserCustomerUsageResponse } from '@g3p/customer/interfaces/customer-dto.interface';
import { ApiEndpointsUrl } from '@g3p/shared/constants/api-url.constants';

@Injectable({ providedIn: 'root' })
export class CustomerUserStatusModalService {
  constructor(private http: HttpClient) {}

  updateCustomerUserStatus(customerId: string, userId: string): Observable<UserCustomerUsageDef> {
    return this.http
      .patch(
        environment.apiUrl +
          ApiEndpointsUrl.CUSTOMERS.UPDATE_CUSTOMER_USER_STATUS.replace('{customerId}', customerId).replace(
            '{userId}',
            userId
          ),
        null
      )
      .pipe(map((response: UserCustomerUsageResponse) => mapUserCustomerUsageResponse(response)));
  }
}

import { KeycloakTokenParsed } from 'keycloak-js';

import cloneDeep from 'lodash/cloneDeep';

import { newState } from '@g3p/shared/helpers/app-helpers';
import { AuthActions, AuthActionTypes } from '@g3p/store/auth/auth.actions';
import { initialAuthState } from '@g3p/store/auth/auth.state';
import { IAuthState } from '@g3p/store/auth/interfaces/auth-state.interface';
import { IUserLogged } from '@g3p/store/auth/interfaces/user-logged.interface';

export function authReducers(state: IAuthState = cloneDeep(initialAuthState), action: AuthActions): any {
  switch (action.type) {
    case AuthActionTypes.SET_TOKEN_DATA: {
      const tokenPayload: KeycloakTokenParsed = action.payload.token;
      const userLogged: IUserLogged = {} as IUserLogged;

      if (tokenPayload) {
        userLogged.email = tokenPayload.email || '';
        userLogged.firstname = tokenPayload.given_name || '';
        userLogged.lastname = tokenPayload.family_name || '';
        userLogged.name = tokenPayload.name || '';
        userLogged.tenantId = tokenPayload.tenant_id || [''];
        userLogged.username = tokenPayload.username;
        userLogged.id = tokenPayload.sub;
        userLogged.roles = tokenPayload.roles;
      }

      return newState(state, {
        user: { ...userLogged }
      });
    }

    default:
      return state;
  }
}

<g3-large-list
    data-automation="Cashbook - Admin page - Customer List"
    [noItemsFoundMessage]="'content.no-customers-found'"
    [largeListGroup]="customersList"
    [selectedItemId]="selectedCustomerId"
    (selectedItem)="setSelectedCustomer($event.itemId)"
    [newButton]="true"
    (newButtonClick)="onCreateCustomer()"
    (scroll)="loadCustomers()"
    [showFiltersHeader]="true"
    (quickFilter)="setSearchString($event)"
    [quickFilterValue]="searchString"
    (resetQuickFilter)="resetSearch()"
    (advancedFilter)="openFilters()"
    [quickFilterTranslationKey]="'content.search' | translate"
    [advancedFiltersSet]="activeFilters"
    [minInputLength]="1"
>
</g3-large-list>

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { SharedModule } from '@g3p/shared/shared.module';
import { BaseBackgroundComponent } from '@g3p/workstation/registration/base-background/base-background.component';
import { RegistrationComponent } from '@g3p/workstation/registration/registration.component';
import { WorkstationRoutingModule } from '@g3p/workstation/workstation.routing';
import { EffectsModule } from '@ngrx/effects';
import { WorkstationEffects } from '@g3p/workstation/store/workstation.effects';

@NgModule({
  declarations: [RegistrationComponent, BaseBackgroundComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,

    WorkstationRoutingModule,
    SharedModule,

    EffectsModule.forFeature([
        WorkstationEffects
      ]
    )
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [BaseBackgroundComponent]
})
export class WorkstationModule {}

<g3-second-level-nav [paths]="navLinks"></g3-second-level-nav>
<g3p-container [columnsNumber]="containerType.TWO_COLUMNS" data-automation="Admin page - Divider">
    <g3p-container-content-left>
        <g3p-customer-list></g3p-customer-list>
    </g3p-container-content-left>

    <g3p-control-bar-left>
        <div data-automation="Cashbook - Admin page - Customer Header">
            <div data-automation="Cashbook - Admin page - Customer Label">{{ 'content.customer' | translate }}</div>
            <div class="field-value" data-automation="Cashbook - Admin page - Customer Value">{{ (customerName$ | async) || '-' }}</div>
        </div>
    </g3p-control-bar-left>

    <g3p-control-bar-right>
        <button
            color="primary"
            class="large white"
            mat-stroked-button
            data-automation="Cashbook - Admin page - Delete customer"
            (click)="onDeleteCustomer()"
            [disabled]="isCustomerDeletionDisabled()"
        >
            {{ "content.delete" | translate }}
        </button>
        <button
            color="primary"
            class="large white"
            mat-stroked-button
            data-automation="Cashbook - Admin page - Customer deletion state"
            (click)="onOpenCustomerDeletionStateModal()"
        >
            {{ "content.deletion-status" | translate }}
        </button>
        <button
            color="primary"
            class="large"
            mat-flat-button
            data-automation="Cashbook - Admin page - Edit customer"
            (click)="updateCustomer()"
        >
            {{ "content.edit" | translate }}
        </button>
    </g3p-control-bar-right>

    <g3p-container-content-right>
        <g3p-customer-details></g3p-customer-details>
    </g3p-container-content-right>
</g3p-container>

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StoreConstants } from '@g3p/shared/constants/store.constants';
import { IAuthState } from '@g3p/store/auth/interfaces/auth-state.interface';

export const authStore = createFeatureSelector<IAuthState>(StoreConstants.authStore);

export const emailState = createSelector(authStore, (state: IAuthState) => state.user.email);

export const firstnameState = createSelector(authStore, (state: IAuthState) => state.user.firstname);

export const lastnameState = createSelector(authStore, (state: IAuthState) => state.user.lastname);

export const nameState = createSelector(authStore, (state: IAuthState) => state.user.name);

export const tenantIdState = createSelector(authStore, (state: IAuthState) => state.user.tenantId);

export const usernameState = createSelector(authStore, (state: IAuthState) => state.user.username);

export const userIdState = createSelector(authStore, (state: IAuthState) => state.user.id);

export const rolesState = createSelector(authStore, (state: IAuthState) => state.user.roles);

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { AngularMaterialModule } from '@cgm/cashbook-ui-lib';
import { G3ComponentLibModule } from '@cgm/g3-component-lib';

import { DialogHeaderComponent } from '@g3p/shared/components/dialog-header/dialog-header.component';
import { HeaderComponent } from '@g3p/shared/components/header/header.component';
import { TssStatusPopoverComponent } from '@g3p/shared/components/tss-status-popover/tss-status-popover.component';


import { ContainerComponent } from './components/container/container.component';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
import { ErrorDialogComponent } from '@g3p/shared/components/error-dialog/error-dialog.component';

@NgModule({
  declarations: [HeaderComponent, DialogHeaderComponent, ContainerComponent, TssStatusPopoverComponent, FilterModalComponent, ErrorDialogComponent],
  imports: [
    AngularMaterialModule,
    CommonModule,
    FormsModule,
    RouterModule,

    TranslateModule.forChild({}),

    G3ComponentLibModule,
    OverlayModule
  ],
  exports: [
    TranslateModule,
    AngularMaterialModule,
    G3ComponentLibModule,
    DialogHeaderComponent,
    HeaderComponent,
    ContainerComponent,
    TssStatusPopoverComponent,
    FilterModalComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule {}

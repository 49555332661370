import { ActionReducer } from '@ngrx/store';

import { storeLogger } from 'ngrx-store-logger';

import { IAppState } from '@g3p/store/app-state.interface';

export function logger(reducer: ActionReducer<IAppState>): any {
  return storeLogger({
    collapsed: true,
    timestamp: false,
    colors: {
      title: () => '#455A64',
      prevState: () => '#795548',
      nextState: () => '#4CAF50',
      action: () => '#2196F3',
      error: () => '#f44336'
    }
  })(reducer);
}

<g3-modal-regular>
    <g3-modal-title>{{ title }}</g3-modal-title>
    <g3-modal-body>
        <span>{{ description }}</span>
        <ul class="usage-list">
            <li class="usage-item" *ngFor="let customer of linkedCustomers">
                {{ customer }}
            </li>
        </ul>
        <p>{{ 'content.should-this-user-also-have-access-to-the-current-customer' | translate }}</p>
    </g3-modal-body>
    <g3-modal-footer>
        <button
            mat-button
            color="primary"
            data-automation="Cashbook - Admin page - Delete inUse username button"
            (click)="cancel()"
        >{{ 'content.cancel' | translate }}</button>
        <button
            mat-button
            color="primary"
            data-automation="Cashbook - Admin page - Confirm inUse username button"
            (click)="confirm()"
        >{{ 'content.confirm' | translate}}</button>
    </g3-modal-footer>
</g3-modal-regular>


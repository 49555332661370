<g3-modal-regular>
    <g3-modal-title>
        <span>{{ dialogHeaderText() }}</span>
    </g3-modal-title>
    <g3-modal-body>
        <div *ngIf="!showSecondConfirmation && !showThirdConfirmation" class="modal-body-content"
             [ngStyle]="{ 'font-weight': firstConfirmationMessage() === 'content.customer-deletion-modal-dialog-text-tse' ? 'bold' : 'normal', 'color': firstConfirmationMessage() === 'content.customer-deletion-modal-dialog-text-tse' ? 'red' : 'black' }">
            <p>{{ firstConfirmationMessage() | translate }}</p>
        </div>
        <div *ngIf="showSecondConfirmation && !showThirdConfirmation" class="modal-body-content"
             [ngStyle]="{ 'font-weight': secondConfirmationMessage() === 'content.customer-deletion-modal-dialog-text-tse' ? 'bold' : 'normal', 'color': secondConfirmationMessage() === 'content.customer-deletion-modal-dialog-text-tse' ? 'red' : 'black' }">
            <p>{{ secondConfirmationMessage() | translate }}</p>
        </div>
        <div *ngIf="!showSecondConfirmation && showThirdConfirmation" class="modal-body-content">
            <p>{{ thirdConfirmationMessage() | translate }}</p>
        </div>
    </g3-modal-body>
    <g3-modal-footer>
        <button
            #cancelButton
            mat-button
            color="primary"
            data-automation="Customer deletion - Cancel Button"
            (click)="cancel()"
        >{{ 'content.cancel' | translate }}</button>
        <button
            #confirmButton
            mat-button
            color="primary"
            data-automation="Customer deletion - Confirm Button"
            (click)="confirm()"
        >{{ 'content.confirm' | translate }}</button>
    </g3-modal-footer>
</g3-modal-regular>

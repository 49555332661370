import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { take, takeWhile } from 'rxjs/operators';

import { IOption } from '@cgm/cashbook-ui-lib/lib/components/app/shared/interfaces/option.interface';

import { CustomerDef, WorkstationFormDef } from '@g3p/workstation/interfaces/workstation-def.interface';
import { WorkstationFacade } from '@g3p/workstation/store/workstation.facade';

@Component({
  selector: 'g3p-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {
  registrationForm: FormGroup;
  busy = false;
  customers: IOption[] = [];
  customerList: CustomerDef[] = [];
  workstations: IOption[] = [];
  isAdminMode = false;
  private alive = true;

  constructor(private formBuilder: FormBuilder, private facade: WorkstationFacade, private router: Router) {}

  ngOnInit(): void {
    this.getAdminMode();
    this.createForm();
    this.getBusyState();
    this.fetchCustomers();
    this.getCustomerOptions();
    this.updateFormState();
    this.updateFormData();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  get version(): String {
    return localStorage.getItem('APP_VERSION');
  }

  fetchCustomers(): void {
    this.facade.fetchCustomers();
  }

  resetWorkstation(isAdminMode: boolean): void {
    const workstationField = this.registrationForm.get('workstation');
    this.isAdminMode = isAdminMode;
    workstationField.setValue('');
    isAdminMode ? workstationField.disable() : workstationField.enable();
    isAdminMode ? localStorage.setItem('isAdminMode', 'true') : localStorage.setItem('isAdminMode', 'false');
  }

  onSubmit(): void {
    this.facade.setRegistered(
      this.selectedCustomer.name,
      this.selectedWorkstation?.value,
      this.selectedWorkstation?.id
    );

    const url = '/cashpoint';
    this.router.navigate([url], { replaceUrl: true });
  }

  onAdminMode(): string {
    return '/cashpoint/daily-closures';
  }

  getWorkstations(): void {
    this.workstations = this.selectedCustomer.workstations
      .sort((l, r) => l.code.localeCompare(r.code))
      .map(w => ({ id: w.id, value: w.code }));
    if (!this.isAdminMode) {
      this.selectDefault('workstation', this.workstations);
    }
  }

  createForm(): void {
    this.registrationForm = this.formBuilder.group({
      isAdminMode: new FormControl(false),
      customer: new FormControl('', Validators.required),
      workstation: new FormControl({ value: '', disabled: this.isAdminMode }, Validators.required)
    });
  }

  getBusyState(): void {
    this.facade
      .getBusyState$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((busy: boolean) => (this.busy = busy));
  }

  getCustomerOptions(): void {
    this.facade
      .getCustomers$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((customers: CustomerDef[]) => {
        this.customerList = customers;
        this.customers = customers.map(c => ({ id: c.id, value: c.name }));
        this.selectDefault('customer', this.customers);
        this.selectedCustomer && this.getWorkstations();
      });
  }

  getAdminMode(): void {
    this.facade
      .getAdminMode$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((isAdminMode: boolean) => (this.isAdminMode = isAdminMode));
  }

  updateFormState(): void {
    this.registrationForm.valueChanges
      .pipe(takeWhile(() => this.alive))
      .subscribe(_ => this.facade.updateForm(this.registrationForm.value));
  }

  updateFormData(): void {
    this.facade
      .getFormState$()
      .pipe(take(1))
      .subscribe((form: WorkstationFormDef) => this.registrationForm.patchValue(form));
  }

  get selectedCustomer(): CustomerDef {
    return this.customerList?.find(customer => customer.id === this.registrationForm.get('customer').value);
  }

  get selectedWorkstation(): IOption {
    return this.workstations?.find(workstation => workstation.id === this.registrationForm.get('workstation').value);
  }

  private selectDefault(field: string, list: any[]): void {
    this.registrationForm.get(field).setValue(list?.length === 1 ? list[0].id : null);
  }
}

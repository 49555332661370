<g3p-base-background>
    <g3p-header-container>
        <div class="login-header-container">
        </div>
    </g3p-header-container>
    <g3p-base-background-form>
        <div cdkTrapFocus class="registration-form-container">
            <div class="registration-form">
                <div class="loading-shade" *ngIf="busy">
                    <g3-spinner></g3-spinner>
                </div>
                <form [formGroup]="registrationForm" data-automation="Login - Login form" [hidden]="busy">
                    <div class="header">
                        <span>{{ 'content.cashbook-registration' | translate }}</span>
                        <g3-switch
                            ngDefaultControl
                            class="slide-toggle"
                            [label]="'content.admin-mode'"
                            (switchToggle)="resetWorkstation($event.checked)"
                            formControlName="isAdminMode"
                            data-automation="'DIALOG-HEADER-ADMIN-MODE-TOGGLE'"
                        >
                        </g3-switch>
                    </div>

                    <div class="customer-field">
                        <mat-form-field
                            class="input-field-large username-input-field"
                            appearance="outline"
                        >
                            <mat-select
                                class="vendor-mat-select"
                                data-automation="VRM - Create new vendor - vendorType selector"
                                formControlName="customer"
                                [disabled]="customers.length === 0"
                                ngDefaultControl
                                (selectionChange)="getWorkstations()"
                            >
                                <mat-option *ngFor="let customer of customers" [value]="customer.id">
                                    {{ customer.value }}
                                </mat-option>
                            </mat-select>
                            <mat-label>{{ "content.company-name" | translate }}</mat-label>

                        </mat-form-field>
                    </div>

                    <div class="workstation-field">
                        <mat-form-field
                            class="input-field-large username-input-field"
                            appearance="outline"
                        >
                            <mat-select
                                class="vendor-mat-select"
                                data-automation="VRM - Create new vendor - vendorType selector"
                                formControlName="workstation"
                                [disabled]="isAdminMode"
                                ngDefaultControl
                            >
                                <mat-option *ngFor="let workstation of workstations" [value]="workstation.id">
                                    {{ workstation.value }}
                                </mat-option>
                            </mat-select>
                            <mat-label>{{ "content.pos-workstation" | translate }}</mat-label>

                        </mat-form-field>
                    </div>

                    <div class="footer">
                        <button
                            mat-button
                            color="primary"
                            class="minimal button-submit"
                            (click)="onSubmit()"
                            [disabled]="registrationForm.invalid"
                            data-automation="Login - Login button"
                        >
                            {{ "content.confirm" | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>

    </g3p-base-background-form>
    <g3p-version-container>
        <div>v: {{ version }}</div>
    </g3p-version-container>
</g3p-base-background>


<div class="create-customer-workstation">
    <form [formGroup]="workstationFormGroup"
          [attr.data-automation]="dataAutomation + '-form'">
        <div class="row workstation-info title-line">
            <span class="add-workstation">{{'content.workstations' | translate}}</span>
            <button
                (click)="addNewWorkstation(null, null, true, null, true)"
                [disabled]="workstationsFormArray.length > 9"
                class="right-button"
                mat-icon-button
                color="primary"
                [attr.data-automation]="dataAutomation + '-add workstation button'"
            >
                <mat-icon class="mdi mdi-24px mdi-plus-circle-outline"></mat-icon>
            </button>
        </div>
        <div class="contact-list-container" formArrayName="workstations">
            <div
                class="contact-list"
                *ngFor="let workstation of workstationsFormArray?.controls; let index=index; let last = last; let first = first"
            >
                <div class="forms-box" [formGroupName]="index">
                    <div class="workstation-row">
                        <mat-form-field appearance="outline" class="input-field-large description">
                            <input
                                matInput
                                type="text"
                                autocomplete="off"
                                formControlName="code"
                                [attr.data-automation]="dataAutomation + index + 'input-Description'"
                                required
                                [g3FormValidation]="formValidation"
                            />
                            <mat-label [attr.data-automation]="dataAutomation + index + 'input-Description label'">
                                {{ "content.workstation-name" | translate }}
                            </mat-label>
                            <mat-icon matSuffix class="mdi mdi-24px mdi-alert-circle"
                                      *ngIf="formValidation.isErrorState(workstationsFormArray.controls[index].get('code'))">
                            </mat-icon>
                            <mat-error *ngIf="formValidation.isErrorState(workstationsFormArray.controls[index].get('code'))">
                                <span>{{ "content.field-is-mandatory" | translate }}</span>
                            </mat-error>
                        </mat-form-field>
                        <button
                            class="contact-card-cta--trash"
                            mat-icon-button
                            [attr.data-automation]="dataAutomation + index + '-delete workstation button'"
                            [disabled]="isDisabled(workstation)"
                            matTooltip="{{ getTooltip(workstation) | translate }}"
                            (click)="removeWorkstation(index)"
                        >
                            <mat-icon class="trash mdi mdi-24px mdi-delete"></mat-icon>
                        </button>
                    </div>
                    <!-- hidden fields-->
                    <input type="hidden" formControlName="id" [attr.data-automation]="dataAutomation + index + '-id'"/>
                </div>
            </div>
        </div>
    </form>
</div>

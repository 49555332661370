import { BusinessUnitDef, CustomerCreateFormDef } from '@g3p/customer/interfaces/customer-def.interface';
import {
  CustomerDeletionStateListDef
} from "@g3p/customer/interfaces/customer-deletion-state.interface";

export interface CustomerCreateState {
  busy: boolean;
  isOpened: boolean;
  isEditMode: boolean;
  businessUnits: BusinessUnitDef[];
  createForm: CustomerCreateFormDef;
  isCustomerDeletionModalOpened: boolean;
  customerDeletionStateList: CustomerDeletionStateListDef[];
  isCustomerDeletionStateModalOpened: boolean;
}

export const initialCustomerCreateState: CustomerCreateState = {
  busy: false,
  isOpened: false,
  isEditMode: false,
  businessUnits: [],
  createForm: {
    customerDataForm: null,
    accountForm: [],
    workstationForm: null
  },
  isCustomerDeletionModalOpened: false,
  customerDeletionStateList: [],
  isCustomerDeletionStateModalOpened: false
};

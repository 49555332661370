import { CustomerListStatus } from '@g3p/customer/enum/customer-status.enum';

export interface CustomerDetailsResponseDto {
  data: CustomerDetailsResponseAttributes;
}

export interface CustomerDetailsResponseAttributes {
  attributes: CustomerDetailsResponse;
  id: string;
  type: string;
}

export interface CustomerDetailsResponse {
  institutionName: string;
  organizationName: string;
  street: string;
  postalCode: string;
  city: string;
  email: string;
  businessUnitName: string;
  customerNumber: string;
  locationNumber: string;
  vatId: string
  fiscalCode: string;
  status: CustomerListStatus;
  workstations: WorkstationResponse[];
  users: CustomerUserResponse[];
  hasTse: boolean;
  hasOpenTransactions: boolean;
}

export interface CustomerUserResponse {
  id: string;
  type: string;
  attributes: {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    endDate: Date
    customers: []
  }
}

export interface WorkstationResponse {
  code: string;
  id: string;
  active: boolean;
  closureStatus: ClosureStatus,
  deletable: boolean;
}

export interface WorkstationDtoData {
  type: string,
  id: string,
  attributes: {
    workstationCode: string,
    customerId: string,
    active: boolean,
    closureStatus: ClosureStatus,
    deletable: boolean
  }
}

export interface WorkstationDtoResponse {
  data: WorkstationDtoData
}

export enum ClosureStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

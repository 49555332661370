import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { Observable } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

import { WorkstationFacade } from '@g3p/workstation/store/workstation.facade';
import { PathsEnum } from '@g3p/shared/enums/paths.enum';

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {

  private alive = true;

  constructor(private router: Router, private facade: WorkstationFacade) {}

  ngOnDestroy() {
    this.alive = false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.facade.getRegistered$()
      .pipe(
        takeWhile( () => this.alive),
        map((isRegistered: boolean) => {
          if (isRegistered) {
            this.router.parseUrl(PathsEnum.cashPoint);
            return true;
          }
          return this.router.parseUrl(PathsEnum.registerWorkstation);
        })
      );
  }
}

import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';

import { G3SnackbarService, G3SnackType } from '@cgm/g3-component-lib';

import { Page } from '@g3p/customer/enum/customer-search.enum';
import {
  CustomerListDef,
  CustomerListFilterDef,
  CustomerSearchRequest
} from '@g3p/customer/interfaces/customer-list-def.interface';

import { CustomerListService } from '../services/customer-list.service';
import * as fromActions from './customer-list.actions';
import * as fromSelectors from './customer-list.selectors';
import { CustomerListState } from './customer-list.state';

@Injectable({ providedIn: 'root' })
export class CustomerListEffects {
  fetchCustomerListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CustomerListActionTypes.GET_CUSTOMER_LIST_REQUEST),
      withLatestFrom(
        this.store$.select(fromSelectors.searchRequestState),
        this.store$.select(fromSelectors.customerListFilterState)
      ),
      switchMap(([_, searchRequest, filter]: [Action, CustomerSearchRequest, CustomerListFilterDef]) =>
        this.service
          .getCustomersList$(
            {
              page: {
                size: Page.DEFAULT_PAGE_SIZE * ( searchRequest.page.number + 1 ),
                number: Page.DEFAULT_PAGE_NUMBER
              }
            } as CustomerSearchRequest,
            filter
          )
          .pipe(
            map((customerList: CustomerListDef[]) => new fromActions.GetCustomerListSuccess({ customerList })),
            catchError(() => of(new fromActions.GetCustomerListError()))
          )
      )
    )
  );

  fetchCustomerListError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.CustomerListActionTypes.GET_CUSTOMER_LIST_ERROR),
        tap(() =>
          this.snackbarService.open(
            this.translateService.instant('content.something-went-wrong'),
            5000,
            G3SnackType.Error
          )
        )
      ),
    { dispatch: false }
  );
  constructor(
    private actions$: Actions,
    private service: CustomerListService,
    private snackbarService: G3SnackbarService,
    private translateService: TranslateService,
    private store$: Store<CustomerListState>
  ) {}
}

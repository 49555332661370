import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { takeWhile } from 'rxjs/operators';

import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import { CustomerUserStatusModalFacade } from './store/customer-user-status-modal.facade';

@Component({
  selector: 'g3p-customer-user-status-modal',
  templateUrl: './customer-user-status-modal.component.html',
  styleUrls: ['./customer-user-status-modal.component.scss']
})
export class CustomerUserStatusModalComponent implements OnInit, OnDestroy {
  @ViewChild('confirmButton') confirmButton: MatButton;

  private alive = true;

  private isActive: boolean;

  public translation: {
    title: string;
    text: string;
    confirmButton: string;
  };

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private translateService: TranslateService,
    private facade: CustomerUserStatusModalFacade
  ) {
    this.isActive = data.customerUser.customers.find(
      customer => customer.customerUserId.customerId === data.customerId
    )?.active;
    let userInfo = {
      firstname: data.customerUser.firstName,
      lastname: data.customerUser.lastName,
      username: data.customerUser.username
    };
    if (!this.isActive) {
      this.translation = {
        title: this.translateService.instant('content.customer-user-activate-header', userInfo),
        text: this.translateService.instant('content.customer-user-activate-text', userInfo),
        confirmButton: this.translateService.instant('content.customer-user-activate-button')
      };
    } else {
      this.translation = {
        title: this.translateService.instant('content.customer-user-deactivate-header', userInfo),
        text: this.translateService.instant('content.customer-user-deactivate-text', userInfo),
        confirmButton: this.translateService.instant('content.customer-user-deactivate-button')
      };
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.alive = false;
  }

  confirmDialog() {
    this.confirmButton.disabled = true;
    this.facade.updateCustomerUserStatus(
      this.data.customerId,
      this.data.customerUser.id,
      this.data.customerUser.username,
      this.isActive
    );
    this.facade
      .isBusy$()
      .pipe(takeWhile(() => this.alive))
      .subscribe(isBusy => {
        this.confirmButton.disabled = isBusy;
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { takeWhile } from 'rxjs/operators';

import { MatDialogRef } from '@angular/material/dialog';

import { KeyCode } from '../../..//shared/enums/key-code.enum';

@Component({
  selector: 'g3p-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnDestroy {
  @Input() dataAutomation = 'FILTER MODAL';
  @Output() resetFilter = new EventEmitter();
  @Output() apply = new EventEmitter();

  private alive = true;

  constructor(private dialogRef: MatDialogRef<FilterModalComponent>) {
    this.dialogRef
      .keydownEvents()
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: KeyboardEvent) => this.handleKeyBoard(event));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onCloseFilterDialog(): void {
    this.dialogRef.close(false);
  }

  onReset(): void {
    this.resetFilter.emit();
  }

  onApplyFilters(): void {
    this.apply.emit();
  }

  private handleKeyBoard(event: KeyboardEvent) {
    event.stopPropagation();
    event.code === KeyCode.Escape && this.onCloseFilterDialog();
  }
}

<g3p-filter-modal
    (resetFilter)="onReset()"
    (apply)="onApplyFilters()"
    [formGroup]="filtersForm"
    dataAutomation="Cashbook - Admin page - Customer List Filter Modal"
>
    <!-- 1st row -->
    <div class="inputField-row">
        <mat-form-field class="input-field-large input-extra-wide" appearance="outline">
            <mat-label>{{ "content.name" | translate }}</mat-label>
            <input
                matInput
                data-automation="Cashbook - Admin page - Customer List Filter Modal - companyName"
                formControlName="companyName"
                autocomplete="off"
            />
        </mat-form-field>
    </div>

    <!-- 2nd row -->
    <div class="inputField-row">
        <mat-form-field class="input-field-large input-medium" appearance="outline">
            <mat-select
                formControlName="businessUnitName"
                data-automation="Cashbook - Admin page - Customer List Filter Modal - businessUnit"
                class="custom-mat-select"
                [(ngModel)]="selectedBusinessUnit"
            >
                <mat-option *ngFor="let businessUnit of businessUnitList" [value]="businessUnit.name">
                    {{ businessUnit.name }}
                </mat-option>
            </mat-select>
            <mat-label>{{ "content.ais-bu" | translate }}</mat-label>
        </mat-form-field>

        <mat-form-field class="input-field-large input-medium" appearance="outline">
            <mat-label>{{ "content.customer-number" | translate }}</mat-label>
            <input
                matInput
                data-automation="Cashbook - Admin page - Customer List Filter Modal - customerNumber"
                formControlName="customerNumber"
                autocomplete="off"
            />
        </mat-form-field>
    </div>

    <!-- 3rd row -->
    <div class="inputField-row">
        <mat-form-field class="input-field-large input-medium" appearance="outline">
            <mat-label>{{ "content.zip" | translate }}</mat-label>
            <input
                matInput
                data-automation="Cashbook - Admin page - Customer List Filter Modal - postalCode"
                formControlName="postalCode"
                autocomplete="off"
            />
        </mat-form-field>

        <mat-form-field class="input-field-large input-medium" appearance="outline">
            <mat-label>{{ "content.city" | translate }}</mat-label>
            <input
                matInput
                data-automation="Cashbook - Admin page - Customer List Filter Modal - city"
                formControlName="city"
                autocomplete="off"
            />
        </mat-form-field>
    </div>
    <input type="hidden" formControlName="query" data-automation="Cashbook - Admin page - Customer List Filter Modal - query"/>
</g3p-filter-modal>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from "@env/environment";
import {ApiEndpointsUrl} from "@g3p/shared/constants/api-url.constants";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  getDocument$( documentId: string): Observable<File> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Accept', 'application/pdf');

    return this.http
      .get(environment.apiUrl + ApiEndpointsUrl.DOCUMENT.GET.replace("{documentId}", documentId), {headers: headers, responseType: 'blob' })
      .pipe(map((responseData: File) => responseData));
  }
}

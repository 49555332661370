import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { take, takeWhile, withLatestFrom } from 'rxjs/operators';

import { G3FormValidation } from '@cgm/g3-component-lib';

import { CustomerCreateFacade } from '../../store/customer-create.facade';
import { WorkstationFormDef } from '@g3p/customer/interfaces/customer-def.interface';
import { TranslateService } from '@ngx-translate/core';
import { ClosureStatus } from '@g3p/customer/interfaces/customer-details-dto.interface';

@Component({
  selector: 'g3p-workstation-step',
  templateUrl: './workstation-step.component.html',
  styleUrls: ['./workstation-step.component.scss']
})
export class WorkstationStepComponent implements OnInit, OnDestroy {
  @Input() workstationFormGroup: FormGroup;
  @Input() formValidation: G3FormValidation;
  @Output() validChange = new EventEmitter<boolean>();

  dataAutomation = 'CUSTOMER-CREATE-Workstation';
  private alive = true;

  constructor(
    private formBuilder: FormBuilder,
    private facade: CustomerCreateFacade,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.updateFormState();
    this.updateFormData();
    if ( this.workstationsFormArray.length === 0 ) {
      this.addNewWorkstation( '', '', true, null, true );
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  updateFormState(): void {
    this.workstationFormGroup.valueChanges
      .pipe(
        takeWhile( () => this.alive ),
        withLatestFrom( this.facade.getCreateDialogOpened$() )
      )
      .subscribe( ( [ _, isOpened ]: [ FormGroup, boolean ] ) => {
        if ( isOpened ) {
          this.validChange.emit( this.workstationFormGroup.valid );
          this.facade.updateWorkstationForm( this.buildStepFormDef() );
        }
      } );
  }

  updateFormData(): void {
    this.facade
      .selectWorkstationStep$()
      .pipe( take( 1 ) )
      .subscribe( ( form: WorkstationFormDef ) => this.patchFormValue( form ) );
  }

  patchFormValue( value: WorkstationFormDef ): void {
    value?.workstations.map( workstation => this.addNewWorkstation( workstation.code, workstation.id, workstation.active, workstation.closureStatus, workstation.deletable ) );
  }

  buildStepFormDef(): WorkstationFormDef {
    return this.workstationFormGroup.value as WorkstationFormDef;
  }

  removeWorkstation( index: number ) {
    this.workstationsFormArray.removeAt( index );
  }

  get workstationsFormArray(): FormArray {
    return this.workstationFormGroup.get( 'workstations' ) as FormArray;
  }

  addNewWorkstation( name: string, id: string, active: boolean, closureStatus: ClosureStatus, deletable: boolean ) {
    if ( ( this.workstationsFormArray.length === 0 ) || ( this.workstationFormGroup.valid ) ) {
      this.formValidation.cancelPost();
      this.workstationsFormArray.push(
        this.formBuilder.group( {
          id: id,
          code: [ name, { validators: Validators.required, updateOn: 'blur' } ],
          active: active,
          closureStatus: closureStatus,
          deletable: deletable
        } )
      );
    } else {
      this.formValidation.post();
    }
  }

  isDisabled(workstation: any): boolean {
    return this.workstationsFormArray.length === 1 || !workstation.value.deletable;
  }

  getTooltip(workstation: any): string {
    if (workstation.value.deletable) {
      if (this.workstationsFormArray.length === 1) {
        return this.translateService.instant('content.workstation-delete-last-one-tooltip')
      }
    } else {
      return this.translateService.instant('content.workstation-delete-already-used-tooltip')
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {IG3ExtendedTableRow} from "@cgm/g3-component-lib";
import {MatTableDataSource} from "@angular/material/table";
import {
    CustomerCreateFacade
} from "@g3p/customer/components/customer-create-modal/store/customer-create.facade";
import {takeWhile} from "rxjs/operators";
import {MatDialogRef} from "@angular/material/dialog";
import {
    CustomerDeletionStateListDef
} from "@g3p/customer/interfaces/customer-deletion-state.interface";

@Component({
    selector: 'g3p-customer-deletion-state-modal',
    templateUrl: './customer-deletion-state-modal.component.html',
    styleUrls: ['./customer-deletion-state-modal.component.scss']
})
export class CustomerDeletionStateModalComponent implements OnInit, OnDestroy, IG3ExtendedTableRow<CustomerDeletionStateListDef> {

    dataSource: MatTableDataSource<CustomerDeletionStateListDef>
    displayedColumns: string[] = [
        'customerId',
        'customerName',
        'status',
        'message',
        'startDate',
        'documentDownload'
    ];
    displayedColumnsData = {
        customerId: 'content.customer-number',
        customerName: 'content.institution-name',
        status: 'content.status',
        message: 'content.message',
        startDate: 'content.start-date',
        documentDownload: 'content.download'
    };

    private alive = true;

    constructor(
        private customerCreateFacade: CustomerCreateFacade,
        private dialogRef: MatDialogRef<CustomerDeletionStateModalComponent>
    ) {
    }

    ngOnInit(): void {
       this.notifyDialogOpen();
       this.getCustomerDeletionStateList();
    }

  getCustomerDeletionStateList(): void {
    this.customerCreateFacade
    .getCustomerDeletionStateList$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((list: CustomerDeletionStateListDef[]) => (this.dataSource = new MatTableDataSource(list)));
  }

    applyStyles(row: CustomerDeletionStateListDef, index?: number): Record<string, boolean> {
        return undefined;
    }

    notifyDialogOpen(): void {
        this.customerCreateFacade
        .isCustomerDeletionStateModalOpened$()
        .pipe(takeWhile(() => this.alive))
        .subscribe((isOpened: boolean) => {
            !isOpened && this.dialogRef.close();
        });
    }

    close() {
        this.customerCreateFacade.closeCustomerDeletionStateModal();
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

  downloadDocument(index: number) {
    const documentId = this.dataSource.data[index].documentId;
    this.customerCreateFacade.getCustomerDeletionDocument(documentId);
  }
}



import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CustomerState } from '@g3p/customer/store/customer.state';
import { StoreConstants } from '@g3p/shared/constants/store.constants';

import { CustomerUserStatusState } from './customer-user-status-modal.state';

export const customerStore = createFeatureSelector<CustomerState>(StoreConstants.customer);
export const customerCreateState = createSelector(
  customerStore,
  (state: CustomerState) => state.customerUserStatusModal
);

export const isBusy = createSelector(customerCreateState, (state: CustomerUserStatusState) => state?.busy);

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'g3p-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent {
  @Input() titleTranslationKey: string;
  @Input() extra: string;
  @Input() helpButtonVisible: boolean;
  @Input() adminModeVisible: boolean;
  @Output() adminMode: EventEmitter<boolean> = new EventEmitter();
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { CustomerUserDef } from '@g3p/customer/interfaces/customer-details-def.interface';

import * as fromActions from './customer-user-status-modal.actions';
import { isBusy } from './customer-user-status-modal.selectors';
import { CustomerUserStatusState } from './customer-user-status-modal.state';

@Injectable({ providedIn: 'root' })
export class CustomerUserStatusModalFacade {
  constructor(private store$: Store<CustomerUserStatusState>) {}

  openModal(currentCustomer: any, customerUser: CustomerUserDef, customerId: string): void {
    this.store$.dispatch(new fromActions.OpenUpdateCustomerUserStatusDialog(currentCustomer, customerUser, customerId));
  }

  updateCustomerUserStatus(customerId: string, userId: string, username: string, active: boolean) {
    this.store$.dispatch(new fromActions.UpdateCustomerUserStatusRequest(customerId, userId, username, active));
  }

  isBusy$(): Observable<boolean> {
    return this.store$.select(isBusy);
  }
}

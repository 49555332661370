import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import * as actions from './customer.actions';
import { CustomerState } from '@g3p/customer/store/customer.state';
import { CustomerListFacade } from '@g3p/customer/components/customer-list/store/customer-list.facade';
import { CustomerDetailsFacade } from '@g3p/customer/components/customer-details/store/customer-details.facade';
import {CustomerListDef} from "@g3p/customer/interfaces/customer-list-def.interface";

@Injectable({ providedIn: 'root' })
export class CustomerFacade {
  constructor(
    private store$: Store<CustomerState>,
    private customerListFacade: CustomerListFacade,
    private customerDetailsFacade: CustomerDetailsFacade
  ) {}

  reset(): void {
    this.store$.dispatch(new actions.Reset());
  }

  fetchCustomers(): void {
    this.customerListFacade.fetchCustomers();
  }

  getCustomerName$(): Observable<string> {
    return this.customerDetailsFacade.getCustomerName$();
  }

  getCustomerList$(): Observable<CustomerListDef[]> {
    return this.customerListFacade.getCustomerList$();
  }
}

<div class="create-customer">
    <g3-modal-fullscreen
        [hasStepper]="true"
        (onClose)="cancel()"
        cdkTrapFocus
        [attr.data-automation]="dataAutomation + '-g3-modal-fullscreen'"
    >
        <g3-modal-title>
            <span>{{getDialogTitle()}}</span>
        </g3-modal-title>
        <g3-modal-body>
            <g3-wizard
                class="vertical-stepper fullscreen-stepper"
                [steps]="steps"
                [linear]="false"
                [orientation]="'horizontal'"
                #stepper
                (currentStep)="onCurrentStep($event)"
                [attr.data-automation]="dataAutomation + '-g3-wizard'"
            ></g3-wizard>

            <ng-template #customerDataTemplate>
                <div class="step-content-wrapper">
                    <g3p-customer-data-step
                        [customerDataFormGroup]="customerDataFormGroup"
                        (validChange)="notifyStepValidation($event, 'customerData')"
                        [formValidation]="customerDataValidation"
                    ></g3p-customer-data-step>
                </div>
            </ng-template>

            <ng-template #accountTemplate>
                <div class="step-content-wrapper">
                    <g3p-account-step
                        [accountGroupArray]="accountFormArray"
                        (validChange)="notifyStepValidation($event, 'accounts')"
                        [formValidation]="accountFormValidation"
                        [formErrors]="accountFormErrors"
                    ></g3p-account-step>
                </div>
            </ng-template>
            <ng-template #workstationTemplate>
                <div class="step-content-wrapper">
                    <g3p-workstation-step
                        [workstationFormGroup]="workstationFormGroup"
                        (validChange)="notifyStepValidation($event, 'workstations')"
                        [formValidation]="workstationFormValidation"
                    ></g3p-workstation-step>
                </div>
            </ng-template>

        </g3-modal-body>
        <g3-modal-footer
            [class.oneButton]="currentStep === 1"
            [class.twoButtons]="currentStep > 1"
        >
            <button
                (click)="onPrevious()"
                *ngIf="currentStep > 1"
                class="customer-creation-back-btn"
                color="primary"
                data-automation="Create new customer - Back Button"
                mat-button
            >
                <mat-icon class="mdi mdi-24px mdi-chevron-left"></mat-icon>
                <span>{{ "content.back" | translate }}</span>
            </button>
            <button
                (click)="onNext()"
                (keydown.enter)="onNext()"
                *ngIf="currentStep < steps.length"
                [disabled]="disabled"
                class="customer-creation-next-btn"
                color="primary"
                data-automation="Create new customer - Next Button"
                mat-flat-button
            >
                <span>{{ "content.next" | translate }}</span>
            </button>
            <button
                (click)="createNewCustomer()"
                [disabled]="isCreateNewCustomerDisabled()"
                *ngIf="currentStep === steps.length"
                class="customer-creation-submit-btn"
                color="primary"
                data-automation="Create new customer - Creation Button"
                mat-flat-button
            >
                <span>
                    {{ (editMode? "content.update" : "content.create") | translate }}
                </span>
            </button>
        </g3-modal-footer>
    </g3-modal-fullscreen>

</div>

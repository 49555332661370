import cloneDeep from 'lodash/cloneDeep';

import { newState } from '@g3p/shared/helpers/app-helpers';
import { WorkstationActionTypes } from '@g3p/workstation/store/workstation.actions';
import { initialWorkstationState, WorkstationState } from '@g3p/workstation/store/workstation.state';
import { WorkstationTssStatus } from '@g3p/shared/enums/workstation-tss.enums';

export function workstationReducer(state: WorkstationState = cloneDeep(initialWorkstationState), action) {
  switch (action.type) {
    case WorkstationActionTypes.RESET:
      return { ...initialWorkstationState };
    case WorkstationActionTypes.GET_CUSTOMER_REQUEST:
      return newState(state, {
        ...state,
        busy: true
      });
    case WorkstationActionTypes.GET_CUSTOMER_SUCCESS:
      return newState(state, {
        ...state,
        busy: false,
        customers: action.customers
      });
    case WorkstationActionTypes.GET_WORKSTATION_TSS_STATUS_REQUEST:
      return newState(state, {
        ...state
      });
    case WorkstationActionTypes.GET_WORKSTATION_TSS_STATUS_SUCCESS:
      return newState(state, {
        ...state,
        workstationTssStatus: action.workstationTssStatus
      });
    case WorkstationActionTypes.GET_WORKSTATION_TSS_STATUS_FAILURE:
      return newState(state, {
        ...state,
        workstationTssStatus: {
          ...initialWorkstationState.workstationTssStatus,
          status: WorkstationTssStatus.NOT_ACTIVE,
          error: action.error.error?.errors ? action.error.error?.errors[0]?.detail : action.error.message
        }
      });
    case WorkstationActionTypes.SET_REGISTERED:
      return newState(state, {
        ...state,
        registered: true,
        customerName: action.customer,
        workstationName: action.workstation,
        workstationId: action.workstationId
      });
    case WorkstationActionTypes.UPDATE_FORM:
      return newState(state, {
        form: action.form
      });
    case WorkstationActionTypes.SET_OVERLAY_HELP:
      return newState(state, {
        overlayHelp: action.overlayHelp
      });
    default:
      return state;
  }
}

<div class="g3p-app-container" [ngClass]="{ 'extra-padding': columnsNumber === containerType.ONE_COLUMN, 'collapsed-background': !showControlBar }">
    <ng-container *ngIf="columnsNumber === containerType.ONE_COLUMN">
        <div class="container" [ngClass]="{'compressed-control-bar' : !showControlBar}">
            <ng-container *ngTemplateOutlet="controlBarContent"></ng-container>

            <div class="container-content">
                <ng-content select="g3p-container-content"></ng-content>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="columnsNumber === containerType.TWO_COLUMNS">
        <div class="container-left">
            <ng-content select="g3p-container-content-left"></ng-content>
        </div>
        <div class="container-right">
            <ng-container *ngTemplateOutlet="controlBarContent"></ng-container>

            <div class="container-content">
                <ng-content select="g3p-container-content-right"></ng-content>
            </div>
        </div>
    </ng-container>


    <ng-template #controlBarContent>
        <div class="control-bar-container" *ngIf="showControlBar">
            <ng-content class="control-bar-left" select="g3p-control-bar-left"></ng-content>
            <ng-content class="control-bar-right" select="g3p-control-bar-right"></ng-content>
        </div>
    </ng-template>
</div>

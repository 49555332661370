import { environment } from '@env/environment';

import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { IAppState } from '@g3p/store/app-state.interface';
import { authReducers } from '@g3p/store/auth/auth.reducers';
import { logger } from '@g3p/store/store-logger.redicers';
import { storeMetaReducers } from '@g3p/store/store.reducers';
import { workstationReducer } from '@g3p/workstation/store/workstation.reducer';
import { customerReducers } from '@g3p/customer/store/customer.reducers';

export const appReducers: ActionReducerMap<IAppState> = {
  auth: authReducers,
  workstation: workstationReducer,
  customer: customerReducers
};

export const metaReducers: MetaReducer<IAppState>[] = environment.production
  ? [storeMetaReducers]
  : [storeMetaReducers, logger];

import { Page } from '@g3p/customer/enum/customer-search.enum';
import {
  CustomerListDef,
  CustomerListFilterDef,
  CustomerSearchRequest
} from '@g3p/customer/interfaces/customer-list-def.interface';

export interface CustomerListState {
  search: CustomerSearchRequest;
  customerList: CustomerListDef[];
  filters: CustomerListFilterDef;
}

export const initialCustomerListState: CustomerListState = {
  search: {
    page: {
      size: Page.DEFAULT_PAGE_SIZE,
      number: Page.DEFAULT_PAGE_NUMBER
    }
  },
  customerList: [],
  filters: {
    companyName: '',
    businessUnitName: '',
    customerNumber: '',
    postalCode: '',
    city: '',
    query: ''
  }
};

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CustomerState } from '@g3p/customer/store/customer.state';
import { StoreConstants } from '@g3p/shared/constants/store.constants';

import { CustomerListState } from './customer-list.state';

export const customerStore = createFeatureSelector<CustomerState>(StoreConstants.customer);
export const CustomerListStore = createSelector(customerStore, (state: CustomerState) => state.customerList);

export const customerListState = createSelector(CustomerListStore, (state: CustomerListState) => state.customerList);
export const searchRequestState = createSelector(CustomerListStore, (state: CustomerListState) => state.search);
export const customerListFilterState = createSelector(CustomerListStore, (state: CustomerListState) => state.filters);

import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';

import { Observable } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

import { AuthFacade } from '@g3p/store/auth/auth.facade';
import { PathsEnum } from '@g3p/shared/enums/paths.enum';

const adminRole = 'manage-customers';

@Injectable()
export class AdminGuard implements CanActivate, OnDestroy {
  private alive = true;

  constructor(private readonly router: Router, private facade: AuthFacade) {}

  ngOnDestroy(): void {
    this.alive = false;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const adminGuardData = next.data.adminGuardData;
    return this.facade.getRoles$()
      .pipe(
        takeWhile(() => this.alive),
        map((roles: string[]) => {
          const isAdmin = roles.find(role => role === adminRole);
          if (adminGuardData.redirectToCustomer) {
            return isAdmin ? this.router.parseUrl(PathsEnum.customers) : true;
          }
          return isAdmin ? true : this.router.parseUrl(PathsEnum.registerWorkstation);
        })
      );
  }
}

export enum KeyCode {
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Backspace = 'Backspace',
  Comma = 'Comma',
  Enter = 'Enter',
  NumpadEnter = 'NumpadEnter',
  NumpadDot = 'NumpadDecimal',
  Tab = 'Tab',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Escape = 'Escape'
}

import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AccountUsageDialogData } from '@g3p/customer/interfaces/account-usage-dialog-data.interface';

@Component({
  selector: 'g3p-account-usage-modal',
  templateUrl: './account-usage-modal.component.html',
  styleUrls: ['./account-usage-modal.component.scss']
})
export class AccountUsageModalComponent {
  readonly linkedCustomers: string[];
  readonly description: string;
  readonly title: string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<AccountUsageDialogData>
  ) {
    this.linkedCustomers = data.linkedCustomers;
    this.description = data.content?.description;
    this.title = data.content?.title;
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close(this.data?.returnValue);
  }
}

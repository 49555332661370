<div class="header" data-automation="DIALOG-HEADER-TITLE">
    <span>{{ titleTranslationKey | translate }}{{ extra }}</span>
    <button
        mat-button
        mat-icon-button
        color="primary"
        *ngIf="helpButtonVisible"
        data-automation="DIALOG-HEADER-HELP-BUTTON"
    >
        <mat-icon class="mdi mdi-24px mdi-help-circle-outline"></mat-icon>
    </button>
    <g3-switch
        ngDefaultControl
        class="slide-toggle"
        [label]="'content.admin-mode'"
        *ngIf="adminModeVisible"
        (switchToggle)="adminMode.emit($event.checked)"
        data-automation="'DIALOG-HEADER-ADMIN-MODE-TOGGLE'"
    >
    </g3-switch>
</div>

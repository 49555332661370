import { Component, OnDestroy, OnInit } from '@angular/core';

import { takeWhile } from 'rxjs/operators';

import { WorkstationTssDataDef } from '@g3p/workstation/interfaces/workstation-def.interface';
import { WorkstationFacade } from '@g3p/workstation/store/workstation.facade';
import { G3DatePipe } from '@cgm/g3-component-lib';
import { WorkstationTssStatus } from '@g3p/shared/enums/workstation-tss.enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'g3p-tss-status-popover',
  templateUrl: './tss-status-popover.component.html',
  styleUrls: ['./tss-status-popover.component.scss']
})
export class TssStatusPopoverComponent implements OnInit, OnDestroy {
  private alive = true;

  workstationId = '';
  workstationTssData: WorkstationTssDataDef;

  constructor(
    private g3Date: G3DatePipe,
    private translateService: TranslateService,
    private workstationFacade: WorkstationFacade) {}

  ngOnInit() {
    this.getWorkstationId();
    this.getWorkstationTssStatus();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  getWorkstationTssStatus(): void {
    this.workstationFacade
      .getWorkstationTssStatus$()
      .pipe( takeWhile( () => this.alive ) )
      .subscribe( ( workstationTssData: WorkstationTssDataDef ) => this.workstationTssData = workstationTssData );
  }

  getWorkstationId(): void {
    this.workstationFacade
      .getWorkstationId$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((workstationId: string) => (this.workstationId = workstationId));
  }

  getFormattedDate(givenDate: string): string {
    return givenDate ? this.g3Date.transform(new Date(givenDate.replace(/\[.+$/, '')), '2.2.4', false) : '-';
  }

  getStatusText(status: WorkstationTssStatus): string {
    return status && status === WorkstationTssStatus.ACTIVE ? this.translateService.instant('content.tss_status_active') : this.translateService.instant('content.tss_status_not_active');
  }
}

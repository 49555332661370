import {
  CustomerDef,
  WorkstationDef,
  WorkstationTssDataDef
} from '@g3p/workstation/interfaces/workstation-def.interface';
import {
  CustomerListDataDto,
  WorkstationDto,
  WorkstationTssDataDto
} from '@g3p/workstation/interfaces/workstation-dto.interface';
import { WorkstationDtoData, WorkstationDtoResponse, WorkstationResponse } from "@g3p/customer/interfaces/customer-details-dto.interface";

export function mapCustomersDef(dataList: CustomerListDataDto[]): CustomerDef[] {
  return dataList.map(customer => mapCustomerDef(customer));
}

export function mapCustomerDef(data: CustomerListDataDto): CustomerDef {
  return {
    id: data.id,
    name: data.attributes.name,
    workstations: mapWorkstationsDef(data.attributes.workstations)
  };
}

export function mapWorkstationDef(workstation: WorkstationDto): WorkstationDef {
  return {
    id: workstation.id,
    code: workstation.code,
    active: workstation.active,
    closureStatus: workstation.closureStatus,
    deletable: workstation.deletable
  };
}

export function mapWorkstationsDef(workstations: WorkstationDto[]): WorkstationDef[] {
  return workstations.map(workstation => mapWorkstationDef(workstation));
}

export function mapWorkstationTssStatusDef( data: WorkstationTssDataDto): WorkstationTssDataDef {
  return {
    status: data.status,
    dateOfActivation: data.dateOfActivation,
    clientId: data.clientId,
    openTransactions: data.openTransactions,
    connectionStatus: data.connectionStatus,
    maxTransactions: data.maxTransactions,
    firstTransactionDate: data.firstTransactionDate,
    organizationId: data.organizationId,
    tssId: data.tssId,
    error: ''
  };
}

export function mapWorkstationDtoData ( workstationDtoData: WorkstationDtoData): WorkstationResponse {
  return {
    id: workstationDtoData.id,
    code: workstationDtoData.attributes.workstationCode,
    active: workstationDtoData.attributes.active,
    closureStatus: workstationDtoData.attributes.closureStatus,
    deletable: workstationDtoData.attributes.deletable
  }
}

export function mapWorkstationDtoResponse ( workstationDtoResponse: WorkstationDtoResponse): WorkstationResponse {
  return mapWorkstationDtoData(workstationDtoResponse.data);
}

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as info from '../../pipelineinfo.json';
import { ErrorCode } from '@g3p/services/http-error-interceptor.service';

@Injectable({providedIn: 'root'})
export class AppVersionService {
  constructor(private http: HttpClient) {
  }

  fetchVersion() {
    let localAppVersion = localStorage.getItem("APP_VERSION");
    const clientVersion = this.getInfo();

    this.getServerVersion().pipe(
      tap((serverVersion: string) => {
        const lastVersion = clientVersion > serverVersion ? clientVersion : serverVersion;
        if(localAppVersion !== lastVersion) {
          localStorage.setItem('APP_VERSION', lastVersion)
        }
      }),
    ).subscribe();
  }

  private getServerVersion(): Observable<String> {
    return this.http.get(environment.apiUrl + '/cashbook/version').pipe(
      map((response: any) => response.version)
    );
  }

  getLocalAppVersion() {
    const localAppVersion = localStorage.getItem("APP_VERSION");
    if(localAppVersion === null) {
      throwError(() => new Error(ErrorCode.OUTDATED_APP.toString()))
    }
    return localAppVersion ? localAppVersion : '';
  }

  private getInfo(): string {
    return (JSON.parse(JSON.stringify(info)).default as {tagVersion: string}).tagVersion;
  }
}

import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { CustomerDetailsState } from './customer-details.state';
import * as fromSelector from './customer-details.selectors';
import * as fromActions from './customer-details.actions'
import { CustomerDetailsDef } from '@g3p/customer/interfaces/customer-details-def.interface';
import { WorkstationResponse } from "@g3p/customer/interfaces/customer-details-dto.interface";

@Injectable({ providedIn: 'root' })
export class CustomerDetailsFacade {
  constructor(private store$: Store<CustomerDetailsState>) {}

  fetchCurrentCustomer(id: string): void {
    this.store$.dispatch(new fromActions.GetCurrentCustomerRequest({id}));
  }

  getCurrentCustomer$(): Observable<CustomerDetailsDef> {
    return this.store$.select(fromSelector.customerDetailsState);
  }

  getCustomerName$(): Observable<string> {
    return this.store$.select(fromSelector.customerNameState);
  }

  getCustomerId$(): Observable<string> {
    return this.store$.select(fromSelector.customerIdState);
  }

  getCustomerHasTse$(): Observable<boolean> {
    return this.store$.select(fromSelector.customerHasTseState);
  }

  getCustomerHasOpenTransactions$(): Observable<boolean> {
    return this.store$.select(fromSelector.customerHasOpenTransactions);
  }

  toggleWorkstationActiveStatus(customerId: string, workstation: WorkstationResponse) {
    this.store$.dispatch(new fromActions.ToggleWorkstationActiveStatusRequest({customerId: customerId, workstation: workstation}));
  }

  openWorkstationToggleModal() {
    this.store$.dispatch(new fromActions.OpenWorkstationToggleModal());
  }

  closeWorkstationToggleModal() {
    this.store$.dispatch(new fromActions.CloseWorkstationToggleModal());
  }

  isWorkstationToggleModalOpen$(): Observable<boolean> {
    return this.store$.select(fromSelector.workstationToggleModalState);
  }

  getBusy$(): Observable<boolean> {
    return this.store$.select(fromSelector.isBusyState);
  }

  resetCurrentCustomer(): void {
    this.store$.dispatch(new fromActions.Reset());
  }
}

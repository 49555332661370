import {
  CustomerDef,
  WorkstationFormDef,
  WorkstationTssDataDef
} from '@g3p/workstation/interfaces/workstation-def.interface';

export interface WorkstationState {
  busy: boolean;
  form: WorkstationFormDef;
  customers: CustomerDef[];
  customerName: string;
  workstationName: string;
  workstationId: string;
  workstationTssStatus: WorkstationTssDataDef;
  overlayHelp: boolean;
}

export const initialWorkstationState: WorkstationState = {
  busy: false,
  form: null,
  customers: [],
  customerName: '',
  workstationName: '',
  workstationId: '',
  workstationTssStatus: null,
  overlayHelp: false
};

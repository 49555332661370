import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[g3pPasswordReveal]'
})
export class PasswordRevealDirective implements AfterViewInit, OnChanges {
  @Input() hasError = false;
  private isHide = true;
  private input: HTMLInputElement;
  private container: HTMLElement;
  private icon: HTMLElement;

  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    this.setup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateUI();
  }

  private revealPassword(): void {
    this.input.focus();
    this.input.selectionEnd = this.input.value?.length;
    this.isHide = !this.isHide;
    this.input.type = this.isHide ? 'password' : 'text';
  }

  private setup(): void {
    this.container = this.element.nativeElement as HTMLElement;
    this.input = this.container.querySelector('input');
    this.icon = this.container.querySelector('mat-icon') as HTMLElement;
    if (this.icon) {
      this.icon.addEventListener('click', (event: MouseEvent) => {
        event.stopPropagation();
        this.revealPassword();
        this.updateUI();
      });
    }
  }

  private updateUI(): void {
    this.icon && (this.icon.style.color = this.isHide || this.hasError ? '' : '#0052f0');
  }
}

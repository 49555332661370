import cloneDeep from 'lodash/cloneDeep';

import { newState } from '@g3p/shared/helpers/app-helpers';
import { CustomerCreateState, initialCustomerCreateState } from './customer-create.state';
import { CustomerCreateActionTypes } from './customer-create.actions';
import { mapCustomerForm } from '@g3p/customer/helpers/mappers';

export function customerCreateReducer(
  state: CustomerCreateState = cloneDeep(initialCustomerCreateState),
  action
): CustomerCreateState {
  switch (action.type) {
    case CustomerCreateActionTypes.RESET:
      return { ...initialCustomerCreateState }

    case CustomerCreateActionTypes.OPEN_CREATE_DIALOG:
      return newState(state, {
        isOpened: true,
        isEditMode: false
      });

    case CustomerCreateActionTypes.OPEN_UPDATE_DIALOG:
      return newState(state, {
        isOpened: true,
        isEditMode: true
      });

    case CustomerCreateActionTypes.CLOSE_CREATE_DIALOG:
      return newState(state, {
        isOpened: false,
        createForm: initialCustomerCreateState.createForm
      });

    case CustomerCreateActionTypes.UPDATE_WORKSTATION_FORM_DATA:
      return newState(state, {
        createForm: {
          ...state.createForm,
          workstationForm: action.form
        }
      });

    case CustomerCreateActionTypes.UPDATE_CUSTOMER_DATA_FORM_DATA:
      return newState(state, {
        createForm: {
          ...state.createForm,
          customerDataForm: action.form
        }
      });

    case CustomerCreateActionTypes.UPDATE_ACCOUNT_FORM_DATA:
      return newState(state, {
        createForm: {
          ...state.createForm,
          accountForm: action.form
        }
      });

    case CustomerCreateActionTypes.UPDATE_CREATE_FORM_DATA:
      return newState(state, {
        createForm: mapCustomerForm(action.customer)
      });

    case CustomerCreateActionTypes.GET_BUSINESS_UNITS_SUCCESS:
      return newState(state, {
        businessUnits: action.businessUnits
      });

    case CustomerCreateActionTypes.OPEN_DELETE_MODAL:
      return newState(state, {
        isCustomerDeletionModalOpened: true
      });

    case CustomerCreateActionTypes.CLOSE_DELETE_MODAL:
      return newState(state, {
        isCustomerDeletionModalOpened: false
      });

    case CustomerCreateActionTypes.DELETE_CUSTOMER_REQUEST:
      return newState(state, {
        busy: true
      });

    case CustomerCreateActionTypes.DELETE_CUSTOMER_SUCCESS:
      return newState(state, {
        busy: false
      });

    case CustomerCreateActionTypes.DELETE_CUSTOMER_FAILURE:
      return newState(state, {
        busy: false
      });

    case CustomerCreateActionTypes.OPEN_CUSTOMER_DELETION_STATE_MODAL:
      return newState(state, {
        isCustomerDeletionStateModalOpened: true
      });

    case CustomerCreateActionTypes.CLOSE_CUSTOMER_DELETION_STATE_MODAL:
      return newState(state, {
        isCustomerDeletionStateModalOpened: false
      });

    case CustomerCreateActionTypes.GET_CUSTOMER_DELETION_STATE_LIST_SUCCESS:
      return newState(state, {
        customerDeletionStateList: action.customerDeletionStateList
      });

    default:
      return state;
  }
}

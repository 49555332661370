import { CustomerDetailsDef } from '@g3p/customer/interfaces/customer-details-def.interface';

export interface CustomerDetailsState {
  currentCustomer: CustomerDetailsDef
  isWorkstationToggleModalOpen: boolean
  busy: boolean
}

export const initialCustomerDetailsState = {
  currentCustomer: null,
  isWorkstationToggleModalOpen: false,
  busy: false
};

<g3-modal-regular>
    <g3-modal-title>
        <mat-icon matSuffix class="mdi mdi-36px mdi-alert-circle-outline"></mat-icon>
        <ng-container *ngIf='data.errorCode === errorCode.TIMEOUT_ERROR; else appVersionOutdatedTitle'>
            {{ 'content.timeout-error-title' | translate }}
        </ng-container>
        <ng-template #appVersionOutdatedTitle>
            {{ 'content.new-version-available-title' | translate }}
        </ng-template>
    </g3-modal-title>
    <g3-modal-body>
        <ng-container *ngIf='data.errorCode === errorCode.TIMEOUT_ERROR; else appVersionOutdatedDetails'>
            {{ 'content.timeout-error-description' | translate }}
        </ng-container>
        <ng-template #appVersionOutdatedDetails>
            {{ 'content.new-version-available-description' | translate }}
        </ng-template>
    </g3-modal-body>
    <g3-modal-footer>
        <button
            mat-button
            color="primary"
            data-automation="Cashbook - Error dialog"
            (click)="close()"
            *ngIf='data.errorCode !== errorCode.OUTDATED_APP'
        >{{ 'content.close' | translate }}</button>
        <button
            mat-button
            color="primary"
            data-automation="Cashbook - Error dialog"
            (click)="onOk()"
            *ngIf='data.errorCode === errorCode.OUTDATED_APP'
        >{{ "content.ok" | translate }}</button>
    </g3-modal-footer>
</g3-modal-regular>


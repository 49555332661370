import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { takeWhile } from 'rxjs/operators';

import { CustomerCreateFacade } from '@g3p/customer/components/customer-create-modal/store/customer-create.facade';
import { CustomerListFacade } from '@g3p/customer/components/customer-list/store/customer-list.facade';
import { BusinessUnitDef } from '@g3p/customer/interfaces/customer-def.interface';
import { CustomerListFilterDef } from '@g3p/customer/interfaces/customer-list-def.interface';

@Component({
  selector: 'g3p-customer-list-filter-modal',
  templateUrl: './customer-list-filter-modal.component.html',
  styleUrls: ['./customer-list-filter-modal.component.scss']
})
export class CustomerListFilterModalComponent implements OnInit, OnDestroy {
  filtersForm: FormGroup = new FormGroup({});
  businessUnitList: BusinessUnitDef[];

  selectedBusinessUnit: string;

  private alive = true;

  constructor(
    private formBuilder: FormBuilder,
    private customerListFacade: CustomerListFacade,
    private createFacade: CustomerCreateFacade
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getActiveFilters();
    this.getBusinessUnits();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onReset(): void {
    this.filtersForm.patchValue({
      companyName: '',
      businessUnitName: '',
      customerNumber: '',
      postalCode: '',
      city: '',
      query: ''
    });
    this.customerListFacade.setFilter(this.getFilter() as CustomerListFilterDef);
  }

  onApplyFilters(): void {
    this.customerListFacade.setFilter(this.getFilter() as CustomerListFilterDef);
    this.customerListFacade.fetchCustomers();
  }

  getFilter(): CustomerListFilterDef {
    return <CustomerListFilterDef>{
      ...this.filtersForm.value
    };
  }

  createForm(): void {
    this.filtersForm = this.formBuilder.group({
      companyName: '',
      businessUnitName: '',
      customerNumber: '',
      postalCode: '',
      city: '',
      query: ''
    });
  }

  getActiveFilters(): void {
    this.customerListFacade
      .getFilter$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((filters: any) => {
        this.filtersForm.patchValue({
          companyName: filters.companyName,
          businessUnitName: filters.businessUnitName,
          customerNumber: filters.customerNumber,
          postalCode: filters.postalCode,
          city: filters.city,
          query: filters.query
        });
        this.selectedBusinessUnit = filters.businessUnitName;
      });
  }

  getBusinessUnits(): void {
    this.createFacade
      .getBusinessUnits$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((businessUnitList: BusinessUnitDef[]) => {
        businessUnitList
          ? (this.businessUnitList = businessUnitList.filter(element => element.id !== 'undefined'))
          : null;
      });
  }
}

<g3-modal-overlay>
    <g3-modal-title>
        <mat-icon class="mdi mdi-24px mdi-close" [attr.data-automation]="dataAutomation + ' - Close Filter'"
                  (click)="onCloseFilterDialog()">
        </mat-icon>
    </g3-modal-title>

    <g3-modal-body>
        <ng-content></ng-content>
    </g3-modal-body>

    <g3-modal-footer>
        <button mat-button color="primary" [attr.data-automation]="dataAutomation + ' - Reset Filter'"
                (click)="onReset()">
            {{ "content.reset" | translate }}
        </button>
        <button mat-button mat-dialog-close color="primary" [attr.data-automation]="dataAutomation + ' - Apply Filter'"
                (click)="onApplyFilters()">
            {{ "content.apply" | translate }}
        </button>
    </g3-modal-footer>
</g3-modal-overlay>

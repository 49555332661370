import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  CustomerDetailsFacade
} from "@g3p/customer/components/customer-details/store/customer-details.facade";
import {WorkstationResponse} from "@g3p/customer/interfaces/customer-details-dto.interface";
import {TranslateService} from "@ngx-translate/core";
import {takeWhile} from "rxjs/operators";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'g3p-workstation-toggle-active-modal',
  templateUrl: './workstation-toggle-active-modal.component.html',
  styleUrls: ['./workstation-toggle-active-modal.component.scss']
})
export class WorkstationToggleActiveModalComponent implements OnInit, OnDestroy {

  dialogData: {customerId: string, workstation: WorkstationResponse}
  private alive = true;
  @ViewChild('confirmButton') confirmButton: MatButton;
  @ViewChild('cancelButton') cancelButton: MatButton;

  constructor(
      private facade: CustomerDetailsFacade,
      private dialogRef: MatDialogRef<WorkstationToggleActiveModalComponent>,
      @Inject(MAT_DIALOG_DATA) private data: {customerId: string, workstation: WorkstationResponse},
      private translateService: TranslateService
  ) {
    this.dialogData = data;
  }

  ngOnInit(): void {
    this.notifyDialogOpen();
  }

  confirmationMessage(): string {
    const workstationDialogMessageKey = this.dialogData.workstation.active ? 'content.workstation-toggle-status-dialog-deactivate' : 'content.workstation-toggle-status-dialog-activate';
    return this.translateService.instant(workstationDialogMessageKey, {value: this.dialogData.workstation.code})
  }

  confirmationButton(): string {
    return this.dialogData.workstation.active ? 'content.deactivate' : 'content.activate';
  }

  dialogHeaderText(): string {
    const workstationDialogHeaderKey = this.dialogData.workstation.active ? 'content.workstation-toggle-status-dialog-title-deactivate' : 'content.workstation-toggle-status-dialog-title-activate';
    return this.translateService.instant(workstationDialogHeaderKey, {value: this.dialogData.workstation.code})
  }

  cancel() {
    this.facade.closeWorkstationToggleModal();
  }

  confirm() {
    const toggle = !this.data.workstation.active;
    const updatedWorkstation = {
      ...this.data.workstation,
      active: toggle
    }
    this.facade.toggleWorkstationActiveStatus(this.data.customerId, updatedWorkstation)
    this.facade.getBusy$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((isBusy: boolean) => {
        this.confirmButton.disabled = isBusy;
        this.cancelButton.disabled = isBusy;
      });
  }

  notifyDialogOpen(): void {
    this.facade
    .isWorkstationToggleModalOpen$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((isOpened: boolean) => {
      !isOpened && this.dialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

}

import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';

import { TranslateService } from '@ngx-translate/core';

import { CashbookLocalizationService } from '@cgm/cashbook-ui-lib';

import { AuthService } from '@g3p/services/auth.service';

@Component({
  selector: 'g3p-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private cashbookLocalizationService: CashbookLocalizationService,
    private translateService: TranslateService
  ) {
    this.authService.rehydrateAuthentication();
  }

  ngOnInit() {
    const languages = [environment.defaultLang, ...environment.additionalLanguages];
    this.translateService.addLangs(languages);
    this.translateService.setDefaultLang('de-DE');
    this.translateService.use('de-DE');

    this.cashbookLocalizationService.setLocale('de-DE');
  }
}

import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private static localeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('de-DE');
  private static timezoneSubject: BehaviorSubject<string> = new BehaviorSubject<string>('Europe/Berlin');
  locale$ = LocalizationService.localeSubject.asObservable();
  timezone$ = LocalizationService.timezoneSubject.asObservable();

  setLocale(locale: string): void {
    LocalizationService.localeSubject.next(locale);
  }

  setTimezone(timezone: string): void {
    LocalizationService.timezoneSubject.next(timezone);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { ApiEndpointsUrl } from '@g3p/shared/constants/api-url.constants';
import { mapCustomersDef, mapWorkstationTssStatusDef } from '@g3p/workstation/helpers/mappers';
import { CustomerDef, WorkstationTssDataDef } from '@g3p/workstation/interfaces/workstation-def.interface';
import { CustomerListResponseDto, WorkstationTssResponse } from '@g3p/workstation/interfaces/workstation-dto.interface';

@Injectable({ providedIn: 'root' })
export class WorkstationService {
  constructor(private http: HttpClient) {}

  getCustomer$(userId: string): Observable<CustomerDef[]> {
    return this.http
      .get(environment.apiUrl + ApiEndpointsUrl.USERS.GET_CUSTOMERS_BY_ID.replace('{id}', userId))
      .pipe(map((response: CustomerListResponseDto) => mapCustomersDef(response.data)));
  }

  getWorkstationTssStatus$( workstationId: string): Observable<WorkstationTssDataDef> {
    return this.http
      .get(
        environment.apiUrl + ApiEndpointsUrl.CUSTOMERS.WORKSTATION_TSE_STATUS.replace('{workstationId}', workstationId)
      )
      .pipe(map((response: WorkstationTssResponse) => mapWorkstationTssStatusDef(response.attributes)));
  }
}

<g3-modal-regular>
    <g3-modal-title>
        <span>{{ 'content.customer-deletion-state' | translate }}</span>
    </g3-modal-title>
    <g3-modal-body>
        <div class="customer-deletion-state-table-container">
            <g3-table [showHeader]="false" [showFooter]="false">
                <g3-table-content class="customer-deletion-state-table-content">
                    <div class="customer-deletion-state-scroll-handler">
                        <table
                            mat-table
                            id="dataTable"
                            matSort
                            [dataSource]="dataSource"
                            class="mat-elevation-z8"
                            data-automation="Cashbook Admin - customer-deletion-state table"
                        >
                            <ng-container [matColumnDef]="column"
                                          *ngFor="let column of displayedColumns">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ displayedColumnsData[column] | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element; let i = index">
                                    <div *ngIf="column === 'customerId'">
                                        {{ (element[column]) ? element[column]
                                        : ('content.all' | translate) }}
                                    </div>
                                    <div *ngIf="column === 'customerName'">
                                        {{ element[column] }}
                                    </div>
                                    <div *ngIf="column === 'status'">
                                        {{ element[column] }}
                                    </div>
                                    <div *ngIf="column === 'message'">
                                        {{ element[column] }}
                                    </div>
                                    <div *ngIf="column === 'startDate'">
                                        {{ element[column] | g3Date }}
                                    </div>
                                    <div *ngIf="column === 'documentDownload'">
                                        <mat-icon
                                            matPrefix
                                            class="mdi mdi-24px mdi-download"
                                            (click)="downloadDocument(i)"
                                        ></mat-icon>
                                    </div>
                                </td>
                            </ng-container>
                            <tr
                                mat-header-row
                                *matHeaderRowDef="displayedColumns"
                                data-automation="Cashbook Admin - customer-deletion-state - table header column"
                            ></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns"
                                data-automation="Cashbook Admin - customer-deletion-state - table row"
                            ></tr>
                        </table>
                    </div>
                </g3-table-content>
                <g3-table-footer-left data-automation="Cashbook Admin - customer-deletion-state - table footer">
                </g3-table-footer-left>
            </g3-table>
        </div>
    </g3-modal-body>
    <g3-modal-footer>
        <button
            mat-button
            color="primary"
            data-automation="Customer deletion - Confirm Button"
            (click)="close()"
        >{{ 'content.close' | translate }}</button>
    </g3-modal-footer>
    <div
        class="no-results-found"
        *ngIf="dataSource?.data?.length === 0"
        data-automation="Cashbook Admin - customer-deletion-state table - no results found"
    >
        {{ "content.error" | translate }}
    </div>
</g3-modal-regular>

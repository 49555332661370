import { Action } from '@ngrx/store';

export enum CustomerActionTypes {
  RESET = '[Cashbook - Admin page] Reset Customer tab',
}

export class Reset implements Action {
  readonly type = CustomerActionTypes.RESET;
}

export type CustomerActions =
  | Reset;

import { Injectable } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';

import { KeycloakTokenParsed } from 'keycloak-js';

import { AuthFacade } from '@g3p/store/auth/auth.facade';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private authFacade: AuthFacade, private keycloakService: KeycloakService) {}

  rehydrateAuthentication(): void {
    this.keycloakService.isLoggedIn().then((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.authFacade.setTokenData(this.getLoggedUser());
      }
    });
  }

  getLoggedUser(): KeycloakTokenParsed {
    const instance = this.keycloakService.getKeycloakInstance();
    const user =  instance.idTokenParsed;
    user.roles = instance.resourceAccess['cashbook-standalone']?.roles || [];
    return user;
  }
}

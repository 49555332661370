import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { mapCustomerListResponseDto } from '@g3p/customer/helpers/mappers';
import {
  CustomerListDef,
  CustomerListFilterDef,
  CustomerSearchRequest
} from '@g3p/customer/interfaces/customer-list-def.interface';
import { CustomerListResponseDto } from '@g3p/customer/interfaces/customer-list-dto.interface';
import { ApiEndpointsUrl } from '@g3p/shared/constants/api-url.constants';

@Injectable({ providedIn: 'root' })
export class CustomerListService {
  constructor(private http: HttpClient) {}

  getCustomersList$(
    searchRequest: CustomerSearchRequest,
    filter: CustomerListFilterDef
  ): Observable<CustomerListDef[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('page', searchRequest.page.number);
    httpParams = httpParams.append('size', searchRequest.page.size);

    if (filter.query) {
      httpParams = httpParams.append('query', filter.query);
    }

    if (filter.companyName) {
      httpParams = httpParams.append('companyName', filter.companyName);
    }

    if (filter.businessUnitName) {
      httpParams = httpParams.append('businessUnitName', filter.businessUnitName);
    }

    if (filter.customerNumber) {
      httpParams = httpParams.append('customerNumber', filter.customerNumber);
    }

    if (filter.postalCode) {
      httpParams = httpParams.append('postalCode', filter.postalCode);
    }

    if (filter.city) {
      httpParams = httpParams.append('city', filter.city);
    }

    return this.http
      .get(environment.apiUrl + ApiEndpointsUrl.CUSTOMERS.GET, { params: httpParams })
      .pipe(map((response: CustomerListResponseDto) => mapCustomerListResponseDto(response)));
  }
}

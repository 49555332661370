import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { WorkstationFacade } from '@g3p/workstation/store/workstation.facade';
import { WorkstationFormDef } from '@g3p/workstation/interfaces/workstation-def.interface';
import { AppVersionService } from '@g3p/services/app-version.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  session: any;
  private subscription: Subscription;

  constructor(private facade: WorkstationFacade, private appVersionService: AppVersionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.getSessionState();
    const update = this.addHeaders(req, this.session);
    const request = req.clone(update);
    return next.handle(request).pipe(
      finalize(() => this.subscription.unsubscribe())
    );
  }

  getSessionState(): void {
    this.subscription = this.facade.getFormState$()
      .subscribe((session: WorkstationFormDef) => this.session = session);
  }

  addHeaders(req: HttpRequest<any>, session: any): any {
    let headers = req.headers;

    headers = this.setVersion(headers, req);

    if (session?.customer) {
      headers = headers.set('cid', session.customer);
    }
    if (session?.workstation) {
      headers = headers.set('wid', session.workstation);
    }
    if (session?.isAdminMode) {
      headers = headers.set('adm', session.isAdminMode.toString())
    }
    headers = headers.set('tz-offset', this.getTimeZoneOffset());

    return { headers };
  }

  setVersion(headers: HttpHeaders, req: HttpRequest<any>): HttpHeaders {
    let versionPath = req.url.split("/");
    if(versionPath[versionPath.length-1] === "version") {
      // No need to add app-version header for version endpoint.
      return headers;
    }
    return headers.set('app-version', this.appVersionService.getLocalAppVersion());
  }

  getTimeZoneOffset(): string {
    const tzOffset = new Date().toTimeString().match(/([\+-]\d{4})/)[0];
    return [tzOffset.slice(0, 3), ':', tzOffset.slice(3)].join('');
  }
}

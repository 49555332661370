import { Action } from '@ngrx/store';

import { KeycloakTokenParsed } from 'keycloak-js';

export enum AuthActionTypes {
  SET_TOKEN_DATA = '[Auth] Set token data'
}

export class SetTokenData implements Action {
  readonly type = AuthActionTypes.SET_TOKEN_DATA;
  constructor(public payload: { token: KeycloakTokenParsed }) {}
}

export type AuthActions = SetTokenData;

import { Action } from '@ngrx/store';

import { CustomerListDef, CustomerListFilterDef } from '@g3p/customer/interfaces/customer-list-def.interface';

export enum CustomerListActionTypes {
  GET_CUSTOMER_LIST_REQUEST = '[Cashbook - Admin page] Get Customer list - Request',
  GET_CUSTOMER_LIST_SUCCESS = '[Cashbook - Admin page] Get Customer list - Success',
  GET_CUSTOMER_LIST_ERROR = '[Cashbook - Admin page] Get Customer list - Error',
  INCREASE_PAGE_NUMBER = '[Cashbook - Admin page] Increase page number',
  SET_CUSTOMER_LIST_FILTER = '[Cashbook - Admin page] Set Customer list filter',
  SET_CUSTOMER_LIST_SEARCH_QUERY = '[Cashbook - Admin page] Set Customer list search query',
  RESET = '[Cashbook - Admin page] Reset Customer List State'
}

export class GetCustomerListRequest implements Action {
  readonly type = CustomerListActionTypes.GET_CUSTOMER_LIST_REQUEST;
}

export class GetCustomerListSuccess implements Action {
  readonly type = CustomerListActionTypes.GET_CUSTOMER_LIST_SUCCESS;
  constructor(public payload: { customerList: CustomerListDef[] }) {}
}

export class GetCustomerListError implements Action {
  readonly type = CustomerListActionTypes.GET_CUSTOMER_LIST_ERROR;
}

export class IncreasePageNumber implements Action {
  readonly type = CustomerListActionTypes.INCREASE_PAGE_NUMBER;
}

export class SetCustomerListFilter implements Action {
  readonly type = CustomerListActionTypes.SET_CUSTOMER_LIST_FILTER;

  constructor(public payload: { filters: CustomerListFilterDef }) {}
}

export class SetCustomerListSearchQuery implements Action {
  readonly type = CustomerListActionTypes.SET_CUSTOMER_LIST_SEARCH_QUERY;

  constructor(public payload: { searchString: string }) {}
}

export class Reset implements Action {
  readonly type = CustomerListActionTypes.RESET;
}

export type CustomerListActions =
  | GetCustomerListRequest
  | GetCustomerListSuccess
  | GetCustomerListError
  | IncreasePageNumber
  | SetCustomerListFilter
  | SetCustomerListSearchQuery
  | Reset;

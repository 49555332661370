import { Injectable } from '@angular/core';

import { fromEvent, Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import {
  CustomerDef,
  WorkstationFormDef,
  WorkstationTssDataDef
} from '@g3p/workstation/interfaces/workstation-def.interface';
import { WorkstationState } from '@g3p/workstation/store/workstation.state';

import * as fromActions from './workstation.actions';
import * as fromSelectors from './workstation.selector';

@Injectable({ providedIn: 'root' })
export class WorkstationFacade {
  constructor(private store$: Store<WorkstationState>) {
    fromEvent(window, 'fetchTssStatus')
      .subscribe(() => this.store$.dispatch(fromActions.GetWorkstationTssStatusRequest()));}

  fetchCustomers(): void {
    this.store$.dispatch(fromActions.GetCustomerRequest());
  }

  getCustomers$(): Observable<CustomerDef[]> {
    return this.store$.select(fromSelectors.customersState);
  }

  fetchWorkstationTssStatus(): void {
    this.store$.dispatch(fromActions.GetWorkstationTssStatusRequest());
  }

  getWorkstationTssStatus$(): Observable<WorkstationTssDataDef> {
    return this.store$.select(fromSelectors.workstationTssStatusState);
  }

  getRegistered$(): Observable<boolean> {
    return this.store$.select(fromSelectors.registeredState);
  }

  setRegistered(customer: string, workstation: string, workstationId: string): void {
    this.store$.dispatch(
      fromActions.SetRegistered({ customer: customer, workstation: workstation, workstationId: workstationId })
    );
  }

  getBusyState$(): Observable<boolean> {
    return this.store$.select(fromSelectors.busyState);
  }

  getFormState$(): Observable<WorkstationFormDef> {
    return this.store$.select(fromSelectors.formState);
  }

  getAdminMode$(): Observable<boolean> {
    return this.store$.select(fromSelectors.adminModeState);
  }

  getWorkstationId$(): Observable<string> {
    return this.store$.select(fromSelectors.workstationIdState);
  }

  reset(): void {
    this.store$.dispatch(fromActions.Reset());
  }

  updateForm(form: WorkstationFormDef): void {
    this.store$.dispatch(fromActions.UpdateForm({ form }));
  }

  getCustomerName(): Observable<string> {
    return this.store$.select(fromSelectors.customerState);
  }

  getWorkstationName(): Observable<string> {
    return this.store$.select(fromSelectors.workstationState);
  }

  setOverlayHelp(overlayHelp: boolean): void {
    return this.store$.dispatch(fromActions.SetOverlayHelp({ overlayHelp }));
  }

  getOverlayHelp$(): Observable<boolean> {
    return this.store$.select(fromSelectors.overlayHelpState);
  }
}

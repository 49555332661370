<div class="creation-vendor-login-credentials"
     [attr.data-automation]="dataAutomation + '- form'">
    <form [formGroup]="customerDataFormGroup">
        <!-- institution name -->
        <div class="customer-data-row">
            <mat-form-field appearance="outline" class="input-field-large input-full-width">
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    maxlength="100"
                    formControlName="institutionName"
                    (blur) = updateOrganizationName()
                    [attr.data-automation]="dataAutomation + '- institutionName'"
                    required
                    [g3FormValidation]="formValidation"
                />
                <mat-label
                    [attr.data-automation]="dataAutomation + '- institutionName label'">
                    {{ "content.institution-name" | translate }}
                </mat-label>
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.institutionName)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.institutionName, 'required')">
                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- organization name -->
        <div class="customer-data-row">
            <mat-form-field appearance="outline" class="input-field-large input-full-width">
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    maxlength="100"
                    formControlName="organizationName"
                    [attr.data-automation]="dataAutomation + '- organizationName'"
                    required
                    [g3FormValidation]="formValidation"
                />
                <mat-label
                    [attr.data-automation]="dataAutomation + '- organizationName label'">
                    {{ "content.organization-name" | translate }}
                </mat-label>
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.organizationName)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.organizationName, 'required')">
                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- zip + city -->
        <div class="customer-data-step__zip_city__selection customer-data-row">
            <mat-form-field appearance="outline" class="input-field-large"
                            data-automation="Create Customer Data - postalCode"
            >
                <mat-label [attr.data-automation]="dataAutomation + '- postalCode label'">
                    {{ "content.zip" | translate }}
                </mat-label>
                <input matInput
                       formControlName="postalCode"
                       autocomplete="off"
                       type="text"
                       required
                       minlength="5"
                       maxlength="5"
                       [g3FormValidation]="formValidation"
                       g3InputWithoutDecimal
                       [showThousandSeparator]="false"
                       #postalCode
                />
                <mat-hint align="end">
                    {{ postalCode.value.length + "/" + postalCode.maxLength }}
                </mat-hint>
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.postalCode)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.postalCode, 'required')">
                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                </mat-error>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.postalCode, 'minlength')">
                    <span>{{ "content.invalid-length" | translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="input-field-large">
                <mat-label data-automation="Vendor Create Customer Data  - city label">
                    {{ "content.city" | translate }}
                </mat-label>
                <input
                    matInput
                    formControlName="city"
                    maxlength="50"
                    autocomplete="off"
                    type="text"
                    data-automation="Vendor Create Customer Data  - city"
                    required
                    [g3FormValidation]="formValidation"
                />
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.city)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.city, 'required')">
                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- street -->
        <div class="customer-data-row">
            <mat-form-field appearance="outline" class="input-field-large input-full-width">
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    formControlName="street"
                    maxlength="100"
                    data-automation="Vendor Create Customer Data  - street"
                    required
                    [g3FormValidation]="formValidation"
                />
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.street)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.street, 'required')">
                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                </mat-error>
                <mat-label data-automation="Vendor Create Customer Data - street label">
                    {{ "content.street" | translate }}
                </mat-label>
            </mat-form-field>
        </div>
        <!-- vat + bsnr -->
        <div class="customer-data-step__50_50__selection customer-data-row">
            <mat-form-field appearance="outline" class="input-field-large"
                            data-automation="Create Customer Data - vatId"
            >
                <mat-label [attr.data-automation]="dataAutomation + '-vatId label'">
                    {{ "content.vatId" | translate }}
                </mat-label>
                <input matInput
                       formControlName="vatId"
                       autocomplete="off"
                       type="text"
                />
            </mat-form-field>
            <mat-form-field appearance="outline" class="input-field-large"
                            data-automation="Create Customer Data - fiscalCode"
            >
                <mat-label [attr.data-automation]="dataAutomation + '-fiscalCode label'">
                    {{ "content.fiscalCode" | translate }}
                </mat-label>
                <input matInput
                       formControlName="fiscalCode"
                       autocomplete="off"
                       type="text"
                />
            </mat-form-field>
        </div>
        <!-- locationNumber -->
        <div class="customer-data-row">
            <mat-form-field appearance="outline" class="input-field-large input-full-width">
                <mat-label data-automation="Vendor Create Customer Data  - locationNumber label">
                    {{ "content.company-number" | translate }}
                </mat-label>
                <input
                    matInput
                    formControlName="locationNumber"
                    autocomplete="off"
                    type="text"
                    [attr.data-automation]="dataAutomation + '- locationNumber'"
                    required
                    [g3FormValidation]="formValidation"
                />
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.locationNumber)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.locationNumber, 'required')">
                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- email -->
        <div class="customer-data-row">
            <mat-form-field appearance="outline" class="input-field-large input-full-width">
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    formControlName="email"
                    [attr.data-automation]="dataAutomation + '-email'"
                    [g3FormValidation]="formValidation"
                />
                <mat-label
                    [attr.data-automation]="dataAutomation + '-email label'">
                    {{ "content.email" | translate }}
                </mat-label>
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.email)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.email, 'pattern')">
                    <span>{{ "content.enter-valid-email-address" | translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- AIS Name + Number -->
        <div class="customer-data-step__50_50__selection customer-data-row">
            <mat-form-field appearance="outline" class="input-field-large"
                            data-automation="Create Customer Data - businessUnitId"
            >
                <mat-select
                    data-automation="Customer Create Customer Data - businessUnitId selector"
                    formControlName="businessUnitName"
                    ngDefaultControl
                    required
                    (selectionChange)="handleSelectionChange($event)"
                    [g3FormValidation]="formValidation"
                >
                    <mat-option *ngFor="let type of businessUnits" [value]="type.name">
                        {{ type.name }}
                    </mat-option>
                </mat-select>
                <mat-label
                    [attr.data-automation]="dataAutomation + '- businessUnitId label'">
                    {{ "content.ais-name" | translate }}
                </mat-label>
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.businessUnitName)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.businessUnitName, 'required')">
                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="input-field-large">
                <mat-label
                    [attr.data-automation]="dataAutomation + '- customerNumber label'">
                    {{ "content.ais-customer-number" | translate }}
                </mat-label>
                <input
                    matInput
                    formControlName="customerNumber"
                    maxlength="10"
                    autocomplete="off"
                    type="text"
                    required
                    [attr.data-automation]="dataAutomation + '- customerNumber'"
                    [g3FormValidation]="formValidation"
                />
                <mat-icon matSuffix *ngIf="formValidation.isErrorState(customerDataFormGroup.controls.customerNumber)"
                          class="mdi mdi-24px mdi-alert-circle">
                </mat-icon>
                <mat-error *ngIf="formValidation.isErrorType(customerDataFormGroup.controls.customerNumber, 'required')">
                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- hidden fields-->
        <input type="hidden"
               formControlName="businessUnitId"
               [attr.data-automation]="dataAutomation + '- businessUnitId'" />
        <input type="hidden"
               formControlName="id"
               [attr.data-automation]="dataAutomation + '- Id'" />
    </form>
</div>

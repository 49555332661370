import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { ApiEndpointsUrl } from '@g3p/shared/constants/api-url.constants';
import {
  BusinessUnitDef,
  BusinessUnitListDto,
  CustomerCreateFormDef,
  CustomerUserDef,
  UserUsage,
  UserUsageCriteria
} from '@g3p/customer/interfaces/customer-def.interface';
import {
  CustomerRequestDto,
  UserResponse
} from '@g3p/customer/interfaces/customer-dto.interface';
import {
  mapBusinessUnitList, mapCustomerDeletionStateListDto,
  mapCustomerDetailsResponseDto,
  mapCustomerRequest,
  mapUserResponse
} from '@g3p/customer/helpers/mappers';
import { CustomerDetailsDef } from '@g3p/customer/interfaces/customer-details-def.interface';
import { CustomerDetailsResponseDto } from '@g3p/customer/interfaces/customer-details-dto.interface';
import {
  CustomerDeletionListResponseDto,
  CustomerDeletionStateListDef
} from "@g3p/customer/interfaces/customer-deletion-state.interface";

@Injectable({
  providedIn: 'root'
})
export class CustomerCreateService {

  constructor(private http: HttpClient) { }

  addNewCustomer$(form: CustomerCreateFormDef): Observable<CustomerDetailsDef> {
    const request: CustomerRequestDto = mapCustomerRequest(form);
    return this.http
      .post(environment.apiUrl + ApiEndpointsUrl.CUSTOMERS.ADD_NEW, request).pipe(
        map((response: CustomerDetailsResponseDto) => {return mapCustomerDetailsResponseDto(response) } )
      );
  }

  updateCustomer$(form: CustomerCreateFormDef): Observable<boolean> {
    const request: CustomerRequestDto = mapCustomerRequest(form);
    return this.http
      .patch(environment.apiUrl + ApiEndpointsUrl.CUSTOMERS.PATCH + form.customerDataForm.id, request).pipe(
        map((response: CustomerRequestDto) => {return !!response } )
      );
  }

  deleteCustomer$(customerId: string): Observable<boolean> {
    return this.http
    .delete(environment.apiUrl + ApiEndpointsUrl.CUSTOMERS.DELETE.replace('{customerId}', customerId), { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => { return response.status === 200 } )
    );
  }

  getBusinessUnits$(): Observable<BusinessUnitDef[]> {
    return this.http
      .get(environment.apiUrl + ApiEndpointsUrl.CATALOG.GET_BUSINESS_UNITS)
      .pipe(map((response: BusinessUnitListDto) => mapBusinessUnitList(response)));
  }

  isUserInUse$(usageCriteria: UserUsageCriteria): Observable<UserUsage> {
    let httpParams = new HttpParams();
    if (usageCriteria.username) {
      httpParams = httpParams.append('username', usageCriteria.username);
    }
    if (usageCriteria.email) {
      httpParams = httpParams.append('email', usageCriteria.email);
    }

    return this.http
      .get(environment.apiUrl + ApiEndpointsUrl.USERS.GET_USAGE, { params: httpParams })
      .pipe(map((response: UserUsage) => response));
  }

  getUserById$(id: string): Observable<CustomerUserDef> {
    return this.http
      .get(environment.apiUrl + ApiEndpointsUrl.USERS.GET_USERS_BY_ID.replace('{id}', id))
      .pipe(map((response: UserResponse) => mapUserResponse(response)));
  }

  getCustomerDeletionStateList$(): Observable<CustomerDeletionStateListDef[]> {
    return this.http
      .get(environment.apiUrl + ApiEndpointsUrl.CUSTOMERS.CUSTOMER_DELETION_STATE_LIST)
      .pipe(map((response: CustomerDeletionListResponseDto) => mapCustomerDeletionStateListDto(response)));
  }
}

import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';

import {Action, Store} from '@ngrx/store';

import {of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {G3ModalService, G3SnackbarService, G3SnackType} from '@cgm/g3-component-lib';

import {CustomerCreateState} from './customer-create.state';
import * as fromActions from './customer-create.actions';
import {CustomerCreateActionTypes} from './customer-create.actions';
import * as fromSelectors from './customer-create.selectors';
import * as fromDetailsSelectors from '../../customer-details/store/customer-details.selectors';
import * as fromListActions from '../../customer-list/store/customer-list.actions';
import * as fromDetailsActions from '../../customer-details/store/customer-details.actions';
import {CustomerDetailsState} from '../../customer-details/store/customer-details.state';
import {CustomerDetailsDef} from '@g3p/customer/interfaces/customer-details-def.interface';
import {
  CustomerCreateService
} from '@g3p/customer/components/customer-create-modal/services/customer-create.service';
import {
  BusinessUnitDef,
  CustomerCreateFormDef
} from '@g3p/customer/interfaces/customer-def.interface';
import {
  CustomerDeletionStateListDef
} from "@g3p/customer/interfaces/customer-deletion-state.interface";

@Injectable({ providedIn: 'root' })
export class CustomerCreateEffects {

  getBusinessUnitsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreateActionTypes.GET_BUSINESS_UNITS_REQUEST),
      switchMap((_: Action) => {
        return this.customerCreateService.getBusinessUnits$().pipe(
          map(
            (businessUnits: BusinessUnitDef[]) =>
              fromActions.GetBusinessUnitsSuccess({ businessUnits: businessUnits })
          ),
          catchError(() => of(fromActions.GetBusinessUnitsFailure()))
        );
      })
    )
  );

  getBusinessUnits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.CustomerCreateActionTypes.OPEN_CREATE_DIALOG,
        fromActions.CustomerCreateActionTypes.OPEN_UPDATE_DIALOG
      ),
      switchMap(() => of(fromActions.GetBusinessUnitsRequest()))
    )
  );

  createCustomerRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreateActionTypes.CREATE_CUSTOMER_REQUEST),
      withLatestFrom(this.customerCreateStore$.select(fromSelectors.customerCreateFormState)),
      switchMap(([, form]: [Action, CustomerCreateFormDef]) => {
        return this.customerCreateService.addNewCustomer$(form).pipe(
          map(
            (customer: CustomerDetailsDef) =>
              fromActions.CreateCustomerSuccess({customer})
          ),
          catchError(() => of(fromActions.CreateCustomerFailure()))
        );
      })
    )
  );

  createNewCustomerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CreateCustomerSuccess),
      switchMap(action => {
        this.openSimpleSnackbar(
          this.translateService.instant('content.new-customer-created-successfully'),
          5000,
          G3SnackType.Success
        );
        return of(
          fromActions.CloseCreateDialog(),
          new fromDetailsActions.GetCurrentCustomerRequest({id: action.customer.id}),
          new fromListActions.GetCustomerListRequest(),
        );
      })
    )
  );

  updateCustomerRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreateActionTypes.UPDATE_CUSTOMER_REQUEST),
      withLatestFrom(this.customerCreateStore$.select(fromSelectors.customerCreateFormState)),
      switchMap(([, form]: [Action, CustomerCreateFormDef]) => {
        return this.customerCreateService.updateCustomer$(form).pipe(
          map(
            () =>
              fromActions.UpdateCustomerSuccess({id: form.customerDataForm.id})
          ),
          catchError(() => of(fromActions.UpdateCustomerFailure()))
        );
      })
    )
  );

  updateCustomerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UpdateCustomerSuccess),
      switchMap((action: any) => {
        this.openSimpleSnackbar(
          this.translateService.instant('content.customer-updated-successfully'),
          5000,
          G3SnackType.Success
        );
        return of(
          fromActions.CloseCreateDialog(),
          new fromListActions.GetCustomerListRequest(),
          new fromDetailsActions.GetCurrentCustomerRequest({id: action.id})
        );
      })
    )
  );

  deleteCustomerRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreateActionTypes.DELETE_CUSTOMER_REQUEST),
      withLatestFrom(this.customerDetailsStore$.select(fromDetailsSelectors.customerDetailsState)),
      switchMap(([, customer]: [Action, CustomerDetailsDef]) => {
        return this.customerCreateService.deleteCustomer$(customer.id).pipe(
          map(
            () =>
              fromActions.DeleteCustomerSuccess({id: customer.id})
          ),
          catchError(() => of(fromActions.DeleteCustomerFailure()))
        );
      })
    )
  );

  deleteCustomerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.DeleteCustomerSuccess),
      switchMap(() => {
        this.openSimpleSnackbar(
          this.translateService.instant('content.customer-deleted-successfully'),
          5000,
          G3SnackType.Success
        );
        return of(
          new fromListActions.GetCustomerListRequest(),
          fromActions.GetCustomerDeletionStateListRequest()
        );
      })
    )
  );

  getCustomerDeletionStateListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreateActionTypes.GET_CUSTOMER_DELETION_STATE_LIST_REQUEST),
      switchMap(() => {
        return this.customerCreateService.getCustomerDeletionStateList$().pipe(
          map(
            (response: CustomerDeletionStateListDef[]) =>
              fromActions.GetCustomerDeletionStateListSuccess({customerDeletionStateList: response})
          ),
          catchError(() => of(fromActions.GetCustomerDeletionStateListFailure()))
        );
      })
    )
  );

  updateCreateFormRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreateActionTypes.UPDATE_CREATE_FORM),
      withLatestFrom(this.customerDetailsStore$.select(fromDetailsSelectors.customerDetailsState)),
      switchMap(([, customer]: [Action, CustomerDetailsDef]) =>
        of(fromActions.UpdateCreateFormData({customer}))
      )
    )
  );

  openUpdateDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreateActionTypes.UPDATE_CREATE_FORM_DATA),
      map(() => fromActions.OpenUpdateDialog())
    )
  );

  getError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.CustomerCreateActionTypes.GET_BUSINESS_UNITS_FAILURE,
        fromActions.CustomerCreateActionTypes.CREATE_CUSTOMER_FAILURE,
        fromActions.CustomerCreateActionTypes.UPDATE_CUSTOMER_FAILURE,
        fromActions.CustomerCreateActionTypes.DELETE_CUSTOMER_FAILURE,
        fromActions.CustomerCreateActionTypes.GET_CUSTOMER_DELETION_STATE_LIST_FAILURE
      ),
      tap(() => this.snackbarService.open(
        this.translateService.instant('content.something-went-wrong'),
        5000,
        G3SnackType.Error
      ))
    ), {dispatch: false}
  );

  private openSimpleSnackbar(message: string, duration: number, type: G3SnackType): void {
    this.snackbarService.open(message, duration, type);
  }

  constructor(
    private actions$: Actions,
    private modalService: G3ModalService,
    private snackbarService: G3SnackbarService,
    private translateService: TranslateService,
    private customerCreateService: CustomerCreateService,
    private customerCreateStore$: Store<CustomerCreateState>,
    private customerDetailsStore$: Store<CustomerDetailsState>
  ) {
  }
}

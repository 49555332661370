import { createAction, props } from '@ngrx/store';

export enum DocumentActionTypes {

  GET_DOCUMENT_REQUEST = '[document-admin] Get document admin - Request',
  GET_DOCUMENT_SUCCESS = '[document-admin] Get document admin - Success',
  GET_DOCUMENT_FAILURE = '[document-admin] Get document admin - Failure',
}

export const GetDocumentRequest = createAction(DocumentActionTypes.GET_DOCUMENT_REQUEST, props<{documentId: string}>());
export const GetDocumentSuccess = createAction(DocumentActionTypes.GET_DOCUMENT_SUCCESS, props<{document: File}>());
export const GetDocumentFailure = createAction(DocumentActionTypes.GET_DOCUMENT_FAILURE);

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CashPointModule } from '@cgm/cashbook-ui-lib';

import { AdminGuard } from '@g3p/guards/admin-guard';
import { AuthGuard } from '@g3p/guards/auth-guard';
import { PathsEnum } from '@g3p/shared/enums/paths.enum';

const routes: Routes = [
  {
    path: PathsEnum.home,
    redirectTo: PathsEnum.customers,
    pathMatch: 'full'
  },
  {
    path: PathsEnum.customers,
    loadChildren: () => import('@g3p/customer/customer.module').then(m => m.CustomerModule),
    canActivate: [AdminGuard],
    data: {
      adminGuardData: {
        redirectToCustomer: false
      }
    }
  },
  {
    path: PathsEnum.registerWorkstation,
    loadChildren: () => import('@g3p/workstation/workstation.module').then(m => m.WorkstationModule),
    canActivate: [AdminGuard],
    data: {
      adminGuardData: {
        redirectToCustomer: true
      }
    }
  },
  {
    path: PathsEnum.cashPoint,
    loadChildren: () => CashPointModule,
    canActivate: [AuthGuard]
  },
  {
    path: PathsEnum.wildCard,
    redirectTo: PathsEnum.customers,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

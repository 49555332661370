import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { TranslateService } from '@ngx-translate/core';

import { G3SnackbarService, G3SnackType } from '@cgm/g3-component-lib';

import { UserCustomerUsageDef } from '@g3p/customer/interfaces/customer-details-def.interface';

import { CustomerUserStatusModalComponent } from '../customer-user-status-modal.component';
import { CustomerUserStatusModalService } from '../services/customer-user-status-modal.service';
import * as fromActions from './customer-user-status-modal.actions';

@Injectable({ providedIn: 'root' })
export class CustomerUserStatusModalEffects {
  openModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.CustomerUserStatusActionTypes.OPEN_UPDATE_CUSTOMER_USER_STATUS_DIALOG),
        tap((data: any) =>
          this.dialog.open(CustomerUserStatusModalComponent, {
            data: { customerUser: data.customerUser, customerId: data.customerId },
            disableClose: true,
            autoFocus: false
          })
        )
      ),
    { dispatch: false }
  );

  closeModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.CustomerUserStatusActionTypes.CLOSE_UPDATE_CUSTOMER_USER_STATUS_DIALOG),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  updateCustomerUserStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_REQUEST),
      switchMap((action: fromActions.UpdateCustomerUserStatusRequest) =>
        this.service.updateCustomerUserStatus(action.customerId, action.userId).pipe(
          map(
            (currentUserCustomerUsage: UserCustomerUsageDef) =>
              new fromActions.UpdateCustomerUserStatusSuccess(currentUserCustomerUsage, action.username)
          ),
          catchError(() => of(new fromActions.UpdateCustomerUserStatusFailure(action.username, action.active)))
        )
      )
    );
  });

  updateCustomerUserStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_SUCCESS),
      switchMap((data: any) => {
        let message = data.payload.active
          ? 'content.customer-user-activate-snackbar-success'
          : 'content.customer-user-deactivate-snackbar-success';
        this.snackbarService.open(
          this.translateService.instant(message, { username: data.username }),
          7000,
          G3SnackType.Success
        );
        return of(new fromActions.CloseUpdateCustomerUserStatusDialog());
      })
    )
  );

  updateCustomerUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_FAILURE),
        tap((data: any) => {
          let message = data.active
            ? 'content.customer-user-deactivate-snackbar-error'
            : 'content.customer-user-activate-snackbar-error';
          this.snackbarService.open(
            this.translateService.instant(message, { username: data.username }),
            7000,
            G3SnackType.Error
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private service: CustomerUserStatusModalService,
    private snackbarService: G3SnackbarService,
    private translateService: TranslateService
  ) {}
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import {
  AccountFormDef,
  BusinessUnitDef,
  CustomerDataFormDef,
  WorkstationFormDef
} from '@g3p/customer/interfaces/customer-def.interface';

import * as fromActions from './customer-create.actions';
import * as fromSelectors from './customer-create.selectors';
import { CustomerCreateState } from './customer-create.state';
import {
  CustomerDeletionStateListDef
} from "@g3p/customer/interfaces/customer-deletion-state.interface";
import {GetDocumentRequest} from "@g3p/customer/store/document/document.actions";


@Injectable({ providedIn: 'root' })
export class CustomerCreateFacade {
  constructor(private store$: Store<CustomerCreateState>) {}

  openCreateDialog(): void {
    this.store$.dispatch(fromActions.OpenCreateDialog());
  }

  openUpdateDialog(): void {
    this.store$.dispatch(fromActions.OpenUpdateDialog());
  }

  closeCreateDialog(): void {
    this.store$.dispatch(fromActions.CloseCreateDialog());
  }

  getCreateDialogOpened$(): Observable<boolean> {
    return this.store$.select(fromSelectors.isOpenedState);
  }

  getEditMode$(): Observable<boolean> {
    return this.store$.select(fromSelectors.isEditModeState);
  }

  getBusyState$(): Observable<boolean> {
    return this.store$.select(fromSelectors.isBusyState);
  }

  createCustomer(): void {
    this.store$.dispatch(fromActions.CreateCustomerRequest());
  }

  updateCustomer(): void {
    this.store$.dispatch(fromActions.UpdateCustomerRequest());
  }

  updateWorkstationForm(form: WorkstationFormDef): void {
    this.store$.dispatch(fromActions.UpdateWorkstationFormData({ form }));
  }

  updateCustomerDataForm(form: CustomerDataFormDef): void {
    this.store$.dispatch(fromActions.UpdateCustomerDataFormData({ form }));
  }

  updateAccountForm(form: AccountFormDef[]): void {
    this.store$.dispatch(fromActions.UpdateAccountFormData({ form }));
  }

  updateCreateForm(): void {
    this.store$.dispatch(fromActions.UpdateCreateForm());
  }

  fetchBusinessUnits(): void {
    this.store$.dispatch(fromActions.GetBusinessUnitsRequest());
  }

  getBusinessUnits$(): Observable<BusinessUnitDef[]> {
    return this.store$.select(fromSelectors.businessUnitsState);
  }

  selectCustomerDataStep$(): Observable<CustomerDataFormDef> {
    return this.store$.select(fromSelectors.customerDataFormState);
  }

  selectAccountStep$(): Observable<AccountFormDef[]> {
    return this.store$.select(fromSelectors.accountFormState);
  }

  selectWorkstationStep$(): Observable<WorkstationFormDef> {
    return this.store$.select(fromSelectors.workstationFormState);
  }

  deleteCustomer(): void {
    this.store$.dispatch(fromActions.DeleteCustomerRequest())
  }

  isCustomerDeletionModalOpened$(): Observable<boolean> {
    return this.store$.select(fromSelectors.customerDeletionModalState)
  }

  openCustomerDeletionModal() {
    this.store$.dispatch(fromActions.OpenDeleteCustomerModal())
  }

  closeCustomerDeletionModal() {
    this.store$.dispatch(fromActions.CloseDeleteCustomerModal())
  }

  isCustomerDeletionStateModalOpened$(): Observable<boolean> {
    return this.store$.select(fromSelectors.customerDeletionStateModalState)
  }

  openCustomerDeletionStateModal() {
    this.store$.dispatch(fromActions.OpenCustomerDeletionStateModal())
  }

  closeCustomerDeletionStateModal() {
    this.store$.dispatch(fromActions.CloseCustomerDeletionStateModal())
  }

  fetchCustomerDeletionStateList() {
    this.store$.dispatch(fromActions.GetCustomerDeletionStateListRequest())
  }

  getCustomerDeletionStateList$(): Observable<CustomerDeletionStateListDef[]> {
    return this.store$.select(fromSelectors.customerDeletionStateList)
  }

  getCustomerDeletionDocument(documentId: string) {
    this.store$.dispatch(GetDocumentRequest({ documentId: documentId }));
  }
}

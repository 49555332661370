import cloneDeep from 'lodash/cloneDeep';

import { newState } from '@g3p/shared/helpers/app-helpers';

import { CustomerListActions, CustomerListActionTypes } from './customer-list.actions';
import { CustomerListState, initialCustomerListState } from './customer-list.state';

export function customerListReducers(
  state: CustomerListState = cloneDeep(initialCustomerListState),
  action: CustomerListActions
): CustomerListState {
  switch (action.type) {
    case CustomerListActionTypes.GET_CUSTOMER_LIST_REQUEST:
      return state;

    case CustomerListActionTypes.GET_CUSTOMER_LIST_ERROR:
      return state;

    case CustomerListActionTypes.GET_CUSTOMER_LIST_SUCCESS:
      return newState(state, {
        customerList: action.payload.customerList
      });

    case CustomerListActionTypes.INCREASE_PAGE_NUMBER:
      return newState(state, {
        search: {
          ...state.search,
          page: {
            ...state.search.page,
            number: state.search.page.number + 1
          }
        }
      });

    case CustomerListActionTypes.SET_CUSTOMER_LIST_FILTER:
      return newState(state, {
        search: {
          ...state.search,
          page: {
            ...state.search.page,
            number: 0
          }
        },
        filters: {
          ...action.payload.filters
        }
      });

    case CustomerListActionTypes.SET_CUSTOMER_LIST_SEARCH_QUERY:
      return newState(state, {
        search: {
          ...state.search,
          page: {
            ...state.search.page,
            number: 0
          }
        },
        filters: {
          ...state.filters,
          query: action.payload.searchString
        }
      });

    case CustomerListActionTypes.RESET:
      return { ...initialCustomerListState };

    default:
      return state;
  }
}

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { G3SnackbarService, G3SnackType } from '@cgm/g3-component-lib';

import * as fromActions from './document.actions';
import { DocumentService } from '../../services/document.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentEffects {
  getDocumentRequest$ = createEffect( () =>
    this.actions$.pipe(
      ofType(fromActions.GetDocumentRequest),
      switchMap( action => {
        return this.service.getDocument$(action.documentId).pipe(
              map((document: File) => fromActions.GetDocumentSuccess({document})),
              catchError(() => of(fromActions.GetDocumentFailure()))
        );
      })
    )
  );

  getDocumentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.GetDocumentSuccess),
        tap( action => {
          const fileBlob = new Blob([action.document], { type: 'application/pdf' });
            window.open(URL.createObjectURL(fileBlob));
        })
      ),
    { dispatch: false }
  );

  getDocumentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.DocumentActionTypes.GET_DOCUMENT_FAILURE
      ),
      tap(() => this.snackbarService.open(
        this.translateService.instant('content.something-went-wrong'),
        5000,
        G3SnackType.Error
      ))
    ), {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private service: DocumentService,
    private snackbarService: G3SnackbarService,
    private translateService: TranslateService
  ) {
  }
}

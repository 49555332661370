import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { G3FormValidation, G3FormValidationType, G3StepperComponent, IG3Step } from '@cgm/g3-component-lib';
import { CustomerCreateFacade } from './store/customer-create.facade';
import { KeyCode } from '@g3p/shared/enums/key-code.enum';

interface StepEvent {
  prev: number;
  next: number;
}
@Component({
  selector: 'g3p-customer-create-modal',
  templateUrl: './customer-create-modal.component.html',
  styleUrls: ['./customer-create-modal.component.scss']
})
export class CustomerCreateModalComponent implements OnInit, OnDestroy {
  @ViewChild('stepper', { static: true }) stepper: G3StepperComponent;
  @ViewChild('customerDataTemplate', { static: true }) customerDataTemplate: TemplateRef<any>;
  @ViewChild('accountTemplate', { static: true }) accountTemplate: TemplateRef<any>;
  @ViewChild('workstationTemplate', { static: true }) workstationTemplate: TemplateRef<any>;

  customerDataValidation = new G3FormValidation({
    [G3FormValidationType.Post]: ['required'],
    [G3FormValidationType.Blur]: ['min', 'max', 'minlength', 'maxlength', 'pattern']
  });

  accountFormValidation = [new G3FormValidation({
    [G3FormValidationType.Post]: ['required'],
    [G3FormValidationType.Blur]: ['min', 'max', 'minlength', 'maxlength']
  })];

  workstationFormValidation = new G3FormValidation({
    [G3FormValidationType.Post]: ['required'],
    [G3FormValidationType.Blur]: ['min', 'max', 'minlength', 'maxlength']
  });

  dataAutomation = 'CUSTOMER-CREATE';
  steps: IG3Step[] = [];
  currentStep = 1;
  editMode = false;
  stepValidation: any = { };
  isInvalid: Subject<boolean> = new BehaviorSubject<boolean>(true);
  disabled = false;

  workstationFormGroup = this.formBuilder.group( { workstations: this.formBuilder.array( [] ) } );
  customerDataFormGroup = new FormGroup({});
  accountFormArray = new FormArray([]);
  accountFormErrors: boolean[] = [];

  private alive = true;
  private onChangeStep$: Subject<StepEvent> = new Subject<StepEvent>();
  private stepDeclarations: { [key: string]: any };

  constructor(
    private formBuilder: FormBuilder,
    private createFacade: CustomerCreateFacade,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<CustomerCreateModalComponent>
  ) { }

  @HostListener('document:keyup.esc', ['$event']) onKeyUp(event: KeyboardEvent): void {
    event.stopPropagation();
    this.cancel();
  }

  ngOnInit(): void {
    this.notifyEditMode();
    this.buildStepDeclarations();
    this.notifyDialogOpen();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  cancel(): void {
    this.createFacade.closeCreateDialog();
  }

  onNext(): void {
    let disabled = false;
    if (this.currentStep === 1){
      this.customerDataValidation.post();
      disabled = this.customerDataFormGroup.invalid;
    }

    if (this.currentStep === 2){
      for (let i = 0; i < this.accountFormArray.length; i++) {
        this.accountFormErrors[i] = !this.accountFormValidation[i].isFormValid(this.accountFormArray.at(i) as FormGroup);
      }
      disabled = this.accountFormArray.invalid;
    }

    if( !disabled && this.steps[this.currentStep-1].stepControl.valid ) {
      this.stepper.next();
    }
    this.disabled = disabled;
  }

  onPrevious(): void {
    this.stepper.previous();
    this.disabled = this.steps[this.currentStep-1].stepControl.invalid;
  }

  onCurrentStep(step: number): void {
    this.notifyStepChange( step - this.currentStep );
    this.currentStep = step;
  }

  createNewCustomer(): void {
    this.workstationFormValidation.post();
    let disabled = this.workstationFormGroup.invalid;
    if ( !disabled && this.steps[ this.currentStep - 1 ].stepControl.valid ) {
      if (this.editMode) {
        this.createFacade.updateCustomer();
      }else {
        this.createFacade.createCustomer();
      }
    }
  }

  isCreateNewCustomerDisabled(): boolean {
    return this.disabled || this.workstationFormGroup.invalid || this.customerDataFormGroup.invalid || this.accountFormArray.invalid;
  }

  notifyStepValidation(valid: boolean, step: string): void {
    if (this.disabled) {
      this.disabled = this.steps[this.currentStep-1].stepControl.invalid;
    }
    this.stepValidation[step] = valid;
    const isInvalid = Object.keys(this.stepValidation).some(_step => !this.stepValidation[_step]);
    this.isInvalid.next(isInvalid);
  }

  buildStepDeclarations(): void {
    const errorTranslationKey1 = 'validation.fill-in-required-fields';
    const allSteps = ['content.customer-data','content.accounts','content.workstations'];

    this.stepDeclarations = {
      'content.customer-data': {
        errorTranslationKey: this.translateService.instant(errorTranslationKey1),
        labelTranslationKey: 'content.customer-data',
        stepTemplate: this.customerDataTemplate,
        stepControl: this.customerDataFormGroup
      },
      'content.accounts': {
        errorTranslationKey: this.translateService.instant(errorTranslationKey1),
        labelTranslationKey: 'content.accounts',
        stepTemplate: this.accountTemplate,
        stepControl: this.accountFormArray
      },
      'content.workstations': {
        errorTranslationKey: this.translateService.instant(errorTranslationKey1),
        labelTranslationKey: 'content.workstations',
        stepTemplate: this.workstationTemplate,
        stepControl: this.workstationFormGroup
      }
    };

    this.steps = allSteps.map(key => this.stepDeclarations[key]);
  }

  getDialogTitle(): string {
    let title: string;
    this.editMode ? title = this.translateService.instant('content.edit-customer') : title = this.translateService.instant('content.create-customer');
    return title;
  }

  notifyDialogOpen(): void {
    this.createFacade
      .getCreateDialogOpened$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((isOpened: boolean) => {
        !isOpened && this.dialogRef.close();
      });
  }

  notifyEditMode(): void {
    this.createFacade
      .getEditMode$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((isEditMode: boolean) => {
        this.editMode = isEditMode;
      });
  }

  handleDialogClose(): void {
    this.dialogRef
      .keydownEvents()
      .pipe(takeWhile(() => this.alive))
      .subscribe((event: KeyboardEvent) => this.handleKeyBoard(event));
  }

  private handleKeyBoard(event: KeyboardEvent): void {
    const code = event.code;
    const isEsc = code === KeyCode.Escape;

    if (isEsc) {
      this.cancel();
    }
  }

  private notifyStepChange(move: number): void {
    const event = { prev: this.currentStep, next: this.currentStep + move };
    this.onChangeStep$.next(event);
  }
}

import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '@env/environment';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { AppRoutingModule } from '@g3p/app-routing.module';
import { AppComponent } from '@g3p/app.component';
import { initializeKeycloak } from '@g3p/shared/factory/initialize-keycloak.factory';
import { SharedModule } from '@g3p/shared/shared.module';
import { appReducers, metaReducers } from '@g3p/store/app-state.reducers';
import { TokenInterceptor } from '@g3p/services/token.interceptor';
import { WorkstationModule } from '@g3p/workstation/workstation.module';
import { CustomerModule } from '@g3p/customer/customer.module';
import { AdminGuard } from '@g3p/guards/admin-guard';
import { AuthGuard } from '@g3p/guards/auth-guard';
import { TranslateLoaderService } from '@g3p/services/translate-loader.service';
import { LocalizationService } from '@g3p/services/localization.service';
import { HttpErrorInterceptor } from '@g3p/services/http-error-interceptor.service';
import { AppVersionService } from '@g3p/services/app-version.service';
import { GlobalErrorHandler } from '@g3p/services/global-error-handler.service';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { LogService } from '@g3p/services/log.service';
import { ApiEndpointsUrl } from '@g3p/shared/constants/api-url.constants';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,

    LoggerModule.forRoot(
      {
        serverLoggingUrl: environment.apiUrl + ApiEndpointsUrl.FRONTEND_LOGGING,
        level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.TRACE,
        serverLogLevel: NgxLoggerLevel.ERROR,
        httpResponseType: 'text',
        disableFileDetails: true,
        disableConsoleLogging: environment.production,
      },
      {
        serverProvider: {
          provide: TOKEN_LOGGER_SERVER_SERVICE,
          useClass: LogService,
        }
      }
    ),

    StoreModule.forRoot(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false
      }
    }),
    EffectsModule.forRoot([]),

    StoreDevtoolsModule.instrument({
      maxAge: 50
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderService,
        deps: [LocalizationService]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),

    KeycloakAngularModule,

    AppRoutingModule,
    SharedModule,
    WorkstationModule,
    CustomerModule
  ],
  providers: [
    AdminGuard,
    AuthGuard,
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: [environment.defaultLang, ...environment.additionalLanguages] }
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(appVersionService: AppVersionService) {
    appVersionService.fetchVersion();
  }
}

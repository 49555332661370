import {Component, OnDestroy, OnInit} from '@angular/core';

import {takeWhile} from 'rxjs/operators';

import {CustomerDetailsDef, CustomerUserDef} from '@g3p/customer/interfaces/customer-details-def.interface';
import {
  CustomerDetailsFacade
} from '@g3p/customer/components/customer-details/store/customer-details.facade';
import {
  ClosureStatus,
  WorkstationResponse
} from "@g3p/customer/interfaces/customer-details-dto.interface";
import {G3ModalService} from "@cgm/g3-component-lib";
import {
  WorkstationToggleActiveModalComponent
} from "@g3p/customer/components/workstation-toggle-active-modal/workstation-toggle-active-modal.component";
import {TranslateService} from "@ngx-translate/core";
import { CustomerUserStatusModalFacade } from '../customer-user-status-modal/store/customer-user-status-modal.facade';

@Component({
  selector: 'g3p-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {
  customerDetails: CustomerDetailsDef;
  private alive = true;
  protected readonly ClosureStatus = ClosureStatus;
  private workstationToggleActiveData: {customerId: string, workstation: WorkstationResponse}

  constructor(
    private facade: CustomerDetailsFacade, private g3modal: G3ModalService, private translateService: TranslateService, 
    private customerUserStatusModalFacade: CustomerUserStatusModalFacade
  ) { }

  ngOnInit(): void {
    this.getCustomerDetails();
    this.notifyWorkstationToggleActiveDialogOpen();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  getCustomerDetails(): void {
    this.facade.getCurrentCustomer$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((details:CustomerDetailsDef) => this.customerDetails = details
      );
  }

  onToggleWorkstationActiveStatus(customerId: string, workstation: WorkstationResponse): void {
    this.workstationToggleActiveData = {customerId: customerId, workstation: workstation};
    this.facade.openWorkstationToggleModal();
  }

  notifyWorkstationToggleActiveDialogOpen(): void {
    this.facade
    .isWorkstationToggleModalOpen$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((isOpened: boolean) => {
      isOpened && this.g3modal.openRegular(WorkstationToggleActiveModalComponent, { remWidth: 38 },
        this.workstationToggleActiveData);
    });
  }

  isToggleWorkstationActiveStatusButtonDisabled(currentWorkstation: WorkstationResponse, workstations: WorkstationResponse[]): boolean {
    const activeWorkstations = workstations.filter((workstation) => workstation.active === true);
    return (currentWorkstation.active && (activeWorkstations.length === 1 || currentWorkstation.closureStatus === ClosureStatus.OPEN))
  }

  toggleWorkstationActiveStatusButtonDisabledTooltip(currentWorkstation: WorkstationResponse, workstations: WorkstationResponse[]): string {
    return this.isToggleWorkstationActiveStatusButtonDisabled(currentWorkstation, workstations) && currentWorkstation.closureStatus === ClosureStatus.OPEN ?
      this.translateService.instant('content.workstation-closure-tooltip') : this.translateService.instant('content.workstation-last-tooltip')
  }


  openCustomerUserStatusModal(currentCustomer: any, user: CustomerUserDef): void {
    this.customerUserStatusModalFacade.openModal(currentCustomer, user, this.customerDetails.id);
  }

  isActive(user: CustomerUserDef): boolean {
    return user.customers
      .find(customerUser => customerUser.customerUserId.customerId === this.customerDetails.id)
      .active;
  }
}

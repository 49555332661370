import {
  CustomerDetailsState,
  initialCustomerDetailsState
} from '@g3p/customer/components/customer-details/store/customer-details.state';
import {
  CustomerListState,
  initialCustomerListState
} from '@g3p/customer/components/customer-list/store/customer-list.state';
import {
  CustomerCreateState,
  initialCustomerCreateState
} from '@g3p/customer/components/customer-create-modal/store/customer-create.state';
import { CustomerUserStatusState, initialCustomerUserStatusState } from '../components/customer-user-status-modal/store/customer-user-status-modal.state';

export interface CustomerState {
  customerDetails: CustomerDetailsState;
  customerList: CustomerListState;
  customerCreateModal: CustomerCreateState;
  customerUserStatusModal: CustomerUserStatusState
}

export const initialCustomersState: CustomerState = {
  customerDetails: initialCustomerDetailsState,
  customerList: initialCustomerListState,
  customerCreateModal: initialCustomerCreateState,
  customerUserStatusModal: initialCustomerUserStatusState
}

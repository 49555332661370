import { IAuthState } from '@g3p/store/auth/interfaces/auth-state.interface';

export const initialAuthState: IAuthState = {
  user: {
    email: undefined,
    firstname: undefined,
    lastname: undefined,
    name: undefined,
    tenantId: undefined,
    username: undefined,
    id: undefined,
    roles: undefined
  }
};

import cloneDeep from 'lodash/cloneDeep';

import { CustomerState, initialCustomersState } from './customer.state';
import * as actions from './customer.actions';
import { customerDetailsReducers } from '@g3p/customer/components/customer-details/store/customer-details.reducers';
import { customerListReducers } from '@g3p/customer/components/customer-list/store/customer-list.reducers';
import { customerCreateReducer } from '@g3p/customer/components/customer-create-modal/store/customer-create.reducers';
import { customerUserStatusReducers } from '../components/customer-user-status-modal/store/customer-user-status-modal.reducer';

export function customerReducers(state: CustomerState = cloneDeep(initialCustomersState), action) {
  switch (action.type) {
    case actions.CustomerActionTypes.RESET:
      return { ...initialCustomersState };

    default:
      return {
        customerDetails: customerDetailsReducers(state.customerDetails, action),
        customerList: customerListReducers(state.customerList, action),
        customerCreateModal: customerCreateReducer(state.customerCreateModal, action),
        customerUserStatusModal: customerUserStatusReducers(state.customerUserStatusModal, action)
      };
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, mergeMap, retryWhen, timeout } from 'rxjs/operators';
import { G3ModalService } from '@cgm/g3-component-lib';
import { ErrorDialogComponent } from '@g3p/shared/components/error-dialog/error-dialog.component';
import { NGXLogger } from 'ngx-logger';

const DEFAULT_MAX_RETRIES: number = 3;
const DEFAULT_TIMEOUT: number = 60_000;
const HTTP_METHOD_GET: string = "GET"; 

export enum ErrorCode {
  TIMEOUT_ERROR,
  OUTDATED_APP
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(public g3modal: G3ModalService, private logger: NGXLogger) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(DEFAULT_TIMEOUT),
      retryWhen(errors => errors.pipe(
        mergeMap((error, retriesCounter) => {
          // retriesCounter start from 0
          let retries = retriesCounter + 1;
          if(retries < DEFAULT_MAX_RETRIES && error.name === 'TimeoutError' && request.method === HTTP_METHOD_GET) {
            return of(null);
          }
          return throwError(error);
        })
      )),
      catchError(error => this.handleError(error))
    );
  }

  private handleError(error: TimeoutError | HttpErrorResponse) {
    if(error instanceof TimeoutError) {
      const errorCode = ErrorCode.TIMEOUT_ERROR
      this.logger.error(errorCode, error);
      this.g3modal.openRegular(ErrorDialogComponent,{ remWidth: 38 }, { errorCode });
    }
    else {
      const outdatedErrorCode = error?.error?.errors?.filter((error: { code: string; }) => error.code === 'OUTDATED_APP')
      if(!outdatedErrorCode || !outdatedErrorCode.length) {
        return throwError(error)
      }
      const errorCode = ErrorCode.OUTDATED_APP
      this.logger.error(errorCode, error);
      this.g3modal.openRegular(ErrorDialogComponent,{ remWidth: 38 }, { errorCode });
    }
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CustomerState } from '@g3p/customer/store/customer.state';
import { StoreConstants } from '@g3p/shared/constants/store.constants';
import { CustomerDetailsDef } from '@g3p/customer/interfaces/customer-details-def.interface';

export const customerStore = createFeatureSelector<CustomerState>(StoreConstants.customer);
export const customerDetailsState = createSelector(customerStore, (state: CustomerState) => state.customerDetails.currentCustomer);

export const customerNameState = createSelector(customerDetailsState, (state: CustomerDetailsDef) => state?.institutionName || null);
export const customerIdState = createSelector(customerDetailsState, (state: CustomerDetailsDef) => state?.id);
export const customerHasTseState = createSelector(customerDetailsState, (state: CustomerDetailsDef) => state?.hasTse)
export const customerHasOpenTransactions = createSelector(customerDetailsState, (state: CustomerDetailsDef) => state?.hasOpenTransactions)

export const workstationToggleModalState = createSelector(customerStore,(state: CustomerState) => state.customerDetails.isWorkstationToggleModalOpen);
export const isBusyState = createSelector(customerStore,(state: CustomerState) => state.customerDetails.busy);

import { createAction, props } from '@ngrx/store';

import {
  CustomerDef,
  WorkstationFormDef,
  WorkstationTssDataDef
} from '@g3p/workstation/interfaces/workstation-def.interface';

export enum WorkstationActionTypes {
  RESET = '[workstation] reset current Configuration',

  GET_CUSTOMER_REQUEST = '[workstation] Get Customer - Request',
  GET_CUSTOMER_SUCCESS = '[workstation] Get Customer - Success',
  GET_CUSTOMER_FAILURE = '[workstation] Get Customer - Failure',

  GET_WORKSTATION_TSS_STATUS_REQUEST = '[workstation] Get Workstation Tss Status - Request',
  GET_WORKSTATION_TSS_STATUS_SUCCESS = '[workstation] Get Workstation Tss Status - Success',
  GET_WORKSTATION_TSS_STATUS_FAILURE = '[workstation] Get Workstation Tss Status - Failure',

  SET_REGISTERED = '[workstation] Set Registered',

  UPDATE_FORM = '[workstation] Update form',
  SET_OVERLAY_HELP = '[workstation] Set overlay help'
}

export const Reset = createAction(WorkstationActionTypes.RESET);

export const GetCustomerRequest = createAction(WorkstationActionTypes.GET_CUSTOMER_REQUEST);
export const GetCustomerSuccess = createAction(
  WorkstationActionTypes.GET_CUSTOMER_SUCCESS,
  props<{ customers: CustomerDef[] }>()
);
export const GetCustomerFailure = createAction(WorkstationActionTypes.GET_CUSTOMER_FAILURE);
export const GetWorkstationTssStatusRequest = createAction(
  WorkstationActionTypes.GET_WORKSTATION_TSS_STATUS_REQUEST
);
export const GetWorkstationTseStatusSuccess = createAction(
  WorkstationActionTypes.GET_WORKSTATION_TSS_STATUS_SUCCESS,
  props<{ workstationTssStatus: WorkstationTssDataDef }>()
);
export const GetWorkstationTseStatusFailure = createAction(
  WorkstationActionTypes.GET_WORKSTATION_TSS_STATUS_FAILURE,
  props<{ error: any }>());
export const SetRegistered = createAction(
  WorkstationActionTypes.SET_REGISTERED,
  props<{ customer: string; workstation: string; workstationId: string }>()
);
export const UpdateForm = createAction(WorkstationActionTypes.UPDATE_FORM, props<{ form: WorkstationFormDef }>());
export const SetOverlayHelp = createAction(WorkstationActionTypes.SET_OVERLAY_HELP, props<{ overlayHelp: boolean }>());

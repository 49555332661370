import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorCode } from '@g3p/services/http-error-interceptor.service';

@Component({
  selector: 'g3p-error-dialog',
  templateUrl: 'error-dialog.component.html',
  styleUrls: ['error-dialog.component.scss'],
})

export class ErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {errorCode: ErrorCode}
  ) {}

  close() {
    this.dialogRef.close();
  }

  onOk() {
    this.dialogRef.close();
    if(this.data.errorCode === ErrorCode.OUTDATED_APP) {
      window.location.reload();
    }
  }

  public get errorCode(): typeof ErrorCode {
    return ErrorCode;
  }
}

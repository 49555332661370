import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { debounceTime, take, takeWhile, withLatestFrom } from 'rxjs/operators';

import { G3FormValidation } from '@cgm/g3-component-lib';
import { CustomerCreateFacade } from '../../store/customer-create.facade';
import { CustomValidatorRegex } from '@g3p/shared/validators/validators-regex';
import { addControls } from '@g3p/shared/helpers/helpers';
import { BusinessUnitDef, CustomerDataFormDef } from '@g3p/customer/interfaces/customer-def.interface';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'g3p-customer-data-step',
  templateUrl: './customer-data-step.component.html',
  styleUrls: ['./customer-data-step.component.scss']
})
export class CustomerDataStepComponent implements OnInit, OnDestroy {
  @Input() customerDataFormGroup: FormGroup;
  @Input() formValidation: G3FormValidation;
  @Output() validChange = new EventEmitter<boolean>();

  businessUnits: BusinessUnitDef[];
  dataAutomation = 'CUSTOMER-CREATE-CustomerData';
  private alive = true;

  constructor(
    private formBuilder: FormBuilder,
    private facade: CustomerCreateFacade
  ) { }

  ngOnInit(): void {
    this.getBusinessUnits();
    this.createForm();
    this.updateFormState();
    this.updateFormData();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  updateFormState(): void {
    this.customerDataFormGroup.valueChanges
      .pipe(
        debounceTime(25),
        takeWhile(() => this.alive),
        withLatestFrom(this.facade.getCreateDialogOpened$())
      )
      .subscribe(([_, isOpened]: [FormGroup, boolean]) => {
        if (isOpened) {
          this.validChange.emit(this.customerDataFormGroup.valid);
          this.facade.updateCustomerDataForm(this.buildStepFormDef());
        }
      });
  }

  updateFormData(): void {
    this.facade
      .selectCustomerDataStep$()
      .pipe(take(1))
      .subscribe((form: CustomerDataFormDef) => this.patchFormValue(form));
  }

  buildStepFormDef(): CustomerDataFormDef {
    return this.customerDataFormGroup.value as CustomerDataFormDef;
  }

  patchFormValue(value: CustomerDataFormDef): void {
    this.customerDataFormGroup.patchValue(value || {});
    if( value && value.businessUnitName) {
      this.customerDataFormGroup.get('businessUnitId').setValue(this.businessUnits.find(bu=> bu.name === value.businessUnitName)?.id);
    }
  }

  createForm(): void {
    const formControls = {
      institutionName: new FormControl('', Validators.required),
      organizationName: new FormControl('', Validators.required),
      postalCode: new FormControl('', [Validators.required, Validators.minLength(5)]),
      city: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      vatId: new FormControl(''),
      customerNumber: new FormControl(''),
      email: new FormControl('', Validators.pattern(CustomValidatorRegex.EMAIL_REGEXP)),
      businessUnitId: new FormControl('', Validators.required),
      businessUnitName: new FormControl('', Validators.required),
      locationNumber: new FormControl('', Validators.required),
      fiscalCode: new FormControl(''),
      id: new FormControl('')
    };
    addControls(this.customerDataFormGroup, formControls);
  }

  getBusinessUnits(): void {
    this.facade
      .getBusinessUnits$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((businessUnits) => {
        this.businessUnits = businessUnits;
        if( this.customerDataFormGroup.get('businessUnitName')?.value ) {
          this.customerDataFormGroup.get('businessUnitId').setValue(this.businessUnits.find(bu=> bu.name === this.customerDataFormGroup.get('businessUnitName').value)?.id);
        }
      });
  }


  handleSelectionChange($event: MatSelectChange): void {
    this.customerDataFormGroup.get('businessUnitId').setValue(this.businessUnits.find(bu=> bu.name === $event.value).id);
  }

  updateOrganizationName(): void {
    this.customerDataFormGroup.get('organizationName').setValue(this.customerDataFormGroup.get('institutionName').value);
  }
}

<section class="section-container">
    <p class="section-title" data-automation="Account - section title">
        {{ 'content.accounts' | translate | uppercase }}
    </p>
    <button
        class="add-address"
        (click)="addAccountFormClick()"
        mat-icon-button
        color="primary"
        data-automation="Account - add new button"
    >
        <mat-icon class="mdi mdi-24px mdi-plus-circle-outline"></mat-icon>
    </button>
    <mat-divider></mat-divider>
</section>

<cdk-accordion class="account-accordion">
    <cdk-accordion-item
        *ngFor="let item of forms; let index = index;"
        #accordionItem="cdkAccordionItem"
        class="account-accordion-item"
        role="button"
        tabindex="0"
        (opened)="expandItem(true, index)"
        (closed)="expandItem(false, index)"
        [expanded]="formAt(index).get('expanded').value"
        [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + index">
        <form [formGroup]="formAt(index)" [ngClass]="{'error': formErrors[index]}">
            <div class="account-accordion-item-header">
                <button type="button" class="mat-icon-button" [ngClass]="{'disabled': forms.length === 1}" mat-button (click)="remove(index)">
                    <mat-icon class="trash mdi mdi-24px mdi-delete"></mat-icon>
                </button>
                <mat-form-field appearance="outline" class="input-full-width account-header input-field-large" [ngClass]="{'deactivated': isDeactivated(index)}">
                    <input
                        matInput
                        type="text"
                        data-automation="Account - username"
                        formControlName="username"
                        [g3FormValidation]="formValidation[index]"
                        required
                        [readonly]="isDeactivated(index)"
                        (focus)="onFocusField('username')"
                        autocomplete="new-password"
                    />
                    <mat-label
                        data-automation="Account - description label">{{'content.user-name' | translate}}
                    </mat-label>
                    <mat-icon matSuffix class="mdi mdi-24px mdi-alert-circle"
                              *ngIf="formValidation[index].isErrorState(formAt(index).controls.username)">
                    </mat-icon>
                    <mat-error *ngIf="formValidation[index].isErrorType(formAt(index).controls.username, 'required')">
                        <span>{{ "content.field-is-mandatory" | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="formAt(index).hasError('inUse', 'username')">
                        <span>{{ "content.user-already-exists-for-customer" | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="formAt(index).hasError('usernameExists', 'username')">
                        <span>{{ "content.user-name-already-in-use" | translate }}</span>
                    </mat-error>
                </mat-form-field>
                <button type="button" class="mat-icon-button" mat-button (click)="accordionItem.toggle()">
                    <span class="mdi mdi-24px mdi-chevron-down" *ngIf="accordionItem.expanded"></span>
                    <span class="mdi mdi-24px mdi-chevron-right" *ngIf="!accordionItem.expanded"></span>
                </button>
            </div>
            <div
                class="account-accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index">

                <div class="item-group address-tabs-wrapper account-container"
                     data-automation="Account - Form"
                >
                    <!-- 1st row -->
                    <div class="field-container">
                        <div class="input-half-width">
                            <mat-form-field appearance="outline" class="input-field-large input-half-width" [ngClass]="{'deactivated': isDeactivated(index)}">
                                <input
                                    matInput
                                    type="text"
                                    autocomplete="off"
                                    formControlName="firstName"
                                    data-automation="Account - firstName"
                                    [g3FormValidation]="formValidation[index]"
                                    required
                                    [readonly]="isDeactivated(index)"
                                />
                                <mat-label data-automation="Account - firstName label">
                                    {{ "content.first-name" | translate }}
                                </mat-label>
                                <mat-icon matSuffix class="mdi mdi-24px mdi-alert-circle"
                                          *ngIf="formValidation[index].isErrorState(formAt(index).controls.firstName)">
                                </mat-icon>
                                <mat-error *ngIf="formValidation[index].isErrorState(formAt(index).controls.firstName)">
                                    <span>{{ "content.field-is-mandatory" | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- right side -->
                        <mat-form-field appearance="outline" class="input-field-large input-half-width" [ngClass]="{'deactivated': isDeactivated(index)}">
                            <input
                                matInput
                                type="text"
                                autocomplete="off"
                                formControlName="lastName"
                                data-automation="Account - lastName"
                                [g3FormValidation]="formValidation[index]"
                                required
                                [readonly]="isDeactivated(index)"
                            />
                            <mat-label data-automation="Account - lastName label">
                                {{ "content.last-name" | translate }}
                            </mat-label>
                            <mat-icon matSuffix class="mdi mdi-24px mdi-alert-circle"
                                      *ngIf="formValidation[index].isErrorState(formAt(index).controls.lastName)">
                            </mat-icon>
                            <mat-error *ngIf="formValidation[index].isErrorState(formAt(index).controls.lastName)">
                                <span>{{ "content.field-is-mandatory" | translate }}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- 2nd row -->
                    <div class="field-container">
                        <mat-form-field appearance="outline" class="input-full-width input-field-large">
                            <input
                                matInput
                                type="text"
                                autocomplete="off"
                                formControlName="email"
                                data-automation="Account - email"
                                [g3FormValidation]="formValidation[index]"
                                required
                            />
                            <mat-label data-automation="Account - email label">
                                {{ "content.email" | translate }}
                            </mat-label>
                            <mat-icon matSuffix class="mdi mdi-24px mdi-alert-circle"
                                      *ngIf="formValidation[index].isErrorState(formAt(index).controls.email)">
                            </mat-icon>
                            <mat-error *ngIf="formValidation[index].isErrorType(formAt(index).controls.email, 'required')">
                                <span>{{ "content.field-is-mandatory" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="formValidation[index].isErrorType(formAt(index).controls.email, 'pattern')">
                                <span>{{ "content.enter-valid-email-address" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="formAt(index).hasError('inUse', 'email')">
                                <span>{{ "content.user-already-exists-for-customer" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="formAt(index).hasError('emailExists', 'email')">
                                <span>{{ "content.email-already-in-use" | translate }}</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- 3rd row -->
                    <div class="field-container">
                        <mat-form-field appearance="outline" class="input-full-width input-field-large">
                            <input
                                matInput
                                formControlName="endDate"
                                [matDatepicker]="picker"
                                data-automation="Account - active until"
                            />
                            <mat-label>{{ "content.active-until" | translate }}</mat-label>
                            <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="field-container">
                        <div class="reset-button" *ngIf="isUpdateMode(index)">
                            <button class="large"
                                    mat-button
                                    color="primary"
                                    (click)="resetPassword(index)">
                                {{ "content.reset-password" | translate }}
                            </button>
                        </div>
                        <mat-form-field *ngIf="showPassword[index] || !isUpdateMode(index)" appearance="outline" class="input-field-large input-full-width" g3pPasswordReveal>
                            <input
                                matInput
                                type="password"
                                autocomplete="new-password"
                                formControlName="initialPassword"
                                data-automation="Account - initialPassword"
                                [g3FormValidation]="formValidation[index]"
                                required
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$"
                            />
                            <mat-label data-automation="Account - initialPassword label">
                                {{ "content.password" | translate }}
                            </mat-label>
                            <mat-icon matSuffix class="mdi mdi-24px mdi-alert-circle"
                                      *ngIf="formValidation[index].isErrorState(formAt(index).controls.initialPassword)">
                            </mat-icon>
                            <mat-error *ngIf="formValidation[index].isErrorType(formAt(index).controls.initialPassword, 'required')">
                                <span>{{ "content.field-is-mandatory" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="formValidation[index].isErrorType(formAt(index).controls.initialPassword, 'pattern')">
                                <span>{{ "content.invalid-password" | translate }}</span>
                            </mat-error>
                            <mat-icon matSuffix class="mdi mdi-24px mdi-eye"></mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <!-- hidden fields-->
                <input type="hidden" formControlName="id" data-automation="Account - id"/>
                <input type="hidden" formControlName="expanded" data-automation="Account - expanded"/>
            </div>
        </form>
    </cdk-accordion-item>
</cdk-accordion>

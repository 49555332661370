import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StoreConstants } from '@g3p/shared/constants/store.constants';
import { WorkstationFormDef } from '@g3p/workstation/interfaces/workstation-def.interface';
import { WorkstationState } from '@g3p/workstation/store/workstation.state';

export const workstationStore = createFeatureSelector<WorkstationState>(StoreConstants.workstation);

export const busyState = createSelector(workstationStore, (state: WorkstationState) => state.busy);
export const registeredState = createSelector(workstationStore, (state: WorkstationState) => !!state.customerName);
export const formState = createSelector(workstationStore, (state: WorkstationState) => state.form);
export const adminModeState = createSelector(formState, (state: WorkstationFormDef) => state?.isAdminMode);
export const customersState = createSelector(workstationStore, (state: WorkstationState) => state.customers);
export const customerState = createSelector(workstationStore, (state: WorkstationState) => state.customerName);
export const workstationState = createSelector(workstationStore, (state: WorkstationState) => state.workstationName);
export const overlayHelpState = createSelector(workstationStore, (state: WorkstationState) => state.overlayHelp);
export const workstationTssStatusState = createSelector(
  workstationStore,
  (state: WorkstationState) => state.workstationTssStatus
);
export const workstationIdState = createSelector(workstationStore, (state: WorkstationState) => state.workstationId);

import { KeycloakService } from 'keycloak-angular';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: getKeycloakUrl(),
        realm: 'master',
        clientId: 'cashbook-standalone'
      },
      initOptions: {
        checkLoginIframe: false,
        onLoad: 'login-required'
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: []
    });
}

function getKeycloakUrl(): string {
  if (window.location.hostname === 'localhost') {
    return 'https://auth-cashbook.stella.cloud.c3.cgm.ag/auth';
  } else {
    const pathArray = window.location.origin.split('/');
    const protocol = pathArray[0];
    const host = pathArray[2];
    return protocol + '//auth-' + host + '/auth';
  }
}

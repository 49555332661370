import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'g3p-base-background',
  templateUrl: './base-background.component.html',
  styleUrls: ['./base-background.component.scss']
})
export class BaseBackgroundComponent implements OnInit {
  private alive = true;

  constructor() {}

  ngOnInit(): void {}
}

import { Injectable } from '@angular/core';

import { from, Observable, of } from 'rxjs';

import * as DE from '../../assets/i18n/de-DE.json';
import * as EN from '../../assets/i18n/en-GB.json';
import * as ES from '../../assets/i18n/es-ES.json';
import * as IT from '../../assets/i18n/it-IT.json';
import { LocalizationService } from '@g3p/services/localization.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateLoaderService {
  private lang: string;
  private paths = {
    'de-DE': of(DE),
    'en-GB': of(EN),
    'es-ES': of(ES),
    'it-IT': of(IT)
  };

  constructor(private localizationService: LocalizationService) {
    this.localizationService.locale$.subscribe((lang: string) => {
      this.lang = lang;
    });
  }

  getTranslation(): Observable<unknown> {
    return from(this.paths[this.lang]);
  }
}

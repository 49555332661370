import { ElementRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { Observable, OperatorFunction } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

export function addControls(form: FormGroup, formControls: { [key: string]: AbstractControl }): void {
  Object.keys(formControls).forEach(key => form.addControl(key, formControls[key]));
}

export function checkUsageValue(inUse: boolean): ValidatorFn {
  return (_: AbstractControl): ValidationErrors | null => {
    return inUse ? { inUse: { valid: false } } : null;
  };
}

export function deepEquals(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function hasAnyPropNotNull<T>(obj: T): boolean {
  return obj && Object.keys(obj).some(prop => !!obj[prop]);
}

export function mapNotNull<T, R>(project: (value: T, index: number) => R): OperatorFunction<T, R> {
  return function mapOperation$(source$: Observable<T>): Observable<R> {
    return source$.pipe(
      map((value, index) => project(value, index)),
      skipWhile(value => value === null)
    );
  };
}

export function formValue<T>(form: FormGroup): T {
  const value: T = <T>{};
  const config = { onlySelf: true, emitEvent: false };

  Object.keys(form.controls).forEach(field => {
    if (form.controls[field].disabled) {
      form.controls[field].enable(config);
      value[field] = form.controls[field].value;
      form.controls[field].disable(config);
    } else {
      value[field] = form.controls[field].value;
    }
  });
  return value;
}

export function isRequiredByFields(fn: () => string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control?.parent?.controls || {};
    const name = Object.keys(formGroup).find(field => formGroup[field] === control);
    return fn().indexOf(name) >= 0 ? Validators.required(control) : null;
  };
}

export function isValidHttpUrl(control: FormControl): Validators {
  if (!control.value) {
    return null;
  }

  let url;
  try {
    url = new URL(control.value);
  } catch (e) {
    return { invalidUrl: { valid: false } };
  }

  return url.protocol === 'http:' || url.protocol === 'https:' ? null : { invalidUrl: { valid: false } };
}

export function scrollIntoView(elementRef: ElementRef, target: string): void {
  setTimeout(() => {
    const lastCreatedRef = elementRef.nativeElement.querySelector(target);
    lastCreatedRef && lastCreatedRef.scrollIntoView({ behavior: 'smooth' });
  });
}

export function requireAtLeastOneOf(fields: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }
    const isValid = fields.map(f => control.parent.get(f)).some(c => !!c.value);
    return isValid ? null : { requiredOne: { value: control.value } };
  };
}

export function toRequestParam(params: Object): String {
  return (
    '?' +
    Object.keys(params)
      .map(param => param + '=' + params[param])
      .join('&')
  );
}

export function numberOnly(event): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export function numberOnlyWithDecimal(event): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 44 && charCode !== 46) {
    return false;
  }
  return true;
}

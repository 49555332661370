import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {TranslateService} from "@ngx-translate/core";
import {MatDialogRef} from "@angular/material/dialog";
import {
  CustomerCreateFacade
} from "@g3p/customer/components/customer-create-modal/store/customer-create.facade";
import {takeWhile} from "rxjs/operators";
import {
  CustomerDetailsFacade
} from "@g3p/customer/components/customer-details/store/customer-details.facade";

@Component({
  selector: 'g3p-customer-deletion-modal',
  templateUrl: './customer-deletion-modal.component.html',
  styleUrls: ['./customer-deletion-modal.component.scss']
})
export class CustomerDeletionModalComponent implements OnInit, OnDestroy {

  private alive = true;
  @ViewChild('confirmButton') confirmButton: MatButton;
  @ViewChild('cancelButton') cancelButton: MatButton;
  showSecondConfirmation: boolean;
  showThirdConfirmation: boolean;
  isTse: boolean;
  hasOpenTransactions: boolean;
  customerName: string;

  constructor(
    private translateService: TranslateService,
    private customerCreateFacade: CustomerCreateFacade,
    private customerDetailsFacade: CustomerDetailsFacade,
    private dialogRef: MatDialogRef<CustomerDeletionModalComponent>
  ) { }

  ngOnInit(): void {
    this.notifyDialogOpen();
    this.getCustomerHasTse();
    this.getCustomerHasOpenTransactions();
    this.notifyCustomerName();
  }

  notifyDialogOpen(): void {
    this.customerCreateFacade
    .isCustomerDeletionModalOpened$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((isOpened: boolean) => {
      !isOpened && this.dialogRef.close();
    });
  }

  notifyCustomerName(): void {
    this.customerDetailsFacade.getCustomerName$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((customerName: string) => {
      this.customerName = customerName;
    });
  }

  getCustomerHasTse(): void {
    this.customerDetailsFacade
    .getCustomerHasTse$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((hasTse: boolean) => (this.isTse = hasTse));
  }

  getCustomerHasOpenTransactions(): void {
    this.customerDetailsFacade
    .getCustomerHasOpenTransactions$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((hasOpenTransactions: boolean) => (this.hasOpenTransactions = hasOpenTransactions));
  }

  dialogHeaderText() {
    return this.customerName ? this.translateService.instant("content.customer-deletion-modal-dialog-header-text", {customerName: this.customerName})
      : this.translateService.instant("content.customer-deletion-modal-dialog-header-text-fallback");
  }

  firstConfirmationMessage() {
    if (this.hasOpenTransactions) {
      return "content.customer-deletion-modal-dialog-text-open-transactions";
    }
    if (!this.isTse) {
      return "content.customer-deletion-modal-dialog-text-no-tse";
    } else {
      return "content.customer-deletion-modal-dialog-text-tse";
    }
  }

  secondConfirmationMessage() {
    if (this.hasOpenTransactions) {
      if (!this.isTse) {
        return "content.customer-deletion-modal-dialog-text-no-tse";
      } else {
        return "content.customer-deletion-modal-dialog-text-tse";
      }
    }
    return "content.customer-deletion-modal-dialog-text-tse-confirm";
  }

  thirdConfirmationMessage() {
    return "content.customer-deletion-modal-dialog-text-tse-confirm";
  }

  cancel() {
    this.customerCreateFacade.closeCustomerDeletionModal();
  }

  confirm() {
    if (!this.showSecondConfirmation && !this.showThirdConfirmation) {
      this.showSecondConfirmation = true;
      return;
    }
    if (this.showSecondConfirmation && !this.showThirdConfirmation && this.hasOpenTransactions) {
      this.showSecondConfirmation = false;
      this.showThirdConfirmation = true;
      return;
    }
    this.customerCreateFacade.deleteCustomer();
    this.customerCreateFacade.closeCustomerDeletionModal();
    this.showSecondConfirmation = false;
    this.showThirdConfirmation = false;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}

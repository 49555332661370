import cloneDeep from 'lodash/cloneDeep';

import { newState } from '@g3p/shared/helpers/app-helpers';
import { CustomerDetailsActions, CustomerDetailsActionTypes } from './customer-details.actions';
import { CustomerDetailsState, initialCustomerDetailsState } from './customer-details.state';

export function customerDetailsReducers(
  state: CustomerDetailsState = cloneDeep(initialCustomerDetailsState),
  action: CustomerDetailsActions
) {
  switch (action.type) {
    case CustomerDetailsActionTypes.GET_CUSTOMER_REQUEST:
      return state;

    case CustomerDetailsActionTypes.GET_CUSTOMER_SUCCESS:
      return newState(state, {
        currentCustomer: action.payload.currentCustomer,
        isWorkstationToggleModalOpen: false,
        busy: false
      });

    case CustomerDetailsActionTypes.GET_CUSTOMER_ERROR:
      return state;

    case CustomerDetailsActionTypes.RESET:
      return newState(state, {
        ...initialCustomerDetailsState
      });

    case CustomerDetailsActionTypes.TOGGLE_WORKSTATION_ACTIVE_STATUS_REQUEST:
      return newState(state, {
        ...state,
        busy: true
      });

    case CustomerDetailsActionTypes.TOGGLE_WORKSTATION_ACTIVE_STATUS_SUCCESS:
            const updatedCustomer = {
              ...state.currentCustomer,
              workstations: state.currentCustomer.workstations.map(workstation => {
                if (workstation.id === action.payload.workstation.id) {
                  return {
                    ...action.payload.workstation,
                    deletable: workstation.deletable
                  };
                } else {
                  return workstation;
                }
              }),
            };
            return newState(state, {
              currentCustomer: updatedCustomer,
              isWorkstationToggleModalOpen: false,
              busy: false
            });

    case CustomerDetailsActionTypes.OPEN_WORKSTATION_TOGGLE_MODAL:
      return newState(state, {
        ...state,
        isWorkstationToggleModalOpen: true
      })

    case CustomerDetailsActionTypes.CLOSE_WORKSTATION_TOGGLE_MODAL:
      return newState(state, {
        ...state,
        isWorkstationToggleModalOpen: false
      })

    default:
      return state;
  }
}

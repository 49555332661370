import { Action } from '@ngrx/store';

import { CustomerUserDef } from '@g3p/customer/interfaces/customer-details-def.interface';

export enum CustomerUserStatusActionTypes {
  UPDATE_CUSTOMER_USER_STATUS_REQUEST = '[Cashbook - Admin page] Activate/Deactivate user - Request',
  UPDATE_CUSTOMER_USER_STATUS_SUCCESS = '[Cashbook - Admin page] Activate/Deactivate user - Success',
  UPDATE_CUSTOMER_USER_STATUS_FAILURE = '[Cashbook - Admin page] Activate/Deactivate user - Failure',

  OPEN_UPDATE_CUSTOMER_USER_STATUS_DIALOG = '[Cashbook - Admin page] Open update customer user status dialog',
  CLOSE_UPDATE_CUSTOMER_USER_STATUS_DIALOG = '[Cashbook - Admin page] Close update customer user status dialog'
}

export class UpdateCustomerUserStatusRequest implements Action {
  readonly type = CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_REQUEST;
  constructor(public customerId: string, public userId: string, public username: string, public active: boolean) {}
}

export class UpdateCustomerUserStatusSuccess implements Action {
  readonly type = CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_SUCCESS;
  constructor(public payload: any, public username: string) {}
}

export class UpdateCustomerUserStatusFailure implements Action {
  readonly type = CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_FAILURE;
  constructor(public username: string, public active: boolean) {}
}

export class OpenUpdateCustomerUserStatusDialog implements Action {
  readonly type = CustomerUserStatusActionTypes.OPEN_UPDATE_CUSTOMER_USER_STATUS_DIALOG;
  constructor(public currentCustomer: any, public customerUser: CustomerUserDef, public customerId: string) {}
}

export class CloseUpdateCustomerUserStatusDialog implements Action {
  readonly type = CustomerUserStatusActionTypes.CLOSE_UPDATE_CUSTOMER_USER_STATUS_DIALOG;
}

export type CustomerUserStatusActions =
  | UpdateCustomerUserStatusRequest
  | UpdateCustomerUserStatusSuccess
  | UpdateCustomerUserStatusFailure
  | OpenUpdateCustomerUserStatusDialog
  | CloseUpdateCustomerUserStatusDialog;

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CustomerState } from '@g3p/customer/store/customer.state';
import { StoreConstants } from '@g3p/shared/constants/store.constants';
import { CustomerCreateState } from '@g3p/customer/components/customer-create-modal/store/customer-create.state';

export const customerStore = createFeatureSelector<CustomerState>(StoreConstants.customer);
export const customerCreateState = createSelector(customerStore,(state: CustomerState) => state.customerCreateModal);

export const isOpenedState = createSelector(customerCreateState,( state: CustomerCreateState) => state.isOpened);
export const isBusyState = createSelector(customerCreateState,(state: CustomerCreateState) => state.busy);
export const isEditModeState = createSelector(customerCreateState,(state: CustomerCreateState) => state.isEditMode);
export const businessUnitsState = createSelector(customerCreateState, (state: CustomerCreateState) => state.businessUnits);

export const customerCreateFormState = createSelector(customerCreateState, (state: CustomerCreateState) => state.createForm);
export const customerDataFormState = createSelector(customerCreateState, (state: CustomerCreateState) => state.createForm.customerDataForm);
export const accountFormState = createSelector(customerCreateState, (state: CustomerCreateState) => state.createForm.accountForm);
export const workstationFormState = createSelector(customerCreateState, (state: CustomerCreateState) => state.createForm.workstationForm);
export const customerDeletionModalState = createSelector(customerCreateState, (state: CustomerCreateState) => state.isCustomerDeletionModalOpened);
export const customerDeletionStateModalState = createSelector(customerCreateState, (state: CustomerCreateState) => state.isCustomerDeletionStateModalOpened);
export const customerDeletionStateList = createSelector(customerCreateState, (state: CustomerCreateState) => state.customerDeletionStateList);

export class CustomValidatorRegex {
  static readonly URL_REGEXP =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  static readonly EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
  static readonly NO_SYMBOLS_REGEXP = /^[a-zA-Z0-9äöüÄÖÜàèéìòóùÀÈÉÌÒÓÙ_]*$/;
  static readonly NO_SPECIAL_CHARS_REGEXP = /^[a-zA-Z0-9äöüÄÖÜàèéìòóùÀÈÉÌÒÓÙ., \-]+$/;
  static readonly CITY_COUNTRY_REGION_REGEXP = /^[a-zA-ZäöüÄÖÜàèéìòóùÀÈÉÌÒÓÙ \-]+$/;
  static readonly POST_REGEXP = /^[0-9 ]+$/;
  static readonly VAT_REGEXP = /^(\d){11}$/;
}

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { G3SnackbarService, G3SnackType } from '@cgm/g3-component-lib';

import * as fromActions from './customer-details.actions';
import { CustomerDetailsService } from '../services/customer-details.service';
import { CustomerDetailsDef } from '@g3p/customer/interfaces/customer-details-def.interface';
import {WorkstationResponse} from "@g3p/customer/interfaces/customer-details-dto.interface";

@Injectable({ providedIn: 'root' })
export class CustomerDetailsEffects {
  fetchCurrentCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CustomerDetailsActionTypes.GET_CUSTOMER_REQUEST),
      switchMap((action: fromActions.GetCurrentCustomerRequest) =>
        this.service.getCustomerById$(action.payload.id).pipe(
          map((currentCustomer: CustomerDetailsDef) =>
            new fromActions.GetCurrentCustomerSuccess({currentCustomer})
          ),
          catchError(() => of(new fromActions.GetCurrentCustomerError()))
        )
      )
    )
  );

  fetchCurrentCustomerError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CustomerDetailsActionTypes.GET_CUSTOMER_ERROR),
      tap(() => this.snackbarService.open(
        this.translateService.instant('content.something-went-wrong'),
        5000,
        G3SnackType.Error
      ))
    ), { dispatch: false }
  );

  toggleWorkstationActiveStateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CustomerDetailsActionTypes.TOGGLE_WORKSTATION_ACTIVE_STATUS_REQUEST),
      switchMap((action: fromActions.ToggleWorkstationActiveStatusRequest) =>
        this.service.toggleWorkstationActiveState(action.payload.customerId, action.payload.workstation).pipe(
          map((workstation: WorkstationResponse) =>
            new fromActions.ToggleWorkstationActiveStatusSuccess({workstation})
          ),
          catchError(() => of(new fromActions.ToggleWorkstationActiveStatusError({workstation: action.payload.workstation})))
        )
      )
    )
  );

  toggleWorkstationActiveStateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CustomerDetailsActionTypes.TOGGLE_WORKSTATION_ACTIVE_STATUS_SUCCESS),
      tap((data: any) => {
        const snackbarMessage = data.payload.workstation.active ?
          'content.workstation-toggle-active-success' : 'content.workstation-toggle-inactive-success';
        this.snackbarService.open(
          this.translateService.instant(snackbarMessage, {value: data.payload.workstation.code}),
          7000,
          G3SnackType.Success
        );
      })
    ), { dispatch: false }
  );

  toggleWorkstationActiveStateError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CustomerDetailsActionTypes.TOGGLE_WORKSTATION_ACTIVE_STATUS_ERROR),
      tap((data: any) => {
        const snackbarMessage = data.payload.workstation.isActive ?
          'content.workstation-toggle-active-error' : 'content.workstation-toggle-inactive-error';
        this.snackbarService.open(
          this.translateService.instant(snackbarMessage, {value: data.payload.workstation.code}),
          7000,
          G3SnackType.Error
        );
      })
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: CustomerDetailsService,
    private snackbarService: G3SnackbarService,
    private translateService: TranslateService
  ) {
  }
}

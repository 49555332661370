import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { CustomerListDef, CustomerListFilterDef } from '@g3p/customer/interfaces/customer-list-def.interface';

import { CustomerDetailsFacade } from '../../customer-details/store/customer-details.facade';
import * as fromActions from './customer-list.actions';
import * as fromSelectors from './customer-list.selectors';
import { CustomerListState } from './customer-list.state';

@Injectable({ providedIn: 'root' })
export class CustomerListFacade {
  constructor(private store$: Store<CustomerListState>, private facade: CustomerDetailsFacade) {}

  fetchCustomers(): void {
    this.store$.dispatch(new fromActions.GetCustomerListRequest());
  }

  getCustomerList$(): Observable<CustomerListDef[]> {
    return this.store$.select(fromSelectors.customerListState);
  }

  increasePageNumber(): void {
    this.store$.dispatch(new fromActions.IncreasePageNumber());
  }

  setCurrentCustomer(id: string): void {
    this.facade.fetchCurrentCustomer(id);
  }

  resetCurrentCustomer(): void {
    this.facade.resetCurrentCustomer();
  }

  getCustomerId$(): Observable<string> {
    return this.facade.getCustomerId$();
  }

  getFilter$(): Observable<CustomerListFilterDef> {
    return this.store$.select(fromSelectors.customerListFilterState);
  }

  setFilter(filters: CustomerListFilterDef): void {
    this.store$.dispatch(new fromActions.SetCustomerListFilter({ filters }));
  }

  setSearchQuery(searchString: string): void {
    this.store$.dispatch(new fromActions.SetCustomerListSearchQuery({ searchString }));
  }
}

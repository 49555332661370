<div class="tse-container">
    <div class="tse-element-container" data-automation="Workstation Tse Status">
        <div class="title" data-automation="Tse Info label">
            {{ "content.tse-info" | translate }}
        </div>
    </div>
    <mat-divider class="horizontal-divider"></mat-divider>
    <div class="tse-element-container" data-automation="Workstation Tse Status - Tse Info">
        <div class="field-name" data-automation="Status label">
            {{ "content.status" | translate }}
        </div>
        <div class="field-value" data-automation="Status value">
            <div
                class="status-icon"
                [ngClass]="{
                    'status-active': workstationTssData?.status === 'ACTIVE',
                    'status-not-active': workstationTssData?.status !== 'ACTIVE'
                }"
            ></div>
            {{ getStatusText(workstationTssData?.status) || "-" }}
        </div>
    </div>
</div>
<div class="tse-container" *ngIf="workstationTssData?.error">
    <mat-divider class="horizontal-divider"></mat-divider>
    <div class="info-text" data-automation="Tse Info Text1">
        {{ "content.tss-info_text_part1" | translate }}
    </div>
    <div class="info-text info-text-buttom" data-automation="Tse Info Text2">
        {{ "content.tss-info_text_part2" | translate }}
    </div>
</div>
<div class="tse-container" *ngIf="!workstationTssData?.error">
    <div class="tse-element-container" data-automation="Workstation Tse Status - Tse dateOfActivation">
        <div class="field-name" data-automation="Date Of Activation label">
            {{ "content.kassen-sich-v" | translate }}
        </div>
        <div class="field-value" data-automation="Date Of Activation - Status value">
            <span *ngIf="workstationTssData?.status === 'ACTIVE'">{{ "content.active-since" | translate }}</span>
            {{ getFormattedDate(workstationTssData?.dateOfActivation) || "-" }}
        </div>
    </div>
    <div class="tse-element-container" data-automation="Workstation Tse Status - Tse firstTransactionDate">
        <div class="field-name" data-automation="First Transaction Date label">
            {{ "content.first-transaction-date" | translate }}
        </div>
        <div class="field-value" data-automation="First Transaction Date value">
            {{ getFormattedDate(workstationTssData?.firstTransactionDate) || "-" }}
        </div>
    </div>
    <div class="tse-element-container" data-automation="Workstation Tse Status - Tse organizationId">
        <div class="field-name" data-automation="Organization Id label">
            {{ "content.organization-id" | translate }}
        </div>
        <div class="field-value" data-automation="organizationId Id value">
            {{ workstationTssData?.organizationId || "-" }}
        </div>
    </div>
    <div class="tse-element-container" data-automation="Workstation Tse Status - Tse tssId">
        <div class="field-name" data-automation="tss Id label">
            {{ "content.tss-id" | translate }}
        </div>
        <div class="field-value" data-automation="tss Id value">
            {{ workstationTssData?.tssId || "-" }}
        </div>
    </div>
    <div class="tse-element-container" data-automation="Workstation Tse Status - Tse clientId">
        <div class="field-name" data-automation="Client Id label">
            {{ "content.client-id" | translate }}
        </div>
        <div class="field-value" data-automation="Client Id value">
            {{ workstationTssData?.clientId || "-" }}
        </div>
    </div>
    <div class="tse-element-container" data-automation="Workstation Tse Status - Tse openTransactions">
        <div class="field-name" data-automation="Open Transaction label">
            {{ "content.closed-transactions" | translate }}
        </div>
        <div class="field-value" data-automation="Open Transaction value">
            {{ workstationTssData?.openTransactions || "-" }}
        </div>
    </div>
    <div class="tse-element-container" data-automation="Workstation Tse Status - Tse maxTransactions">
        <div class="field-name" data-automation="Max Transactions label">
            {{ "content.max-count-of-transactions" | translate }}
        </div>
        <div class="field-value" data-automation="Max Transactions">
            {{ workstationTssData?.maxTransactions || "-" }}
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpBackend } from '@angular/common/http';
import { INGXLoggerMetadata, NGXLoggerServerService } from 'ngx-logger';
import { LocalStorageConstants } from '@g3p/shared/constants/local-storage.constants';

@Injectable()
export class LogService extends NGXLoggerServerService {

  constructor(
    httpBackend: HttpBackend
  ) {
    super(httpBackend);
  }

  public customiseRequestBody(metadata: INGXLoggerMetadata): any {
    const localAppState = localStorage.getItem(LocalStorageConstants.AppState);
    let appState: any;
    if(localAppState) {
      appState = JSON.parse(localAppState)
    }

    const payload: string = metadata.additional[0];
    const response: string = metadata.additional[1];

    // Log the stack for type errors and the message for http error
    const message: string = metadata.message?.stack || metadata.message;

    return {
      ...appState,
      message,
      payload,
      response,
      timestamp: metadata.timestamp,
    };
  }
}

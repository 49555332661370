import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { ApiEndpointsUrl } from '@g3p/shared/constants/api-url.constants';
import { CustomerDetailsResponseDto, WorkstationDtoResponse,   WorkstationResponse } from '@g3p/customer/interfaces/customer-details-dto.interface';
import { CustomerDetailsDef } from '@g3p/customer/interfaces/customer-details-def.interface';
import { mapCustomerDetailsResponseDto } from '@g3p/customer/helpers/mappers';
import { mapWorkstationDtoResponse } from "@g3p/workstation/helpers/mappers";

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsService {
  constructor(private http: HttpClient) {}

  getCustomerById$(customerId: string): Observable<CustomerDetailsDef> {
    return this.http
      .get(environment.apiUrl + ApiEndpointsUrl.CUSTOMERS.CUSTOMER_BY_ID.replace('{customerId}', customerId))
      .pipe(map((response: CustomerDetailsResponseDto) => mapCustomerDetailsResponseDto(response)));
  }

  toggleWorkstationActiveState(customerId: string, workstation: WorkstationResponse): Observable<WorkstationResponse> {
    return this.http
    .patch(environment.apiUrl + ApiEndpointsUrl.CUSTOMERS.WORKSTATION_TOGGLE_ACTIVE_STATUS.replace('{customerId}', customerId).replace('{workstationId}', workstation.id), workstation)
    .pipe(map((response: WorkstationDtoResponse) => {
      return mapWorkstationDtoResponse(response);
    }))
  }
}

export const ApiEndpointsUrl = {
  CATALOG: {
    GET_BUSINESS_UNITS: '/cashbook/catalog/business-units'
  },
  CUSTOMERS: {
    ADD_NEW: '/cashbook/customers',
    CUSTOMER_BY_ID: '/cashbook/customers/{customerId}',
    GET: '/cashbook/customers',
    PATCH: '/cashbook/customers/',
    WORKSTATION_TSE_STATUS: '/cashbook/fiscalization/tssdata/{workstationId}',
    UPDATE_CUSTOMER_USER_STATUS: '/cashbook/customers/{customerId}/user/{userId}/status',
    WORKSTATION_TOGGLE_ACTIVE_STATUS: '/cashbook/customers/{customerId}/workstations/{workstationId}',
    DELETE: '/cashbook/customers/{customerId}',
    CUSTOMER_DELETION_STATE_LIST: '/cashbook/customers/deletionStateList'
  },
  USERS: {
    GET_CUSTOMERS_BY_ID: '/cashbook/users/{id}/customers',
    GET_USAGE: '/cashbook/users/usages',
    GET_USERS_BY_ID: '/cashbook/users/{id}'
  },
  FRONTEND_LOGGING: '/cashbook/frontend-logging',
  DOCUMENT: {
    GET: '/cashbook/admin-documents/{documentId}'
  }
};

import { I18nSupported } from '@g3p/shared/enums/i18n.enum';

const baseUrl = '';

export const environment = {
  production: true,
  clientUrl: '',
  apiUrl: `${baseUrl}/api`,
  dhsUrl: `${baseUrl}/api/devicehub/v1`,
  dhsPort: '',
  apmUrl: '/',
  defaultLang: I18nSupported.ENGLISH,
  additionalLanguages: [ I18nSupported.GERMAN, I18nSupported.ITALIAN, I18nSupported.SPANISH ]
};

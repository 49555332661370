import { Component, Input } from '@angular/core';

import { ContainerType } from '@g3p/customer/enum/container.enum';

@Component({
  selector: 'g3p-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent {
  @Input() columnsNumber: ContainerType = ContainerType.ONE_COLUMN;
  @Input() showControlBar = true;
  containerType = ContainerType;

  constructor() { }
}

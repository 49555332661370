<g3-modal-regular>
    <g3-modal-title>
        <span>{{ dialogHeaderText() | translate }}</span>
    </g3-modal-title>
    <g3-modal-body>
        <div class="modal-body-content">
            <p>{{ confirmationMessage() | translate }}</p>
        </div>
    </g3-modal-body>
    <g3-modal-footer>
        <button
            #cancelButton
            mat-button
            color="primary"
            data-automation="Workstation toggle active status - Cancel Button"
            (click)="cancel()"
        >{{ 'content.cancel' | translate }}</button>
        <button
            #confirmButton
            mat-button
            color="primary"
            data-automation="Workstation toggle active status - Confirm Button"
            (click)="confirm()"
        >{{ confirmationButton() | translate }}</button>
    </g3-modal-footer>
</g3-modal-regular>

<div class="base-background-container">
    <div class="header-section">
        <div class="header-container">
            <ng-content select="g3p-header-container"></ng-content>
        </div>

        <p class="welcome-message-container">
            {{ "content.welcome-to" | translate }}
            <span class="product-name">&nbsp;{{ "content.TSE-cashbook" | translate }}</span>
        </p>
    </div>

    <div class="content-section">
        <div class="form-container">
            <ng-content select="g3p-base-background-form"></ng-content>
        </div>
    </div>

    <div class="footer-section">
        <ng-content select="g3p-version-container"></ng-content>
    </div>
</div>

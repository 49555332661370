<mat-toolbar class="header-container">
    <div class="logo-container">
        {{ "content.TSE-cashbook" | translate }}
    </div>
    <div class="filler filler-right"></div>
    <div
        class="status-icon"
        [ngClass]="{
            'status-active': workstationTssStatus?.status === 'ACTIVE',
            'status-not-active': workstationTssStatus?.status !== 'ACTIVE'
        }"
        *ngIf="!checkAdminMode()"
    ></div>

    <mat-icon
        class="mdi mdi-24px mdi-alert-circle-outline info-icon"
        [matMenuTriggerFor]="tseStatusActions"
        (click)="getLatestWorkstationTssStatus()"
        *ngIf="!checkAdminMode()"
    ></mat-icon>

    <div class="customer-container">{{ sessionDetails }}</div>
    <mat-menu #tseStatusActions class="tse-menu">
        <g3p-tss-status-popover> </g3p-tss-status-popover>
    </mat-menu>

    <div class="filler filler-right"></div>
    <button
        mat-button
        [style.display]="'block'"
        mat-icon-button
        class="overlay-help-btn"
        [ngClass]="{ 'overlay-help-active': overlayHelpActive }"
        [matMenuTriggerFor]="helpMenu"
        cdkOverlayOrigin
        #origin="cdkOverlayOrigin"
    >
        <mat-icon class="mdi mdi-24px mdi-help" position="bottom"></mat-icon>
    </button>

    <mat-menu class="help-menu" #helpMenu>
        <button
            mat-menu-item
            data-automation="App Shell - Overlay Help Active Button"
            (click)="onToggleOverlayHelp()"
        >
            {{ 'content.help-online' | translate }}
        </button>
        <button
            mat-menu-item
            data-automation="App Shell - Overlay User Manual Button"
            (click)="onToggleOpenUserManual()"
        >
            {{ 'content.help-user-manual' | translate }}
        </button>
        <button
            mat-menu-item
            data-automation="App Shell - Overlay Product News Button"
            (click)="onToggleOpenProductNews()"
        >
            {{ 'content.help-product-news' | translate }}
        </button>
        <button
            mat-menu-item
            data-automation="App Shell - Overlay Further Information Button"
            (click)="onToggleOpenFurtherInfo()"
        >
            {{ 'content.help-further-information' | translate }}
        </button>
    </mat-menu>

    <ng-template
        cdk-connected-overlay
        [cdkConnectedOverlayDisableClose]="true"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'overlay-help-backdrop'"
        [cdkConnectedOverlayOrigin]="origin"
        [cdkConnectedOverlayOpen]="overlayHelpActive"
        [cdkConnectedOverlayFlexibleDimensions]="true"
        [cdkConnectedOverlayPositions]="[{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }]"
    >
        <button mat-button mat-icon-button class="overlay-help-active-btn" (click)="onToggleOverlayHelp()">
            <mat-icon class="mdi mdi-24px mdi-help" position="bottom"></mat-icon>
        </button>
    </ng-template>
    <span class="divider-line"></span>
    <div class="user-menu-container" data-automation="Header - User menu">
        <button
            mat-button
            class="user-menu-button"
            [matMenuTriggerFor]="userMenu"
            data-automation="Header - Dropdown button"
        >
            <img
                [src]="'assets/images/avatar-placeholder.png'"
                class="user-image"
                data-automation="Header - Avatar"
                alt="user-image"
            />
            <span class="user-name" data-automation="Header - Username" [ngSwitch]="true">
                <ng-container *ngSwitchCase="lastName !== undefined && firstName !== undefined">
                    {{ lastName }}, {{ firstName }}
                </ng-container>
                <ng-container *ngSwitchCase="lastName === undefined && firstName !== undefined">
                    {{ firstName }}</ng-container
                >
                <ng-container *ngSwitchCase="lastName !== undefined && firstName === undefined">
                    {{ lastName }}
                </ng-container>
                <ng-container *ngSwitchDefault>{{ username }}</ng-container>
            </span>
            <mat-icon class="mdi mdi-24px mdi-chevron-down" matPrefix fontIcon="icon-arrowdown"></mat-icon>
        </button>
        <mat-menu
            class="user-menu"
            #userMenu="matMenu"
            yPosition="below"
            xPosition="before"
            data-automation="Header - User menu"
        >
            <button mat-menu-item (click)="onLogoutClicked()" data-automation="Header - User menu - Logout button">
                {{ "content.logout" | translate }}
            </button>
        </mat-menu>
    </div>
</mat-toolbar>

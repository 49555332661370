<g3-modal-regular>
    <g3-modal-title>
        <span>{{ translation.title | translate }}</span>
    </g3-modal-title>

    <g3-modal-body>
        <span>{{ translation.text }}</span>
    </g3-modal-body>
    <g3-modal-footer>
        <button
            mat-button
            color="primary"
            data-automation="Cashbook - Admin page - Cancel user deactivation"
            (click)="closeDialog()"
        >{{ 'content.cancel' | translate }}</button>
        <button
            #confirmButton
            mat-button
            color="primary"
            data-automation="Cashbook - Admin page - Confirm user deactivation"
            (click)="confirmDialog()"
        >{{ translation.confirmButton }}</button>
    </g3-modal-footer>
</g3-modal-regular>
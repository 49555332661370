import { CustomerUserDef } from '@g3p/customer/interfaces/customer-details-def.interface';

export interface CustomerUserStatusState {
  currentCustomer: any,
  currentCustomerUser: CustomerUserDef;
  currentCustomerId: string;
  busy: boolean;
}

export const initialCustomerUserStatusState = {
  currentCustomer: null,
  currentCustomerUser: null,
  currentCustomerId: null,
  busy: false
};

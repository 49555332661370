import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';

import { G3SnackbarService, G3SnackType } from '@cgm/g3-component-lib';

import * as fromAuthSelectors from '@g3p/store/auth/auth.selectors';
import { IAuthState } from '@g3p/store/auth/interfaces/auth-state.interface';
import { CustomerDef, WorkstationTssDataDef } from '@g3p/workstation/interfaces/workstation-def.interface';
import { WorkstationService } from '@g3p/workstation/services/workstation.service';
import * as workstationSelectors from '@g3p/workstation/store/workstation.selector';
import { WorkstationState } from '@g3p/workstation/store/workstation.state';

import * as fromActions from './workstation.actions';
import { WorkstationTssError } from '@g3p/shared/enums/workstation-tss.enums';

@Injectable({
  providedIn: 'root'
})
export class WorkstationEffects {
  getCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.WorkstationActionTypes.GET_CUSTOMER_REQUEST),
      withLatestFrom(this.authStore$.select(fromAuthSelectors.userIdState)),
      switchMap(([, userId]: [Action, string]) =>
        this.workService$.getCustomer$(userId).pipe(
          map((customers: CustomerDef[]) => fromActions.GetCustomerSuccess({ customers: customers })),
          catchError(() => of(fromActions.GetCustomerFailure()))
        )
      )
    )
  );

  getCustomerError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.WorkstationActionTypes.GET_CUSTOMER_FAILURE),
        tap(() =>
          this.snackbarService.open(
            this.translateService.instant('content.something-went-wrong'),
            5000,
            G3SnackType.Error
          )
        )
      ),
    { dispatch: false }
  );

  getWorkstationTseStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.WorkstationActionTypes.GET_WORKSTATION_TSS_STATUS_REQUEST),
      withLatestFrom(this.workStore$.select(workstationSelectors.workstationIdState)),
      switchMap(([, workstationId]: [Action, string]) =>
        this.workService$.getWorkstationTssStatus$(workstationId).pipe(
          map((workstationTssStatus: WorkstationTssDataDef) =>
            fromActions.GetWorkstationTseStatusSuccess({ workstationTssStatus: workstationTssStatus })
          ),
          catchError(error => of(fromActions.GetWorkstationTseStatusFailure({error: error})))
        )
      )
    )
  );

  getWorkstationTseStatusError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.WorkstationActionTypes.GET_WORKSTATION_TSS_STATUS_FAILURE),
        tap((error: any) =>
        {
          if (!error.error?.error?.errors || error.error?.error?.errors[0]?.detail !== WorkstationTssError.NOT_FOUND) {
            this.snackbarService.open(
              this.translateService.instant('content.something-went-wrong'),
              5000,
              G3SnackType.Error
            );
          }
        }
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private workStore$: Store<WorkstationState>,
    private authStore$: Store<IAuthState>,
    private workService$: WorkstationService,
    private snackbarService: G3SnackbarService,
    private translateService: TranslateService
  ) {}
}

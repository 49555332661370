import cloneDeep from 'lodash/cloneDeep';

import { newState } from '@g3p/shared/helpers/app-helpers';

import { CustomerUserStatusActions, CustomerUserStatusActionTypes } from './customer-user-status-modal.actions';
import { CustomerUserStatusState, initialCustomerUserStatusState } from './customer-user-status-modal.state';

export function customerUserStatusReducers(
  state: CustomerUserStatusState = cloneDeep(initialCustomerUserStatusState),
  action: CustomerUserStatusActions
) {
  switch (action.type) {
    case CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_REQUEST:
      return newState(state, (state.busy = true));

    case CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_SUCCESS:
      return newState(
        state,
        {
          ...state,
          currentCustomer: {
            ...state.currentCustomer,
            users: state.currentCustomer.users.map(user => {
              if (user.id === action.payload.customerUserId.userId) {
                user.endDate = null;
              }
              return user;
            })
          },
          currentCustomerUser: {
            ...state.currentCustomerUser,
            endDate: null,
            customers: state.currentCustomerUser.customers.map(userCustomer => {
              if (
                userCustomer.customerUserId.userId === action.payload.customerUserId.userId &&
                userCustomer.customerUserId.customerId === action.payload.customerUserId.customerId
              ) {
                userCustomer.active = action.payload.active;
                userCustomer.endDate = null;
              }
              return userCustomer;
            })
          }
        }
      );

    case CustomerUserStatusActionTypes.UPDATE_CUSTOMER_USER_STATUS_FAILURE:
      return newState(state, (state.busy = false));

    case CustomerUserStatusActionTypes.OPEN_UPDATE_CUSTOMER_USER_STATUS_DIALOG:
      return newState(state, { currentCustomer: action.currentCustomer, currentCustomerUser: action.customerUser, currentCustomerId: action.customerId });
  }
}

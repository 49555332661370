import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { takeWhile } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';

import { KeycloakService } from 'keycloak-angular';

import { OverlayHelpService } from '@cgm/cashbook-ui-lib';

import { AuthFacade } from '@g3p/store/auth/auth.facade';
import { WorkstationTssDataDef } from '@g3p/workstation/interfaces/workstation-def.interface';
import { WorkstationFacade } from '@g3p/workstation/store/workstation.facade';

const HELP_USER_MANUAL_PDF_LINK = "https://cgm.com/de-tse-kassenbuch-gebrauchsanweisung";
const HELP_PRODUCT_NEWS_PDF_LINK = "https://cgm.com/de-tse-kassenbuch-produktneuigkeiten";
const HELP_FURTHER_INFORMATION_LINK = "https://cgm.com/tse-kassenbuch";

@Component({
  selector: 'g3p-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  overlayHelpActive = false;
  private alive = true;

  firstName = '';
  lastName = '';
  username = '';
  customerName = '';
  workstationName = '';
  workstationId = '';
  workstationTssStatus: WorkstationTssDataDef;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @HostListener('window:keyup.esc') onKeyUp() {
    this.menuTrigger && this.menuTrigger.closeMenu();
  }

  constructor(
    private authFacade: AuthFacade,
    private dialog: MatDialog,
    private keycloak: KeycloakService,
    private workstationFacade: WorkstationFacade,
    private overlayHelpService: OverlayHelpService
  ) {}

  ngOnInit(): void {
    this.getWorkstationTssStatus();
    this.authFacade
      .getFirstname$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((firstName: string) => (this.firstName = firstName));

    this.authFacade
      .getLastname$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((lastName: string) => (this.lastName = lastName));

    this.authFacade
      .getUsername$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((username: string) => (this.username = username));

    this.workstationFacade
      .getCustomerName()
      .pipe(takeWhile(() => this.alive))
      .subscribe((customer: string) => (this.customerName = customer));

    this.workstationFacade
      .getWorkstationName()
      .pipe(takeWhile(() => this.alive))
      .subscribe((workstation: string) => {
        this.workstationName = workstation;
        workstation ? this.getLatestWorkstationTssStatus() : null;
      });

    this.workstationFacade
      .getOverlayHelp$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((overlayHelp: boolean) => {
        this.overlayHelpActive = overlayHelp;
        this.overlayHelpActive ? this.overlayHelpService.show() : this.overlayHelpService.hide();
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  checkAdminMode(): boolean {
    return (this.workstationName === '' || !this.workstationName);
  }

  getLatestWorkstationTssStatus(): void {
    this.workstationFacade.fetchWorkstationTssStatus();
  }

  getWorkstationTssStatus(): void {
    this.workstationFacade
      .getWorkstationTssStatus$()
      .pipe( takeWhile( () => this.alive ) )
      .subscribe( ( workstationTssData: WorkstationTssDataDef ) => this.workstationTssStatus = workstationTssData );
  }

  onToggleOverlayHelp(): void {
    this.workstationFacade.setOverlayHelp(!this.overlayHelpActive);
  }

  onToggleOpenUserManual(): void {
    window.open(HELP_USER_MANUAL_PDF_LINK);
  }

  onToggleOpenProductNews(): void {
    window.open(HELP_PRODUCT_NEWS_PDF_LINK);
  }

  onToggleOpenFurtherInfo(): void {
    window.open(HELP_FURTHER_INFORMATION_LINK);
  }

  public onLogoutClicked(): void {
    this.workstationFacade.reset();
    localStorage.removeItem('app_state');
    localStorage.removeItem('isAdminMode');
    this.keycloak.logout();
  }
  get sessionDetails(): string {
    return this.workstationName ? this.workstationName + ' | ' + this.customerName : this.customerName;
  }
}

import { Action, ActionReducer } from '@ngrx/store';

import { LocalStorageConstants } from '@g3p/shared/constants/local-storage.constants';
import { IAppState } from '@g3p/store/app-state.interface';

export function storeMetaReducers(reducer: ActionReducer<any>) {
  return function (state: IAppState | undefined, action: Action) {
    if (state === undefined) {
      const persisted = localStorage.getItem(LocalStorageConstants.AppState);
      return persisted ? JSON.parse(persisted) : reducer(state, action);
    }

    const newFeatureState = reducer(state, action);
    const newState = { ...state, ...newFeatureState };

    localStorage.setItem(LocalStorageConstants.AppState, JSON.stringify(newState));
    return newState;
  };
}

import { createAction, props } from '@ngrx/store';
import {
  AccountFormDef,
  BusinessUnitDef,
  CustomerDataFormDef,
  WorkstationFormDef
} from '@g3p/customer/interfaces/customer-def.interface';
import { CustomerDetailsDef } from '@g3p/customer/interfaces/customer-details-def.interface';
import {
  CustomerDeletionStateListDef
} from "@g3p/customer/interfaces/customer-deletion-state.interface";

export enum CustomerCreateActionTypes {
  RESET = '[Cash-Point - Create Customer] reset Customer Create Modal',

  OPEN_CREATE_DIALOG = '[Cash-Point - Create Customer] Open Create dialogue',
  OPEN_UPDATE_DIALOG = '[Cash-Point - Create Customer] Open Update dialogue',
  CLOSE_CREATE_DIALOG = '[Cash-Point - Create Customer] Close dialog',
  UPDATE_WORKSTATION_FORM_DATA = '[Cash-Point - Create Customer] Update workstation form data',
  UPDATE_CUSTOMER_DATA_FORM_DATA = '[Cash-Point - Create Customer] Update customer data form data',
  UPDATE_ACCOUNT_FORM_DATA = '[Cash-Point - Create Customer] Update account form data',
  UPDATE_CREATE_FORM = '[Cash-Point - Create Customer] Update Create form',
  UPDATE_CREATE_FORM_DATA = '[Cash-Point - Create Customer] Update Create form data',

  GET_BUSINESS_UNITS_REQUEST = '[cash-point - Create Customer] Get business units - Request',
  GET_BUSINESS_UNITS_SUCCESS = '[cash-point - Create Customer] Get business units - Success',
  GET_BUSINESS_UNITS_FAILURE = '[cash-point - Create Customer] Get business units - Failure',

  CREATE_CUSTOMER_REQUEST = '[cash-point - Create Customer] Create Customer - Request',
  CREATE_CUSTOMER_SUCCESS = '[cash-point - Create Customer] Create Customer - Success',
  CREATE_CUSTOMER_FAILURE = '[cash-point - Create Customer] Create Customer - Failure',

  UPDATE_CUSTOMER_REQUEST = '[cash-point - Create Customer] Update Customer - Request',
  UPDATE_CUSTOMER_SUCCESS = '[cash-point - Create Customer] Update Customer - Success',
  UPDATE_CUSTOMER_FAILURE = '[cash-point - Create Customer] Update Customer - Failure',

  DELETE_CUSTOMER_REQUEST = '[cash-point - Create Customer] Delete Customer - Request',
  DELETE_CUSTOMER_SUCCESS = '[cash-point - Create Customer] Delete Customer - Success',
  DELETE_CUSTOMER_FAILURE = '[cash-point - Create Customer] Delete Customer - Failure',

  OPEN_DELETE_MODAL = '[Cash-Point - Delete Customer] Open Customer Delete modal',
  CLOSE_DELETE_MODAL = '[Cash-Point - Delete Customer] Close Customer Delete modal',

  OPEN_CUSTOMER_DELETION_STATE_MODAL = '[Cash-Point - Delete Customer] Open Customer Deletion State modal',
  CLOSE_CUSTOMER_DELETION_STATE_MODAL = '[Cash-Point - Delete Customer] Close Customer Deletion State modal',
  GET_CUSTOMER_DELETION_STATE_LIST_REQUEST = '[Cash-Point - Delete Customer] Get Customer Deletion State list - Request',
  GET_CUSTOMER_DELETION_STATE_LIST_SUCCESS = '[Cash-Point - Delete Customer] Get Customer Deletion State list - Success',
  GET_CUSTOMER_DELETION_STATE_LIST_FAILURE = '[Cash-Point - Delete Customer] Get Customer Deletion State list - Failure',
}

export const Reset = createAction(CustomerCreateActionTypes.RESET);
export const OpenCreateDialog = createAction(CustomerCreateActionTypes.OPEN_CREATE_DIALOG);
export const OpenUpdateDialog = createAction(CustomerCreateActionTypes.OPEN_UPDATE_DIALOG);
export const CloseCreateDialog = createAction(CustomerCreateActionTypes.CLOSE_CREATE_DIALOG);
export const UpdateWorkstationFormData = createAction(CustomerCreateActionTypes.UPDATE_WORKSTATION_FORM_DATA, props<{form: WorkstationFormDef}>());
export const UpdateCustomerDataFormData = createAction(CustomerCreateActionTypes.UPDATE_CUSTOMER_DATA_FORM_DATA, props<{form: CustomerDataFormDef}>());
export const UpdateAccountFormData = createAction(CustomerCreateActionTypes.UPDATE_ACCOUNT_FORM_DATA, props<{form: AccountFormDef[]}>());
export const UpdateCreateForm = createAction(CustomerCreateActionTypes.UPDATE_CREATE_FORM);
export const UpdateCreateFormData = createAction(CustomerCreateActionTypes.UPDATE_CREATE_FORM_DATA, props<{customer: CustomerDetailsDef}>());

export const GetBusinessUnitsRequest = createAction(CustomerCreateActionTypes.GET_BUSINESS_UNITS_REQUEST);
export const GetBusinessUnitsSuccess = createAction(CustomerCreateActionTypes.GET_BUSINESS_UNITS_SUCCESS, props<{businessUnits: BusinessUnitDef[]}>());
export const GetBusinessUnitsFailure = createAction(CustomerCreateActionTypes.GET_BUSINESS_UNITS_FAILURE);

export const CreateCustomerRequest = createAction(CustomerCreateActionTypes.CREATE_CUSTOMER_REQUEST);
export const CreateCustomerSuccess = createAction(CustomerCreateActionTypes.CREATE_CUSTOMER_SUCCESS, props<{customer: CustomerDetailsDef}>());
export const CreateCustomerFailure = createAction(CustomerCreateActionTypes.CREATE_CUSTOMER_FAILURE);

export const UpdateCustomerRequest = createAction(CustomerCreateActionTypes.UPDATE_CUSTOMER_REQUEST);
export const UpdateCustomerSuccess = createAction(CustomerCreateActionTypes.UPDATE_CUSTOMER_SUCCESS, props<{id: string}>());
export const UpdateCustomerFailure = createAction(CustomerCreateActionTypes.UPDATE_CUSTOMER_FAILURE);

export const DeleteCustomerRequest = createAction(CustomerCreateActionTypes.DELETE_CUSTOMER_REQUEST);
export const DeleteCustomerSuccess = createAction(CustomerCreateActionTypes.DELETE_CUSTOMER_SUCCESS, props<{id: string}>());
export const DeleteCustomerFailure = createAction(CustomerCreateActionTypes.DELETE_CUSTOMER_FAILURE);

export const OpenDeleteCustomerModal = createAction(CustomerCreateActionTypes.OPEN_DELETE_MODAL);
export const CloseDeleteCustomerModal = createAction(CustomerCreateActionTypes.CLOSE_DELETE_MODAL);
export const OpenCustomerDeletionStateModal = createAction(CustomerCreateActionTypes.OPEN_CUSTOMER_DELETION_STATE_MODAL);
export const CloseCustomerDeletionStateModal = createAction(CustomerCreateActionTypes.CLOSE_CUSTOMER_DELETION_STATE_MODAL);
export const GetCustomerDeletionStateListRequest = createAction(CustomerCreateActionTypes.GET_CUSTOMER_DELETION_STATE_LIST_REQUEST);
export const GetCustomerDeletionStateListSuccess = createAction(CustomerCreateActionTypes.GET_CUSTOMER_DELETION_STATE_LIST_SUCCESS, props<{customerDeletionStateList: CustomerDeletionStateListDef[]}>());
export const GetCustomerDeletionStateListFailure = createAction(CustomerCreateActionTypes.GET_CUSTOMER_DELETION_STATE_LIST_FAILURE);

import {Component, OnDestroy, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {ContainerType} from '@g3p/customer/enum/container.enum';
import {CustomerFacade} from '@g3p/customer/store/customer.facade';
import {
  CustomerCreateFacade
} from '@g3p/customer/components/customer-create-modal/store/customer-create.facade';
import {PathsEnum} from '@g3p/shared/enums/paths.enum';
import {takeWhile} from "rxjs/operators";
import {
  CustomerDeletionModalComponent
} from "@g3p/customer/components/customer-deletion-modal/customer-deletion-modal.component";
import {G3ModalService} from "@cgm/g3-component-lib";
import {
  CustomerDeletionStateModalComponent
} from "@g3p/customer/components/customer-deletion-state-modal/customer-deletion-state-modal.component";
import {CustomerListDef} from "@g3p/customer/interfaces/customer-list-def.interface";

@Component({
  selector: 'g3p-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {
  containerType = ContainerType;
  customerName$ = this.customerFacade.getCustomerName$();
  customerList = [];
  navLinks = [];
  private alive = true;
  busy: boolean = false;

  constructor(
    private customerFacade: CustomerFacade,
    private createFacade: CustomerCreateFacade,
    private translateService: TranslateService,
    private g3modal: G3ModalService
  ) { }

  ngOnInit(): void {
    this.customerFacade.fetchCustomers();
    this.createFacade.fetchCustomerDeletionStateList();
    this.notifyCustomerDeletionModalOpen();
    this.notifyCustomerDeletionStateModalOpen();
    this.notifyBusyState();
    this.navLinks = [
      {
        path: PathsEnum.customers,
        data: {
          description: this.translateService.instant('content.customers')
        }
      }
    ];
    this.customerFacade.getCustomerList$().subscribe((data: CustomerListDef[]) => {
      this.customerList = data;
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  updateCustomer(): void {
    this.createFacade.updateCreateForm();
  }

  onDeleteCustomer(): void {
    this.createFacade.openCustomerDeletionModal();
  }

  notifyCustomerDeletionModalOpen(): void {
    this.createFacade
    .isCustomerDeletionModalOpened$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((isOpened: boolean) => {
      isOpened && this.g3modal.openRegular(CustomerDeletionModalComponent, { remWidth: 38 });
    });
  }

    onOpenCustomerDeletionStateModal() {
      this.createFacade.openCustomerDeletionStateModal();
    }

  notifyCustomerDeletionStateModalOpen(): void {
    this.createFacade
    .isCustomerDeletionStateModalOpened$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((isOpened: boolean) => {
      isOpened && this.g3modal.openFullscreen(CustomerDeletionStateModalComponent, null);
    });
  }

  isCustomerDeletionDisabled(): boolean {
    return this.busy || this.customerList == null || this.customerList.length == 0;
  }

  notifyBusyState() {
    this.createFacade.getBusyState$()
    .pipe(takeWhile(() => this.alive))
    .subscribe((isBusy: boolean) => {
      this.busy = isBusy;
    });
  }
}

import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';

import { asyncScheduler, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[g3pMasonryGrid]'
})
export class MasonryGridDirective implements OnInit, OnDestroy {
  private gridContainer: HTMLElement;
  private resize$ = new Subject<void>();
  private elementRefMutations$ = new MutationObserver(_ => this.resize$.next());
  private elementRefIntersection$;

  constructor(private element: ElementRef) {
    if ('IntersectionObserver' in window) {
      this.elementRefIntersection$ = new IntersectionObserver(_ => this.resize$.next());
    }
    this.resize$.pipe(throttleTime(100, asyncScheduler, { trailing: true, leading: true })).subscribe(() => {
      this.resizeAllGridItems();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resize$.next();
  }

  ngOnInit(): void {
    this.elementRefMutations$.observe(this.element.nativeElement, { subtree: true, childList: true });
    this.elementRefIntersection$?.observe(this.element.nativeElement);
  }

  ngOnDestroy(): void {
    this.elementRefMutations$.disconnect();
    this.elementRefIntersection$?.disconnect();
  }

  resizeGridItem(item: HTMLElement): void {
    this.gridContainer = this.element.nativeElement as HTMLElement;
    const grid = this.gridContainer.querySelector('.masonry-grid');
    const rowHeight = parseInt(getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    const rowGap = parseInt(getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    const rowSpan = Math.ceil(
      (item?.querySelector('.grid-content')?.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap)
    );
    item.style.gridRowEnd = 'span ' + rowSpan;
  }

  resizeAllGridItems(): void {
    const allItems = this.element.nativeElement.getElementsByClassName('grid-item');
    for (let i = 0; i < allItems.length; i++) {
      this.resizeGridItem(allItems[i]);
    }
  }
}

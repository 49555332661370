import { Action } from '@ngrx/store';

import { CustomerDetailsDef } from '@g3p/customer/interfaces/customer-details-def.interface';
import {WorkstationResponse} from "@g3p/customer/interfaces/customer-details-dto.interface";

export enum CustomerDetailsActionTypes {
  GET_CUSTOMER_REQUEST = '[Cashbook - Admin page] Set current customer - Request',
  GET_CUSTOMER_SUCCESS = '[Cashbook - Admin page] Set current customer - Success',
  GET_CUSTOMER_ERROR = '[Cashbook - Admin page] Set current customer - Error',
  RESET = '[Cashbook - Admin page] Set current customer - Reset',
  TOGGLE_WORKSTATION_ACTIVE_STATUS_REQUEST = '[Cashbook - Admin page] Toggle workstation active status - Request',
  TOGGLE_WORKSTATION_ACTIVE_STATUS_SUCCESS = '[Cashbook - Admin page] Toggle workstation active status - Success',
  TOGGLE_WORKSTATION_ACTIVE_STATUS_ERROR = '[Cashbook - Admin page] Toggle workstation active status - Error',
  OPEN_WORKSTATION_TOGGLE_MODAL = '[Cashbook - Admin page] Open workstation toggle modal',
  CLOSE_WORKSTATION_TOGGLE_MODAL = '[Cashbook - Admin page] Close workstation toggle modal'
}

export class GetCurrentCustomerRequest implements Action {
  readonly type = CustomerDetailsActionTypes.GET_CUSTOMER_REQUEST;
  constructor(public payload: { id: string }) {}
}

export class GetCurrentCustomerSuccess implements Action {
  readonly type = CustomerDetailsActionTypes.GET_CUSTOMER_SUCCESS;
  constructor(public payload: { currentCustomer: CustomerDetailsDef }) {}
}

export class GetCurrentCustomerError implements Action {
  readonly type = CustomerDetailsActionTypes.GET_CUSTOMER_ERROR;
}

export class Reset implements Action {
  readonly type = CustomerDetailsActionTypes.RESET;
}

export class ToggleWorkstationActiveStatusRequest implements Action {
  readonly type = CustomerDetailsActionTypes.TOGGLE_WORKSTATION_ACTIVE_STATUS_REQUEST;
  constructor(public payload: { customerId: string, workstation: WorkstationResponse }) {
  }
}

export class ToggleWorkstationActiveStatusSuccess implements Action {
  readonly type = CustomerDetailsActionTypes.TOGGLE_WORKSTATION_ACTIVE_STATUS_SUCCESS;
  constructor(public payload: { workstation: WorkstationResponse }) {
  }
}

export class ToggleWorkstationActiveStatusError implements Action {
  readonly type = CustomerDetailsActionTypes.TOGGLE_WORKSTATION_ACTIVE_STATUS_ERROR;
  constructor(public payload: { workstation: WorkstationResponse }) {
  }
}

export class OpenWorkstationToggleModal implements Action {
  readonly type = CustomerDetailsActionTypes.OPEN_WORKSTATION_TOGGLE_MODAL;
}
export class CloseWorkstationToggleModal implements Action {
  readonly type = CustomerDetailsActionTypes.CLOSE_WORKSTATION_TOGGLE_MODAL;
}

export type CustomerDetailsActions =
  | GetCurrentCustomerRequest
  | GetCurrentCustomerSuccess
  | GetCurrentCustomerError
  | Reset
  | ToggleWorkstationActiveStatusRequest
  | ToggleWorkstationActiveStatusSuccess
  | ToggleWorkstationActiveStatusError
  | OpenWorkstationToggleModal
  | CloseWorkstationToggleModal;


import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { takeWhile } from 'rxjs/operators';

import { G3BulletColor, G3ModalService, IG3LargeListData, IG3LargeListItemData } from '@cgm/g3-component-lib';

import { CustomerCreateModalComponent } from '@g3p/customer/components/customer-create-modal/customer-create-modal.component';
import { CustomerCreateFacade } from '@g3p/customer/components/customer-create-modal/store/customer-create.facade';
import { CustomerListFilterModalComponent } from '@g3p/customer/components/customer-list-filter-modal/customer-list-filter-modal.component';
import { CustomerListFacade } from '@g3p/customer/components/customer-list/store/customer-list.facade';
import { CustomerListStatus } from '@g3p/customer/enum/customer-status.enum';
import { CustomerListDef, CustomerListFilterDef } from '@g3p/customer/interfaces/customer-list-def.interface';

@Component({
  selector: 'g3p-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements AfterViewInit, OnInit, OnDestroy {
  customersList: IG3LargeListData[] = [];
  selectedCustomerId: string;
  searchString = '';
  activeFilters = 0;

  private alive = true;

  constructor(
    private modalService: G3ModalService,
    private createFacade: CustomerCreateFacade,
    private customerListFacade: CustomerListFacade
  ) {}

  ngOnInit(): void {
    this.notifyCreateDialogOpen();
  }

  ngAfterViewInit() {
    this.getCustomerList();
    this.getSelectedCustomerId();
    this.getActiveFilters();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  getCustomerList(): void {
    this.customerListFacade
      .getCustomerList$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((customerList: CustomerListDef[]) => {
        const items: IG3LargeListItemData[] = [];
        customerList.forEach(customer => {
          items.push({
            id: customer.id,
            topLeftText: customer.institutionName,
            topRightText: customer.city,
            bottomLeftText: customer.businessUnitName,
            bottomRightText: customer.customerNumber,
            bulletColor: CustomerListComponent.getBulletColor(customer.status)
          });
        });
        customerList?.length > 0 ? this.setSelectedCustomer(customerList[0]?.id) : this.customerListFacade.resetCurrentCustomer();
        setTimeout(()=> this.customersList = [{ list: items }]);

      });
  }

  setSelectedCustomer(id: string) {
    if (this.selectedCustomerId !== id) {
      setTimeout(()=> {
        this.selectedCustomerId = id;
        this.customerListFacade.setCurrentCustomer(this.selectedCustomerId);
      });
    }
  }

  onCreateCustomer(): void {
    this.createFacade.openCreateDialog();
  }

  notifyCreateDialogOpen(): void {
    this.createFacade
      .getCreateDialogOpened$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((isOpened: boolean) => {
        isOpened && this.modalService.openFullscreen(CustomerCreateModalComponent, null);
      });
  }

  loadCustomers(): void {
    this.customerListFacade.increasePageNumber();
    this.customerListFacade.fetchCustomers();
  }

  getSelectedCustomerId(): void {
    this.customerListFacade
      .getCustomerId$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((id: string) => setTimeout(()=>this.selectedCustomerId = id));
  }

  setSearchString(search: string): void {
    this.searchString = search;
    this.customerListFacade.setSearchQuery(this.searchString);
    this.customerListFacade.fetchCustomers();
  }

  resetSearch(): void {
    this.searchString = '';
    this.customerListFacade.setSearchQuery(this.searchString);
    this.customerListFacade.fetchCustomers();
  }

  openFilters(): void {
    this.createFacade.fetchBusinessUnits();
    this.modalService.openLeftOverlayModal(CustomerListFilterModalComponent);
  }

  getActiveFilters(): void {
    this.customerListFacade
      .getFilter$()
      .pipe(takeWhile(() => this.alive))
      .subscribe((filters: CustomerListFilterDef) => {
        setTimeout(() => {
          this.activeFilters =
          (filters.companyName ? 1 : 0) +
          (filters.businessUnitName ? 1 : 0) +
          (filters.customerNumber ? 1 : 0) +
          (filters.postalCode ? 1 : 0) +
          (filters.city ? 1 : 0);
          this.searchString = filters.query
        });
      });
  }

  private static getBulletColor(status: CustomerListStatus): G3BulletColor {
    switch (status) {
      case CustomerListStatus.CREATED:
        return G3BulletColor.Gray;
      case CustomerListStatus.DEACTIVATED:
        return G3BulletColor.Yellow;
      default:
        return undefined;
    }
  }
}
